import React, {useState, useEffect} from 'react';
import { Container} from 'react-bootstrap';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import axios from "axios";
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const Nexverse = () => {
  const [token, setToken] = useState(null);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

	return (
		<>
			<LeftNav />
			<div className="main-wrapper">
				<Header token={token} />
				<main>
					<Container fluid>
						<div style={{width: "100%", height: "80vh"}}>
							<iframe src="https://athenaanalytics.io/acuverse" width="100%" height="100%" frameborder="0" allowFullScreen="true" />
						</div>
					</Container>
				</main>
			</div>
		</>
	);
}
 
export default Nexverse;