import React, {useEffect, useState} from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/LoanUnderwrite/Header';
import Steps from '../components/LoanUnderwrite/Steps';
import LoanUnderwriteLanding from './LoanUnderwriteLanding.jsx';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const PortfolioRecommendations = ({applicationInputs, loanUnderwrite}) => {

  const[revealDetails, setRevealDetails] = useState(false);

  const [token, setToken] = useState(null);
  const [company, setCompany] = useState("");

  const [currentStep, setCurrentStep] = useState("portfolio-impact");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const handleAccept = () => {
    axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"portfolio_impact", items:{
      monthlyPayments: document.getElementById("monthlyPayments").innerText,
      portfolioRiskImpact: document.getElementById("portfolioRiskImpact").innerText,
      probabilityOfDefaultImpact: document.getElementById("probabilityOfDefaultImpact").innerText,
      minimumDelinquency: document.getElementById("minimumDelinquency").innerText,
      maximumDelinquency: document.getElementById("maximumDelinquency").innerText,
      balanceTransfer: document.getElementById("balanceTransfer").innerText,
      prePayment: document.getElementById("prePayment").innerText
    }})
    .then(response=>{
      console.log("loan_underwrite_set calculation response:", response);
      if (response.data.status === 200) {
        console.log('success');
        axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"application_status", items:{status:"Accepted"}})
        .then(response=>{
          console.log("loan_underwrite_set response:", response);
          if (response.data.status === 200) {
            console.log('success');
            setShowAlert(true);
            setAlertMsg(`${applicationInputs.fullName}'s loan is approved`);
            setTimeout(() => {
              setShowAlert(false);
              setAlertMsg("");
              setCurrentStep("loan-application");
            }, 3000);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleDecline = () => {
    axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"portfolio_impact", items:{
      monthlyPayments: document.getElementById("monthlyPayments").innerText,
      portfolioRiskImpact: document.getElementById("portfolioRiskImpact").innerText,
      probabilityOfDefaultImpact: document.getElementById("probabilityOfDefaultImpact").innerText,
      minimumDelinquency: document.getElementById("minimumDelinquency").innerText,
      maximumDelinquency: document.getElementById("maximumDelinquency").innerText,
      balanceTransfer: document.getElementById("balanceTransfer").innerText,
      prePayment: document.getElementById("prePayment").innerText
    }})
    .then(response=>{
      console.log("loan_underwrite_set calculation response:", response);
      if (response.data.status === 200) {
        console.log('success');
        axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"application_status", items:{status:"Declined"}})
        .then(response=>{
          console.log("loan_underwrite_set response:", response);
          if (response.data.status === 200) {
            console.log('success');
            setShowAlert(true);
            setAlertMsg(`${applicationInputs.fullName}'s loan is declined`);
            setTimeout(() => {
              setShowAlert(false);
              setAlertMsg("");
              setCurrentStep("loan-application");
            }, 3000);
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<>
    {currentStep === "portfolio-impact" && <div className='loan-underwrite'>
      <Header token={token} setCompany={setCompany} />
      <Container fluid className='container-wrapper'>
        {showAlert && <div className='alert-msg'><Container fluid>{alertMsg}</Container></div>}
        <Steps currentStep="PortfolioImpact" />

        <div className='dark-bg text-center txt-yellow p-4 pb-3'>
          <h6 className='d-none d-lg-block'>Customer Persona</h6>
          <p>{applicationInputs.fullName}'s profile matches with <b>{loanUnderwrite.historical_comparison.match(/\d+/)[0]}</b> historical customers on our portfolio</p>
        </div>
        
        <div className='mt-4 mb-5 text-center'><Button variant='plain' onClick={() => setRevealDetails(!revealDetails)}>{revealDetails ? 'Hide Details' : 'Explain Recommendations'}</Button></div>

        {revealDetails && <>
          <Table>
            <thead>
              <tr>
                <th>Portfolio Risk Impact</th>
                <th>Probability of Default Impact</th>
                <th>Minimum Delinquency</th>
                <th>Maximum Delinquency</th>
                <th>Balance Transfer</th>
                <th>Pre Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="portfolioRiskImpact">{loanUnderwrite.risk} Risk Portfolio {loanUnderwrite.risk_impact.split(" ")[0]} by {(Number(loanUnderwrite.score_2)*175).toFixed(4)}%</td>
                <td id="probabilityOfDefaultImpact">Increases by {(Number(loanUnderwrite.score_2)*100).toFixed(4)}%</td>
                <td id="minimumDelinquency">
                  {loanUnderwrite.d90 === 0 ? 'No Delinquency' : '90 Days Delinquent'}
                </td>
                <td id="maximumDelinquency">
                  {(loanUnderwrite["npaoneyear+"] !== 0 && loanUnderwrite["npaoneyear+"] !== null) ? '1 year+ NPA' : (
                    (loanUnderwrite["npa180+"] !== 0 && loanUnderwrite["npa180+"] !== null) ? '6 months+ NPA' : (
                      (loanUnderwrite["npa180"] !== 0 && loanUnderwrite["npa180"] !== null) ? '180 days NPA' : (
                        (loanUnderwrite["npa150"] !== 0 && loanUnderwrite["npa150"] !== null) ? '150 days NPA' : (
                          (loanUnderwrite["npa120"] !== 0 && loanUnderwrite["npa120"] !== null) ? '120 days NPA' : 'No Delinquency'
                        )
                      )
                    )
                  )}
                </td>
                <td id="balanceTransfer">{((Number(loanUnderwrite.score_2)*100)*2.6).toFixed(4)}%</td>
                <td id="prePayment">{((Number(loanUnderwrite.score_2)*100)/1.35).toFixed(4)}%</td>
              </tr>
            </tbody>
          </Table>

          <div className='dark-bg text-center txt-yellow p-4 pb-3 mt-5'><p>{applicationInputs.fullName} is likely to make <b id="monthlyPayments">{loanUnderwrite.foir}</b> monthly successful payments without any default</p></div>
        </>}

        <div className='text-center mt-5 mb-5'>
          <Button variant="transparent" onClick={handleAccept}>Accept</Button>
          <Button variant="primary mx-5">Potential Portfolio Impacts</Button>
          <Button variant="transparent" onClick={handleDecline}>Decline</Button>
        </div>
      </Container>
    </div>}
    {currentStep === "loan-application" && <LoanUnderwriteLanding />}
  </>);
}

export default PortfolioRecommendations;