import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const AddModules = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [finalInputs, setFinalInputs] = useState({});
  const [products, setProducts] = useState([]);

  const [industry, setIndustry] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    if (location.state !== null) {
      console.log("location.state", location.state);
      setFinalInputs(location.state);
      if(location.state.edmModules){
        setInputs(location.state.edmModules);
      }
    }
    console.log(finalInputs);

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    setTimeout(() => {
      var checkboxes = document.getElementsByName("edm_modules");
      if(document.querySelector('input[name="edm_modules"]:checked') !== null){
        for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
      } else {
        for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
      }
    }, 2000);
  }, []);

	useEffect(() => {
		if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "products_details_get",{token:token, moduleName:"EDM"})
			.then(response=>{
				console.log("products_details_get:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("products_details_get data:", response.data.items);
            setProducts(response.data.items[0].product_details);
					} else {
            setProducts([]);
          }
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      /* user_role_list_get */
      axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
      .then(response=>{
        console.log('user_role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* profile_list_get */
      profileListGet();
		}
	},[token]);

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            password:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

    if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			}
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}

    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    navigate('/function-module-list',{state:{edmModules:inputs}});
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Add Products</li>
                </ul>
              </div>
              <h1 className="mb-4">EDM Modules | {industry}</h1>
              <Form onSubmit={handleSubmit}>
                <fieldset className="mt-3">
                  <Row>
                    <Col lg={6} className="fieldset-heading">
                      <Form.Group className="txt-yellow" style={{columnCount:"2"}}>
                        {products.length > 0 &&
                          products.map((item, index) => (
                            <Form.Check type="checkbox" name="edm_modules" key={index} id={item.product_name} label={item.product_name} value={item.product_name} onChange={handleChange} defaultChecked={finalInputs.edmModules && finalInputs.edmModules.edm_modules.includes(item.product_name)} required/>
                          ))
                        }
                      </Form.Group>
                    </Col>
                    
                    <Col>
                      <h6 className="txt-white">Function Head:</h6>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control type="text" name="first_name" defaultValue={finalInputs.edmModules && finalInputs.edmModules.first_name} onChange={handleChange} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control type="text" name="last_name" defaultValue={finalInputs.edmModules && finalInputs.edmModules.last_name} onChange={handleChange} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mt-1">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" name="email_id" defaultValue={finalInputs.edmModules && finalInputs.edmModules.email_id} onChange={handleChange} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Profile:</Form.Label>
                            {/* <Form.Control type="text" name="profile" defaultValue={finalInputs.edmModules && finalInputs.edmModules.profile} onChange={handleChange} /> */}
                            <div className='profile-wrap'>
                              <Form.Control type="text" list="profile" name="profile" className='profile' defaultValue={finalInputs.edmModules && finalInputs.edmModules.profile} onChange={handleChange} />
                              <datalist id="profile">
                                {profileList.length > 0 &&
                                  profileList.map((item) => <option value={item}>{item}</option>)
                                }
                              </datalist>
                              <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mt-1">
                            <Form.Label>Role:</Form.Label>
                            <div className='roles-wrap'>
                              <Form.Select name="roles" onChange={handleChange}>
                                <option value="">Select Role</option>
                                {rolesList.length > 0 &&
                                  rolesList.map((item) => <option value={item} selected={finalInputs.edmModules && finalInputs.edmModules.roles === item}>{item}</option>)
                                }
                              </Form.Select>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <div className='position-relative mt-1'>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="password" name="password" className='pe-4' defaultValue={finalInputs.edmModules && finalInputs.edmModules.password || inputs.password} onChange={handleChange} />
                            <span className='toggle-password me-2' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                          </div>
                          <button type="button" id="createUserPwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2 mt-1 float-end'>Generate Password</button>
                        </Col>
                      </Row>
                      <Row>
                      
                      <Col xs="auto"></Col>
                      </Row>
                    </Col>
                  </Row>
                </fieldset>
                <Row className="mt-5">
                  <Col>
                    <button type="button" className="btn-transparent" onClick={() => navigate('/')}>Cancel</button>
                    <button type="submit" className="btn-yellow ms-4">Next</button>
                  </Col>
                </Row>
              </Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default AddModules;