import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const AddData = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [industry, setIndustry] = useState("");
  const [inputs, setInputs] = useState({});
  const [finalInputs, setFinalInputs] = useState({});

  const [deBillingCycle, setDEBillingCycle] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [products, setProducts] = useState([]);

  const [nextDisabled, setNextDisabled] = useState(true);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log("location.state", location.state);
    if (location.state !== null) {
      setFinalInputs(location.state);
      if(location.state.deModules){
        setInputs(location.state.deModules);
      }
    }
    console.log(finalInputs);

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    setTimeout(() => {
      if(document.querySelector('input[name="functions"]:checked') !== null){//Next button disabled until all lookup are filled
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    }, 2000);
  }, []);

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
		if(token !== null && token !== undefined){
      profileListGet();

      axios.post(serverConfig.api_base_url + "products_details_get",{token:token, moduleName:"AI"})
			.then(response=>{
				console.log("products_details_get:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("products_details_get data:", response.data.items);
            setProducts(response.data.items);
					} else {
            setProducts([]);
          }
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"de_billing_cycle"})
      .then(response=>{
        console.log("de_billing_cycle lookup:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("de_billing_cycle lookup data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            setDEBillingCycle(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* user_role_list_get */
      axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
      .then(response=>{
        console.log('user_role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
		}
	},[token]);

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handlePasswordGenerate = (functionName, event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            [functionName+"_password"]:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  const handleFunctionClick = (event) => {
    let el = document.querySelector(`#${event.target.id}`).closest("fieldset").querySelectorAll('.decision-engines input');
    console.log(el.length);
    if(event.target.checked) {
      for(let i=0; i<el.length; i++){
        el[i].disabled = false;
      }
    } else {
      for(let i=0; i<el.length; i++){
        el[i].disabled = true;
      }
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

    if(event.target.type === "checkbox"){

      console.log(event.target.parentElement.classList);

      if(event.target.parentElement.classList.contains("functions-check")){
        handleFunctionClick(event);
      }

      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      /* console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			} */
      let el = event.target.closest('fieldset').querySelectorAll('.decision-engines input[type="checkbox"]');
      let elCheckedLength = event.target.closest('fieldset').querySelectorAll('.decision-engines input[type="checkbox"]:checked').length;
      if (event.target.checked) {
        if(elCheckedLength !== 0){
          for(let i=0; i < el.length; i++){
            el[i].required = false;
          }
        } else {
          for(let i=0; i < el.length; i++){
            el[i].required = true;
          }
        }
      } else {
        for(let i=0; i < el.length; i++){
          el[i].required = false;
          setInputs(values => ({...values, [el[i].name]: []}));
        }
      }
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}

    if(document.querySelector('input[name="functions"]:checked') !== null){//Next button disabled until all lookup are filled
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    console.log({...finalInputs, deModules:inputs});
    //navigate('/');
    navigate('/modules-preview',{state:{...finalInputs, deModules:inputs}});
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Add Products</li>
                </ul>
              </div>
              <h1 className="mb-4">Decision Engines | {industry}</h1>
              <Form onSubmit={handleSubmit}>
                <fieldset className='heading'>
                  <Row>
                    <Col lg={2} className='fieldset-heading'>
                      <h6 className="txt-white">Functions</h6>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col lg="9"><h6 className="txt-white">Decision Engines</h6></Col>
                        <Col><h6 className="txt-white">Billing Cycle</h6></Col>
                      </Row>
                    </Col>
                    <Col lg={5}>
                      <h6 className="txt-white">Function Head:</h6>
                    </Col>
                  </Row>
                </fieldset>

                {products.length > 0 &&
                  products.map((item, index) => {
                    return(<>
                      <fieldset className="mt-3" key={index}>
                        <Row>
                          <Col lg={2} className="fieldset-heading">
                            <h5><Form.Check type="checkbox" name="functions" id={item.function} label={item.function} value={item.function} onChange={ handleChange} defaultChecked={finalInputs.deModules && finalInputs.deModules.functions && finalInputs.deModules.functions.includes(item.function)} className='functions-check' /></h5>
                          </Col>
                          <Col lg={5}>
                            <Form.Group className="txt-yellow decision-engines">
                              {item.product_details.length > 0 && item.product_details.map((subitem, subindex) => (<Row>
                                <Col lg="9">
                                  <Form.Check type="checkbox" name={item.function+"-modules"} key={item+"-"+subindex} id={subitem.product_name} label={subitem.product_name} value={subitem.product_name} onChange={ handleChange} defaultChecked={finalInputs.deModules && finalInputs.deModules[`${item.function+"-modules"}`] && finalInputs.deModules[`${item.function+"-modules"}`].includes(subitem.product_name)} disabled />
                                </Col>
                                <Col>
                                  <Form.Select name={item.function+"_"+subitem.product_name+"_billing_cycle"} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {deBillingCycle.length > 0 &&
                                      deBillingCycle.map((itm, idx) => <option key={itm.value+"-"+idx} value={itm.value} selected={finalInputs.deModules && finalInputs.deModules[`${item.function+"_"+subitem.product_name+"_billing_cycle"}`] === itm.value}>{itm.value}</option>)
                                    }
                                  </Form.Select>
                                </Col>
                              </Row>))}
                            </Form.Group>
                          </Col>
                          <Col lg={5} className='function-head'>
                            {/* <h6 className="txt-white">Function Head:</h6> */}
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>First Name:</Form.Label>
                                  <Form.Control type="text" name={item.function+"_first_name"} defaultValue={finalInputs.deModules && finalInputs.deModules[`${item.function+"_first_name"}`]} onChange={handleChange} />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Last Name:</Form.Label>
                                  <Form.Control type="text" name={item.function+"_last_name"} defaultValue={finalInputs.deModules && finalInputs.deModules[`${item.function+"_last_name"}`]} onChange={handleChange} />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mt-1">
                                  <Form.Label>Email:</Form.Label>
                                  <Form.Control type="email" name={item.function+"_email_id"} defaultValue={finalInputs.deModules && finalInputs.deModules[`${item.function+"_email_id"}`]} onChange={handleChange} />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Profile: </Form.Label>
                                  <div className='profile-wrap'>
                                    <Form.Control type="text" list={item.function+"_profile"} name={item.function+"_profile"} className='profile' defaultValue={finalInputs.deModules && finalInputs.deModules[`${item.function+"_profile"}`]} onChange={handleChange} />
                                    <datalist id={item.function+"_profile"}>
                                      {profileList.length > 0 &&
                                        profileList.map((itm, idx) => <option key={itm+"-"+idx} value={itm}>{itm}</option>)
                                      }
                                    </datalist>
                                    <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group className="mt-1">
                                  <Form.Label>Role: </Form.Label>
                                  <div className='roles-wrap'>
                                    <Form.Select name={item.function+"_role"} onChange={handleChange}>
                                      <option value="">Select Role</option>
                                      {rolesList.length > 0 &&
                                        rolesList.map((itm, idx) => <option key={itm+"-"+idx} value={itm} selected={finalInputs.deModules && finalInputs.deModules[`${item.function+"_role"}`] === itm}>{itm}</option>)
                                      }
                                    </Form.Select>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col>
                                <div className='position-relative mt-1'>
                                  <Form.Label>Password:</Form.Label>
                                  <Form.Control type="password" name={item.function+"_password"} className='pe-4' defaultValue={finalInputs.deModules && finalInputs.deModules[`${item.function+"_password"}`] || inputs[`${item.function+"_password"}`]} onChange={handleChange} />
                                  <span className='toggle-password me-2' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                                </div>
                                <button type="button" id="createUserPwdGenerate" onClick={(e) => handlePasswordGenerate(item.function, e)} className='btn-transparent p-2 mt-1 float-end'>Generate Password</button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </fieldset>
                    </>);
                  })
                }
                
                <Row className="mt-5">
                  <Col>
                    <button type="button" className="btn-transparent" onClick={() => navigate('/function-module-list',{state:finalInputs})}>Previous</button>
                    <button type="submit" className="btn-yellow ms-4" disabled={nextDisabled}>Preview</button>
                    {/* <button type="submit" className="btn-yellow ms-4" onClick={handleSubmit} disabled={nextDisabled}>Submit</button> */}
                  </Col>
                </Row>
              </Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default AddData;