import React, {useState, useEffect} from 'react';
import { Form, Button} from 'react-bootstrap';
import axios from 'axios';
//import { Configuration, OpenAIApi } from "openai";

const serverConfig = require("../config/server.js");
 
const AskAthena = ({token, handleLogout}) => {
  //const [openai, setOpenai] = useState(new OpenAIApi(new Configuration({ apiKey: serverConfig.open_ai_api_key })));

  const [prompt, setPrompt] = useState("");
  const [typing, setTyping] = useState(false);
  const [sessionMessages, setSessionMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "av_get_chat_detail",{token:token})
      .then(response=>{
        console.log("av_get_chat_detail response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          console.log(items);
          if(items !== null){
            console.log(items.text);
            setSessionMessages(items);
            console.log(items[1]);
            setTimeout(() => {
              var objDiv = document.getElementById("chat-body");
              objDiv.scrollTop = objDiv.scrollHeight;
            },);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const sendMessage = () => {
    
    if(document.getElementById("my-msg").value !== ''){
      //setSendErrorMsg("");
      var inputMsg = prompt;
      setTyping(true);
      
      let usermsg = prompt.replace(/'/g, "''");
      console.log(chatMessages);

      //av_set_message - user query
      axios.post(serverConfig.api_base_url + "av_set_message",{token:token, messagetext:{data:usermsg}, type:'user query'})
      .then(response=>{
        console.log('av_set_message response', response);
        if(response.data.status === 200){
          //setInputsTitle({});
          //setNewChatDisabled(false);
          setChatMessages([...chatMessages, {
            type: 'user query',
            message: prompt,
            timestamp: response.data.stamp.split(' ')[1]
          }]);

          setTimeout(() => {
            var objDiv = document.getElementById("chat-body");
            objDiv.scrollTop = objDiv.scrollHeight;
          },);

          setPrompt("");
  
          //bot message
          axios.post("https://ask.thf.athenaanalytics.io/query",{query_str: usermsg})
          .then(response=>{
            console.log("athenagpt response:", response);
            if (response.data.result) {
              setChatMessages((stateChatMessages) => {
                console.log('inputMsg', inputMsg);
                var servermsg;
                /* if(inputMsg === 'What is the percentage change in demand for TRINITI G15 gaming PCs from last month?'){
                  servermsg= 'Demand for TRINITI G15 is up 11.39% this week, measured over a month-on-month frequency.';
                } else if(inputMsg === 'What is the reason for change in demand of TRINITI G15?'){
                  servermsg= 'Price sensitive customers are buying TRINITI G15.';
                } else if(inputMsg === 'What will be the demand for TRINITI G15 for the next 3 months?'){
                  servermsg= 'Demand for TRINITI G15 will decline in July by 5.33% and in August by 4.59%, and then rise again in September by 13.39%.';
                } else { */
                  servermsg = response.data.result;
                //}
                let serverTimestamp = new Date().toLocaleTimeString();
    
                console.log(stateChatMessages, chatMessages, servermsg);

                //av_set_message - bot answer
                axios.post(serverConfig.api_base_url + "av_set_message",{token:token, messagetext:{data:servermsg.replace(/'/g, "''")}, type:'bot answer'})
                .then(response=>{
                  console.log('av_set_message response', response);
                  if(response.data.status === 200){
                    setChatMessages([...stateChatMessages, {
                      type: 'bot answer',
                      message: servermsg,
                      timestamp: response.data.stamp.split(' ')[1]
                    }]);
        
                    setTyping(false);
        
                    setTimeout(() => {
                      var objDiv = document.getElementById("chat-body");
                      objDiv.scrollTop = objDiv.scrollHeight;
                    },);
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                  }
                })
                .catch(err=>console.log("error is",err));

                inputMsg = "";//clear text field
                return stateChatMessages;
              });
            }
          })
          .catch(err=>{
            console.log("error is",err);
            setTyping(false);
            alert("Some error occured. Please try again.");
          });
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else if(document.getElementById("my-msg").value !== '' && document.querySelector('select[name="state"]').value === ''){
      document.querySelector('select[name="state"]').focus();
      //setSendErrorMsg("Please select state");
    } else if(document.getElementById("my-msg").value === '' && document.querySelector('select[name="state"]').value !== ''){
      document.getElementById("my-msg").focus();
      //setSendErrorMsg("Please enter a message");
    } else {
      //setSendErrorMsg("Please select state and enter a message");
    }
  }

  const handleSendMsg = (event) => {
    event.preventDefault();
    console.log(document.getElementById("my-msg").value);
    sendMessage();
  }

  const handleEnter = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  return (<>
    <div id="chat-body">
      <ul className='chat-list'>
        {sessionMessages.map((item,index) => {
          /*  let message;
          if(item.type === 'user query'){
            message = JSON.parse(item.text);
          }else if(item.type === 'bot answer'){
            message = item.text;
          } */
          return (<li key={index} className={item.type === 'bot answer' ? 'msg-wrapper bot-msg' : 'msg-wrapper'}>
            <div>
              <div className='avatar'><img src={item.type === 'bot answer' ? require('./../assets/images/icons/chat-bot-icon.png') : require('./../assets/images/icons/chat-user-icon.png')} alt={item.type === 'bot answer' ? "Athena Icon" : "User Icon"} width="26" /></div>
              <div className='text'>
                {/* <span dangerouslySetInnerHTML={{__html: item.type === 'bot answer' ? JSON.parse(item.text).data.choices[0].text : JSON.parse(item.text.replace(/\n/g,'<br/>')).data}} /> */}
                <span dangerouslySetInnerHTML={{__html: JSON.parse(item.text.replace(/\n/g,'<br/>')).data}} />
              </div>
            </div>
            {/* <div className='timestamp'>{((item.stamp).split(' ')[1]).split('.')[0]}<br/>{inputs.state}/{inputs.year ? inputs.year : "---"}'{inputs.quarter ? inputs.quarter : "---"}</div> */}
          </li>)
        })}
        {chatMessages.map((item,index) => {
          return (<li key={index} className={item.type === 'bot answer' ? 'msg-wrapper bot-msg' : 'msg-wrapper'}>
            <div>
              <div className='avatar'><img src={item.type === 'bot answer' ? require('./../assets/images/icons/chat-bot-icon.png') : require('./../assets/images/icons/chat-user-icon.png')} alt={item.type === 'bot answer' ? "Athena Icon" : "User Icon"} width="26" /></div>
              <div className='text'>
                <span>{item.message}</span>
              </div>
            </div>
            {/* <div className='timestamp'>{item.timestamp}<br/>{inputs.state}/{inputs.year ? inputs.year : "---"}'{inputs.quarter ? inputs.quarter : "---"}</div> */}
          </li>)
        })}
      </ul>
      {typing && <div className='mt-2 ms-3'><img src={require('./../assets/images/icons/typing.gif')} /></div>}
    </div>
    <Form id="form-chat" onSubmit={handleSendMsg}>
      <div className='form-wrapper'>
        <Form.Group>
          <Form.Control id="my-msg" as="textarea" rows={3} placeholder="Ask Athena" value={prompt} onChange={(e) => {setPrompt(e.target.value); /* setSendErrorMsg(""); */}} onKeyDown={handleEnter} required />
        </Form.Group>
        <Button variant="plain" type="submit" className='send'><img src={require('./../assets/images/icons/send-icon.png')} alt="Send" width="25" /></Button>
      </div>
    </Form>
  </>);
}
 
export default AskAthena;