import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import AskAthena from '../components/AskAthena';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const Subscription = () => {

	const navigate = useNavigate();

	const [token, setToken] = useState(null);
	const [clientLogo, setClientLogo] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [viewReportsPrivileged, setViewReportsPrivileged] = useState(false);
	
	const [pinnedReport, setPinnedReport] = useState(false);
	const [iframeUrl, setIFrameUrl] = useState(null);
	const [iframeName, setIFrameName] = useState(null);
	const [dashboardListSubscribed, setDashboardListSubscribed] = useState([]);
	const [dashboardListSubscribedFinal, setDashboardListSubscribedFinal] = useState([]);
	const [dashboardListUnsubscribed, setDashboardListUnsubscribed] = useState([]);
	const [dashboardListUnsubscribedFinal, setDashboardListUnsubscribedFinal] = useState([]);
	const [subscribedFunctionList, setSubscribedFunctionList] = useState([]);
	const [unsubscribedFunctionList, setUnsubscribedFunctionList] = useState([]);
	const [selectFunctions, setSelectFunctions] = useState([]);
	const [functions, setFunctions] = useState([]);
	const [refreshNotifications, setRefreshNotifications] = useState(false);
	const [userList, setUserList] = useState([]);

	const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);
	
	useEffect(() => {
		if(token !== null && token !== undefined){
			axios.post(serverConfig.api_base_url + "user_check_privilege", {token:token, privilege:"view_reports"})
			.then(response=>{
				console.log('user_check_privilege response', response.data);
				if(response.data.status === 200){
					if(response.data.is_privileged === 1) {
						setViewReportsPrivileged(true);
					} else if(response.data.is_privileged === 0) {
						setViewReportsPrivileged(false);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

			axios.post(serverConfig.api_base_url + "modules_subscribed_get", {token:token})
			.then(response=>{
				console.log('modules_subscribed_get response', response.data.items);
				if(response.data.status === 200){
					if(response.data.items !== null) {
						setFunctions(response.data.items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

			axios.post(serverConfig.api_base_url + "lookup_list", {name: "select_functions", token:token})
			.then(response=>{
				console.log('lookup_list_response response', response.data.items);
				if(response.data.status === 200){
					if(response.data.items !== null) {
						response.data.items.sort(function(a,b){
							return a.seq_no - b.seq_no;
						});
						setSelectFunctions(response.data.items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	},[token]);

	useEffect(() => {
		if(token !== null && token !== undefined && selectFunctions.length > 0){
			axios.post(serverConfig.api_base_url + "dashboard_list_get", {token:token})
			.then(response=>{
				console.log('dashboard_list_get response', response);
				if(response.data.status === 200){
					if(response.data.items !== null) {
						console.log(response.data.items.filter(el => el.is_subscribed===1));
						setDashboardListSubscribed(response.data.items.filter(el => el.is_subscribed===1));
						let firstFunction = [...new Set(response.data.items.sort(function(a,b){
							return a.seq_no - b.seq_no;
						}).filter(el => el.is_subscribed===1).map(item => item.function_name))][0];
						if(viewReportsPrivileged){
							setDashboardListSubscribedFinal(response.data.items.filter(el => el.is_subscribed===1 && el.function_name===firstFunction && el.email_id === UserService.getUsername()));
						} else {
							setDashboardListSubscribedFinal(response.data.items.filter(el => el.is_subscribed===1 && el.function_name===firstFunction));
							console.log([...new Set(response.data.items.filter(el => el.is_subscribed===1).map(item => item.email_id))]);
							setUserList([...new Set(response.data.items.filter(el => el.is_subscribed===1).map(item => item.email_id))]);
						}
						setSubscribedFunctionList([...new Set(response.data.items.filter(el => el.is_subscribed===1).map(item => item.function_name))]);

						console.log(response.data.items.filter(el => el.is_subscribed===0));
						setDashboardListUnsubscribed(response.data.items.filter(el => el.is_subscribed===0));
						if(viewReportsPrivileged){
							setDashboardListUnsubscribedFinal(response.data.items.filter(el => el.is_subscribed===0 && el.function_name===selectFunctions[0].value && (el.email_id === UserService.getUsername() || el.email_id === null)));
						} else {
							setDashboardListUnsubscribedFinal(response.data.items.filter(el => el.is_subscribed===0 && el.function_name===selectFunctions[0].value && el.email_id === null));
						}
						setUnsubscribedFunctionList([...new Set(response.data.items.filter(el => el.is_subscribed===0).map(item => item.function_name))]);

						let pinned = response.data.items.filter((el) => el.pinned === 1);
						console.log(pinned);
						if(pinned.length > 0){
							setIFrameUrl(pinned[0].link);
							//setIFrameName(pinned[0].name);
						} else {
							setIFrameUrl(response.data.items.filter(el => el.is_deployed===1)[0].link);
							//setIFrameName(response.data.items[0].name);
						}
						setIsLoading(false);
					} else {
						setDashboardListSubscribed([]);
						setDashboardListSubscribedFinal([]);
						setDashboardListUnsubscribed([]);
						setDashboardListUnsubscribedFinal([]);
						setIsLoading(false);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
					setIsLoading(false);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	},[token, selectFunctions, pinnedReport]);
	
	const handleReportPin = (productid, toPin) => {
		axios.post(serverConfig.api_base_url + "report_pin",{token:token, product_id: productid, to_pin: toPin})
		.then(response=>{
			setIsLoading(true);
			console.log("report_pin response:", response);
			if (response.data.status === 200) {
				console.log('success');
				setPinnedReport(!pinnedReport);
			} else if(response.data.status === 301){
				handleLogout();
			} else {
				alert(response.data.message);
				setIsLoading(false);
			}
		})
		.catch(err=>console.log("error is",err));
	}

	const handleRequest = (itemName, event) => {
		event.stopPropagation();
		console.log(itemName);
		console.log(event.target.innerText);
		var message;
		if(event.target.innerText === 'Subscribe'){
			message = `Subscription requested for ${itemName}`;
		} else if(event.target.innerText === 'Start Trial'){
			message = `Trial requested for ${itemName}`;
		} else {
			message = `Subscription requested for ${itemName}`;
		}
		axios.post(serverConfig.api_base_url + "notification_set",{token:token, notificationText: message})
		.then(response=>{
			console.log("notification_set response:", response);
			if (response.data.status === 200) {
				alert(message);
				setRefreshNotifications(!refreshNotifications);
			} else if(response.data.status === 301){
				handleLogout();
			} else {
				alert(response.data.message);
			}
		})
		.catch(err=>console.log("error is",err));
	}

	const handleFunctionsCheck = (event) => {
		console.log(event.target.value);
		console.log(dashboardListSubscribed);
		console.log(dashboardListSubscribed.filter((el) => el.function_name === event.target.value));
		console.log(dashboardListSubscribedFinal);
		if(viewReportsPrivileged){
			setDashboardListSubscribedFinal(dashboardListSubscribed.filter((el) => el.function_name === event.target.value && el.email_id === UserService.getUsername()));
		} else {
			setDashboardListSubscribedFinal(dashboardListSubscribed.filter((el) => el.function_name === event.target.value));
		}
	}

	const handleUnsubscribedFunctionsCheck = (event) => {
		console.log(event.target.value);
		console.log(dashboardListUnsubscribed);
		console.log(dashboardListUnsubscribed.filter((el) => el.function_name === event.target.value));
		console.log(dashboardListUnsubscribedFinal);
		setDashboardListUnsubscribedFinal(dashboardListUnsubscribed.filter((el) => el.function_name === event.target.value));
	}

	const handleFilterByUser = (event) => {
		if(event.target.value !=='' && event.target.value !==null){
			console.log(event.target.value);
			console.log(dashboardListSubscribed);
			console.log(dashboardListSubscribed.filter((el) => el.function_name === event.target.value));
			console.log(dashboardListSubscribedFinal);
			setDashboardListSubscribedFinal(dashboardListSubscribed.filter((el) => el.email_id === event.target.value));
		} else {
			setDashboardListSubscribedFinal(dashboardListSubscribed.filter(el => el.is_subscribed===1 && el.function_name===selectFunctions[0].value));
		}
	}

	const responsive = {
		xl: {
			breakpoint: { max: 2160, min: 1280 },
			items: 6
		},
		lg: {
			breakpoint: { max: 1280, min: 1024 },
			items: 4
		},
		md: {
			breakpoint: { max: 1024, min: 768 },
			items: 3
		},
		sm: {
			breakpoint: { max: 768, min: 640 },
			items: 2
		},
		xs: {
			breakpoint: { max: 640, min: 0 },
			items: 1
		}
	};

	return (
		<>
			<LeftNav />
			<div className="main-wrapper">
				<Header token={token} refreshNotifications={refreshNotifications} />
				<div>
					<main>
						{isLoading && <div id="loading"><img src={require('./../assets/images/icons/spinner.gif')} alt="Loader" width="70" /></div>}
						<Container fluid>
							{/* {iframeUrl && <section className="iframe-wrapper" dangerouslySetInnerHTML={{__html: iframeUrl}} />} */}
							{iframeUrl && <div className='position-relative'>
								<section className="iframe-wrapper" dangerouslySetInnerHTML={{__html: iframeUrl}} />
								<a href={iframeUrl.split(' ').find((el) => el.startsWith('src=')).slice(5).slice(0,-1)} target="_blank" style={{position:"absolute", right:"5px", bottom: "5px"}} title="Open in new tab"><img src={require('./../assets/images/icons/arrow-up-right-from-square.png')} width="20" /></a>
							</div>}

							{dashboardListSubscribedFinal.length > 0 && <section className="bg-dark-gray mb-0 pb-0">
								<h6 className='mb-3'>My Subscriptions</h6>
								<Carousel responsive={responsive} className='reports-list'>
									{dashboardListSubscribedFinal.map((item, index) => {
										let btn;
										if(item.status === 'Subscribe'){
											btn=<button onClick={(e) => handleRequest(item.product_name, e)}>{item.status}</button>;
										} else {//Active or Unavailable
											if(viewReportsPrivileged){
												btn=<button onClick={(e) => e.stopPropagation()} className={item.status === 'Active' ? 'txt-yellow' : 'txt-gray'}>{item.status}</button>;
											} else {
												btn=<button onClick={() => navigate("/products")} className={item.status === 'Active' ? 'txt-yellow' : 'txt-gray'}>{item.status}</button>;
											}
										}
										return(/* functions.some(el => el.function === item.function_name) && */ <div xs="auto" key={index} className={item.status==='Unavailable' ? 'unavailable' : undefined}>
										<div className={item.pinned === 1 ? 'item active' : 'item'}  onClick={() => setIFrameUrl(item.link)}>
											{item.pinned === 1 ? <img src={require('./../assets/images/icons/star-icon.png')} alt="Pin" width="12" title="Remove from favourites" onClick={() => handleReportPin(item.product_id, 0)} /> : <img src={require('./../assets/images/icons/star-empty-icon.png')} alt="Pin" width="12" title="Add to favourites" onClick={() => handleReportPin(item.product_id, 1)} />}
											<div>
												<img src={item.icon && item.icon} alt={item.value} width="50" />
											</div>
											<span>{item.product_name}</span>
											{btn}
										</div>
									</div>)})}
								</Carousel>
							</section>}

							{selectFunctions.length > 0 && <section className="client-subscription mt-0">
								{/* <h2>My Subscriptions</h2>
								<ul className="subscription">
									{selectFunctions.map((item, index) => (
										functions.some(el => el.function === item.value) && <li key={index}>
											<span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
											<Form.Check inline label={item.value} name="functions" type="checkbox" id={item.value} value={item.value}  defaultChecked={true} onChange={handleFunctionsCheck} />
										</li>
									))}
								</ul> */}
								{!viewReportsPrivileged && <h6>Filter by Function</h6>}
								<Carousel responsive={responsive} className="subscription">
									{selectFunctions.map((item, index) => (
										(/* viewReportsPrivileged ? functions.some(el => el.function === item.value) :  */subscribedFunctionList.includes(item.value)) &&
										<div key={index} className='label-wrap'>
											<label htmlFor={item.value}>
												<input name="functions" type="radio" id={item.value} value={item.value} onChange={handleFunctionsCheck} defaultChecked={item.value===subscribedFunctionList[0]} />
												<span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
												<span className="text">{item.value}</span>
											</label>
										</div>
									))}
								</Carousel>

								{!viewReportsPrivileged && <div className='mt-4'>
									<h6>Filter by User</h6>
									{userList.length > 0 && <Form.Select style={{width:"300px"}} onChange={handleFilterByUser}>
										<option value="">Select User</option>
										{userList.map((usr, isridx) => usr && <option value={usr}>{usr}</option>)}
									</Form.Select>}
								</div>}
							</section>}

							{dashboardListUnsubscribedFinal.length > 0 && <section className="bg-dark-gray mb-0 pb-0">
								<h6 className='mb-3'>Subscribe More</h6>
								<Carousel responsive={responsive} className='reports-list'>
									{dashboardListUnsubscribedFinal.map((item, index) => {
										let btn;
										if(item.status === 'Subscribe' || item.status === 'Trial'){
											btn=<button onClick={(e) => handleRequest(item.product_name, e)}>{item.status}</button>;
										} else {//Active or Unavailable
											btn=<button onClick={(e) => e.stopPropagation()} className={item.status === 'Active' ? 'txt-yellow' : 'txt-gray'}>{item.status}</button>;
										}
										return(/* functions.some(el => el.function === item.function_name) &&  */<div xs="auto" key={index} className={item.status==='Unavailable' ? 'unavailable' : undefined}>
										<div className={item.pinned === 1 ? 'item active' : 'item'}  onClick={() => setIFrameUrl(item.link)}>
											{item.pinned === 1 ? <img src={require('./../assets/images/icons/star-icon.png')} alt="Pin" width="12" title="Remove from favourites" onClick={() => handleReportPin(item.product_id, 0)} /> : <img src={require('./../assets/images/icons/star-empty-icon.png')} alt="Pin" width="12" title="Add to favourites" onClick={() => handleReportPin(item.product_id, 1)} />}
											<div>
												<img src={item.icon && item.icon} alt={item.value} width="50" />
											</div>
											<span>{item.product_name}</span>
											{btn}
										</div>
									</div>)})}
								</Carousel>
							</section>}

							{selectFunctions.length > 0 && <section className="client-subscription mt-0">
								{/* <ul className="subscription">
									{selectFunctions.map((item, index) => (
										unsubscribedFunctionList.includes(item.value) && <li key={index}>
											<span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
											<Form.Check inline label={item.value} name="functions" type="checkbox" id={'subscribe-'+item.value} value={item.value}  defaultChecked={true} onChange={handleUnsubscribedFunctionsCheck} onClick={(e) => e.stopPropagation()} />
										</li>
									))}
								</ul> */}
								<Carousel responsive={responsive} className="subscription">
									{selectFunctions.map((item, index) => (
										/* unsubscribedFunctionList.includes(item.value) && <div key={index} className='label-wrap'> */
										(/* viewReportsPrivileged ? functions.some(el => el.function === item.value) :  */unsubscribedFunctionList.includes(item.value)) && <div key={index} className='label-wrap'>
											<label htmlFor={'subscribe-'+item.value}>
												<input name="functions-unsubscribed" type="radio" id={'subscribe-'+item.value} value={item.value} onChange={handleUnsubscribedFunctionsCheck} /* onClick={(e) => e.stopPropagation()} */ defaultChecked={index===0} />
												<span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
												<span className="text">{item.value}</span>
											</label>
											{/* <span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
											<Form.Check inline label={item.value} name="functions" type="radio" id={'subscribe-'+item.value} value={item.value}  defaultChecked={true} onChange={handleUnsubscribedFunctionsCheck} onClick={(e) => e.stopPropagation()} /> */}
											{/* <button className='subscribe' title='Subscribe' onClick={(e) => handleRequest(item.value, e)}>Subscribe</button> */}
										</div>
									))}
								</Carousel>
							</section>}
						</Container>
					</main>

					{/* <aside>
						<AskAthena token={token} handleLogout={handleLogout} />
					</aside> */}
				</div>
			</div>
		</>
	);
}
 
export default Subscription;