import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const AddDataSources = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [finalInputs, setFinalInputs] = useState({});

  const [industry, setIndustry] = useState("");
  const [nextDisabled, setNextDisabled] = useState(true);

  const [dataSources, setDataSources] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    if (location.state !== null) {
      console.log("location.state", location.state);
      setFinalInputs(location.state);
      if(location.state.dataSource){
        setInputs(location.state.dataSource);
      }
    }
    console.log(finalInputs);

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    setTimeout(() => {
      if(document.querySelector('input[name="dataSource"]:checked') !== null){
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    }, 2000);
  }, []);

	useEffect(() => {
		if(token !== null && token !== undefined){
      /* axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"data_sources"})
      .then(response=>{
        console.log("data_sources lookup:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("data_sources lookup data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            console.log(JSON.parse(response.data.items[0].attribute1));
            console.log(JSON.parse(response.data.items[0].attribute1));
            setDataSources(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err)); */

      axios.post(serverConfig.api_base_url + "data_source_get",{token:token})
      .then(response=>{
        console.log("data_source_get lookup:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("data_source_get", response.data.items);
            setDataSources(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
		}
	},[token]);

  const handleMutliSelect = (event) => {
    //console.log(event.currentTarget, event.target.parentNode);
    //console.log($(this));
    var thisEl = event.target;
    if (thisEl.nextSibling.style.display === "none") {
      thisEl.nextSibling.style.display = "block";
    } else {
      thisEl.nextSibling.style.display = "none";
    }
    //$(event.target).next('ul').toggle();
  }

  const handleShowDropdown = (event) => {
    /* document.querySelector(this).next('ul').toggle();
    e.stopPropagation();
    e.preventDefault(); */
    console.log(event.target.value);
    if(event.target.value === ""){
      let el = event.target.closest('.dropdown-list').querySelectorAll(' .dropdown-list > li > div > .form-check-input');
      if(event.target.checked){
        event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "";
        for(let i=0; i < el.length; i++){
          console.log(i, el.length, el[i].value);
          el[i].checked = true;
          if(el[i].value !== ""){
            if(i === el.length -1){
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value);
            } else {
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value+', ');
            }
          }
        }
      } else {
        event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "Select from below";
        for(let i=0; i < el.length; i++){
          console.log(el[i].nodeName);
          el[i].checked = false;
        }
      }
    } else {// if individual select
      let el = event.target.closest('.dropdown-list').querySelectorAll(' .dropdown-list > li > .sub-dropdown-list');
      for(let i=0; i < el.length; i++){
        el[i].style.display = "none";
      }
      if(event.target.checked){
        event.target.closest('li').querySelector('ul.sub-dropdown-list').style.display = "block";
      } else {
        event.target.closest('li').querySelector('ul.sub-dropdown-list').style.display = "none";
      }
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(name, value);

    if(event.target.type === "checkbox"){
      var checkboxes = document.querySelectorAll('ul.sub-dropdown-list > li > div > .form-check-input');
      var checkboxesChecked = [];
      
      if(event.target.value === ""){
        let el = event.target.closest('ul').querySelectorAll('ul > li > div > .form-check-input');
        if(event.target.checked){
          for(let i=0; i < el.length; i++){
            console.log(el[i].value);
            el[i].checked = true;
          }
        } else {
          for(let i=0; i < el.length; i++){
            console.log(el[i].value);
            el[i].checked = false;
          }
        }
      } else {// if individual select
        if(event.target.closest('li').querySelector('ul.sub-dropdown-list') !== null){
          let elNode = event.target.closest('.dropdown-list').querySelectorAll(' .dropdown-list > li > .sub-dropdown-list');
          
          for(let i=0; i < elNode.length; i++){
            elNode[i].style.display = "none";
          }
          if(event.target.checked){
            event.target.closest('li').querySelector('ul.sub-dropdown-list').style.display = "block";
          } else {
            event.target.closest('li').querySelector('ul.sub-dropdown-list').style.display = "none";
            let el = event.target.closest('li').querySelectorAll('ul.sub-dropdown-list > li > div > .form-check-input');
            for(let i=0; i < el.length; i++){
              el[i].checked = false;
            }
          }
        }
      }

      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        console.log(checkboxes[i].value, checkboxes[i].checked);
        if (checkboxes[i].checked && checkboxes[i].value !== "") {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      setInputs(checkboxesChecked);
		}

    if(document.querySelector('input[name="dataSource"]:checked') !== null){
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    navigate('/choose-data-entities',{state:{dataSource:inputs}})
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Setup New Client</li>
                  <li>|</li>
                  <li>Analytics Admin Screen</li>
                </ul>
              </div>
              <h1 className="mb-4">Data Sources &amp; Data Entities | {industry}</h1>
              <Form className='mt-5' onSubmit={handleSubmit}>
                <h6>Choose the desired Data Source from below:</h6>
                <Form.Group className="mt-4">
                  <div>
                    <button className="btn btn-transparent-gray dropdown nested" type="button" onClick={handleMutliSelect}> Select from below:</button>
                    <ul className="dropdown-list" style={{display: 'none'}}>
                      <li><Form.Check value="" type="checkbox" id="select-all" label="Select all" onChange={handleChange} /></li>
                      {dataSources.length > 0 &&
                        dataSources.map((itm, idx) => <li key={idx}>
                          <Form.Check name="dataSource" value={itm.system} type="checkbox" id={`select-${itm.system}`} label={itm.system} onChange={handleChange} />
                          <ul className="sub-dropdown-list">
                            <li><Form.Check name={itm.system} value="" type="checkbox" id={`select-all-${itm.system}`} label="Select all" onChange={handleChange} /></li>
                            {itm['ds_name'] ? itm['ds_name'].length > 0 &&
                              itm['ds_name'].map((el) => <li><Form.Check name={itm.system} value={el} type="checkbox" id={`select-${itm.system}-${el}`} label={el} onChange={handleChange} /></li>)
                              : <></>
                            }
                          </ul>
                        </li>)
                      }
                    </ul>
                    {/* <ul className="dropdown-list" style={{display: 'none'}}>
                      <li><Form.Check value="" type="checkbox" id="select-all" label="Select all" onChange={handleChange} /></li>
                      <li>
                        <Form.Check name="dataSource" value="File" type="checkbox" id="select-file" label="File" onChange={handleChange} />
                        <ul className="sub-dropdown-list">
                          <li><Form.Check name="file" value="" type="checkbox" id="select-all-file" label="Select all" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="MS Excel" type="checkbox" id="select-ms-excel" label="MS Excel" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="Text/CSV" type="checkbox" id="select-text-csv" label="Text/CSV" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="XML" type="checkbox" id="select-xml" label="XML" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="JSON" type="checkbox" id="select-json" label="JSON" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="Folder" type="checkbox" id="select-folder" label="Folder" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="PDF" type="checkbox" id="select-pdf" label="PDF" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="Parquet" type="checkbox" id="select-parquet" label="Parquet" onChange={handleChange} /></li>
                          <li><Form.Check name="file" value="Share point" type="checkbox" id="select-share-point" label="Share point" onChange={handleChange} /></li>
                        </ul>
                      </li>
                      <li>
                        <Form.Check name="dataSource" value="Database" type="checkbox" id="select-database" label="Database" onChange={handleChange} />
                        <ul className="sub-dropdown-list">
                          <li><Form.Check name="database" value="" type="checkbox" id="select-all-db" label="Select all" onChange={handleChange} /></li>
                          <li><Form.Check name="database" value="Oracle" type="checkbox" id="select-oracle-db" label="Oracle" onChange={handleChange} /></li>
                          <li><Form.Check name="database" value="MySQL" type="checkbox" id="select-mysql-db" label="MySQL" onChange={handleChange} /></li>
                          <li><Form.Check name="database" value="Postgresql" type="checkbox" id="select-postgresql-db" label="Postgresql" onChange={handleChange} /></li>
                          <li><Form.Check name="database" value="SQL" type="checkbox" id="select-sql-db" label="SQL" onChange={handleChange} /></li>
                          <li><Form.Check name="database" value="Other" type="checkbox" id="select-other-db" label="Other" onChange={handleChange} /></li>
                        </ul>
                      </li>
                      <li>
                        <Form.Check name="dataSource" value="Online services" type="checkbox" id="select-online-services" label="Online services" onChange={handleChange} />
                        <ul className="sub-dropdown-list">
                          <li><Form.Check name="online_services" value="" type="checkbox" id="select-all-online-services" label="Select all" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Amazon" type="checkbox" id="select-os-amazon" label="Amazon" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Best Buy" type="checkbox" id="select-os-bestbuy" label="Best Buy" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="X" type="checkbox" id="select-os-x" label="X" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="YouTube" type="checkbox" id="select-os-youtube" label="YouTube" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Instagram" type="checkbox" id="select-os-instagram" label="Instagram" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Zendesk" type="checkbox" id="select-os-zendesk" label="Zendesk" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="SAP Sales Cloud" type="checkbox" id="select-os-sap-sales-cloud" label="SAP Sales Cloud" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Salesforce" type="checkbox" id="select-os-salesforce" label="Salesforce" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Microsoft Dynamics" type="checkbox" id="select-os-ms-dynamics" label="Microsoft Dynamics" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Monday.com" type="checkbox" id="select-os-monday-com" label="Monday.com" onChange={handleChange} /></li>
                          <li><Form.Check name="online_services" value="Nielsen" type="checkbox" id="select-os-nielsen" label="Nielsen" onChange={handleChange} /></li>
                        </ul>
                      </li>
                      <li>
                        <Form.Check name="dataSource" value="Other" type="checkbox" id="select-other" label="Other" onChange={handleChange} />
                        <ul className="sub-dropdown-list">
                          <li><Form.Check name="other" value="" type="checkbox" id="select-all-other" label="Select all" onChange={handleChange} /></li>
                          <li><Form.Check name="other" value="Other" type="checkbox" id="select-other" label="Other" onChange={handleChange} /></li>
                        </ul>
                      </li>
                    </ul> */}
                  </div>
                </Form.Group>
                <Row className="mt-5">
                  <Col>
                    <button type="button" className="btn-transparent" onClick={() => navigate('/')}>Cancel</button>
                    <button type="submit" className="btn-yellow ms-4" disabled={nextDisabled}>Connect</button>
                  </Col>
                </Row>
              </Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default AddDataSources;