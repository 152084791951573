import React, {useState, useEffect} from 'react';
import { Row, Col, Button, Form, Modal} from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { MDBDataTable } from "mdbreact";
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import axios from "axios";
import UserService from '../services/UserService';
 
const serverConfig = require("../config/server.js");

const Users = () => {

  const [token, setToken] = useState(null);

  const [appDataGridRender, setAppDataGridRender] = useState();
  const [actions, setActions] = useState(false);
  //const [userPrivileged, setUserPrivileged] = useState(true);

  const [userSaasModule, setUserSaasModule] = useState([]);
  const [functionList, setFunctionList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      console.log('setting token');
      setToken(sessionStorage.getItem('token'));
    }
  }, []);
  
  useEffect(() => {
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "modules_list_get", {token:token})
			.then(response=>{
				console.log('modules_list_get response', response);
				if(response.data.status === 200){
					if(response.data.items !== null){
						console.log('modules_list_get response', response.data.items);
						setUserSaasModule(response.data.items);
					} else {
						setUserSaasModule([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "modules"})
			.then(response=>{
				console.log("modules entity_details_get response:", response);
				if (response.data.status === 200) {
					console.log('success');
					if(response.data.items !== null){
						setFunctionList(response.data.items.biModules.functions);
					} else {
						setFunctionList([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    console.log('token', token);
    console.log(sessionStorage.getItem('token'));
    //console.log('userPrivileged', userPrivileged);
    
    //if(token !== null && token !== undefined && userPrivileged){
    if(token !== null && token !== undefined){
      console.log('token', token);
      /* user_list_get */
      axios.post(serverConfig.api_base_url + "user_list_get",{token:token})
      .then(response=>{
        console.log("user_list_get response:", response);
        if(response.data.status === 200){
          let items = response.data.items;
          console.log(items);
          if (items !== null || items !== undefined){
            let appData = [];
            items.reverse().map((item, index) => {
              let curRole = [];
              curRole = item.roles;
              //console.log(curRole);
              //console.log(Array.of(curRole));
              return (<>
                {/* {item.roles = [<div><ul>{item.roles.map((i, k) => {
                  return (<li key={k}>{i}</li>)
                })}</ul><button className='btn-icon ms-2' title='Edit user role'><i className="far fa-edit"></i></button></div>]} */}

                {item.roles = [<div><ul className="ps-2"> { curRole && curRole.map((itemRole, i) => <li key={i}>{ itemRole }</li>) }</ul><button className='btn-icon ms-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]}

                {/* {item.roles = [<div><ul><li>{item.roles}</li></ul><button className='btn-icon ms-2' title='Edit user role' onClick={() => handleRolesModal(item.name, item.email_id, curRole)}><i className="far fa-edit"></i></button></div>]} */}
            
                {item.active = item.active === 0 ? (<><button className='btn-icon me-2' title='Enable user' onClick={() => handleActive(item.email_id, 1)}><i className="far fa-user txt-gray"></i></button> Disabled</>) : (<><button className='btn-icon me-2' title='Disable user' onClick={() => handleActive(item.email_id, 0)}><i className="fas fa-user"></i></button> Enabled</>)}

                {item.locked = item.locked === 0 ? (<><button className='btn-icon me-2' title='Lock user' onClick={() => handleLocked(item.email_id, 1)}><i className="fas fa-unlock"></i></button> Unlocked</>) : (<><button className='btn-icon me-2' title='Unlock user' onClick={() => handleLocked(item.email_id, 0)}><i className="fas fa-lock"></i></button> Locked</>)}

                {item.actions = (<><button className='btn-icon me-4' onClick={() => handleChPWModal(item.name, item.email_id)} title="Password change"><i className="fas fa-key"></i></button>{/* <button className='btn-icon me-2' onClick={() => handleChPWModal(item.name, item.email_id)}><u>Change password</u></button> */}</>)}

                {appData.push(item)}
              </>);
            });
            console.log(appData);
            setAppDataGridRender(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      profileListGet();

      /* user_role_list_get */
      axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
      .then(response=>{
        console.log('user_role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, actions]);

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: true, key: 'name' },
      { label: 'Email Id', field: 'email_id', sort: false, key: 'email_id' },
      { label: 'Active', field: 'active', sort: false, key: 'active' },
      { label: 'Locked', field: 'locked', sort: false, key: 'locked' },
      { label: 'Roles', field: 'roles', sort: false, key: 'roles' },
      { label: 'Actions', field: 'actions', sort: false, key: 'roles' },
    ],
    rows: appDataGridRender,
  };

  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [rolesInputs, setRolesInputs] = useState([]);
  const [curUserRole, setCurUserRole] = useState([]);

  const [rolesModalShow, setRolesModalShow] = useState(false);
  const handleCloseRolesModal = () => {
    setRolesInputs([]);//clear roles checkbox on close of modal
    setCurUserRole([]);
    setCurrentUser(null);
    setCurrentUserEmail(null);
    setCurrentUserRole(null);
    setClicked(false);
    setRolesModalShow(false);
  }

  const handleRolesModal = (username, useremail, roles) => {
    console.log(username, useremail, roles);
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setCurrentUserRole(roles);
    setRolesModalShow(true);
  }

  const [clicked, setClicked] = useState(false);
  const handleCheckboxChange = (event) => {
    const roleId = event.target.id;
    console.log('clicked', clicked);
    if(!clicked){
      if(currentUserRole !== null && currentUserRole.length){
        setClicked(true);
        setRolesInputs(currentUserRole);
      }
    }
    if (event.target.checked) {
      setRolesInputs(values => ([...values, roleId ]));
    } else {
      if(clicked){
        setRolesInputs(rolesInputs.filter(e => e !== roleId));
      } else {
        setRolesInputs(currentUserRole && currentUserRole.filter(e => e !== roleId));
      }
    }
  }

  const handleApplyRole = () => {
    //console.log(roles);
    console.log('rolesInputs:', rolesInputs);
    console.log('CurrentUserRole:', currentUserRole);
    var grantList = [];
    var revokeList = [];
    // compare currentUserRole and rolesInputs
    for(var i = 0; i < rolesList.length; ++i) {
      let role = rolesList[i];
      if (rolesInputs.includes(role) && (currentUserRole === null || !currentUserRole.includes(role))) {
        grantList.push(role);
      } else if (currentUserRole != null && currentUserRole.includes(role) && !rolesInputs.includes(role)) {
        revokeList.push(role);
      }
    }
    console.log('grant list: ', grantList);
    console.log('revoke list:', revokeList);
    var changed = false;

    if(grantList.length){
      axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: currentUserEmail, roles: grantList})
      .then(response=>{
        console.log('user_grant_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }

    if (revokeList.length) {
      axios.post(serverConfig.api_base_url + "user_revoke_role",{token: token, email_id: currentUserEmail, roles: revokeList})
      .then(response=>{
        console.log('user_revoke_role response', response);
        if(response.data.status === 200){
          console.log('success');
          if (!changed) {
            changed=true;
            setActions(!actions);// to refresh user list after role is updated
          }
          handleCloseRolesModal();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  /* const [exPWMsg, setExPWMsg] = useState("");
  const [showExPWModal, setShowExPWModal] = useState(false);
  const closeExPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setExPWMsg("");
    setShowExPWModal(false);
  }
  const handleExPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowExPWModal(true);
  } */  
  const handleExpirePwd = () => {
    console.log({token: token, email_id: currentUserEmail});
    axios.post(serverConfig.api_base_url + "user_expire_password",{token: token, email_id: currentUserEmail})
    .then(response=>{
      console.log('user_expire_password response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [passwordSubmitDisabled, setPasswordSubmitDisabled] = useState(true);
  const [passwordChangeType, setPasswordChangeType] = useState(null);
  const [chPWMsg, setChPWMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChPWModal, setShowChPWModal] = useState(false);
  const closeChPWModal = () => {
    setCurrentUser("");
    setCurrentUserEmail("");
    setChPWMsg("");
    setNewPassword("");
    setPasswordSubmitDisabled(true);
    setShowChPWModal(false);
  }
  const handleChPWModal = (username, useremail) => {
    setCurrentUser(username);
    setCurrentUserEmail(useremail);
    setShowChPWModal(true);
  }
  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'changePwdGenerate'){
          setNewPassword(response.data.password);
        } else if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            password:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }
  const handlePasswordChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value);

    if(event.target.id === "new_password") {
      if(event.target.checked){
        document.getElementById("password").disabled = false;
        document.getElementById("password").required = true;
        document.getElementById("password").closest(".row").classList.add('d-flex');
        document.getElementById("password").closest(".row").classList.remove('d-none');
      } else {
        document.getElementById("password").disabled = true;
        document.getElementById("password").required = false;
        document.getElementById("password").closest(".row").classList.add('d-none');
        document.getElementById("password").closest(".row").classList.remove('d-flex');
        document.getElementById("password").value = "";
        setNewPassword("");
      }
    }

    var checkboxes = document.querySelectorAll('input[name="changePassword"]');
    var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    if(checkedOne){
      setPasswordSubmitDisabled(false);
    } else {
      setPasswordSubmitDisabled(true);
    }

    if(document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //only option 1 checked
      setPasswordChangeType("expire_password");
    } else if (!document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //only option 2 checked
      setPasswordChangeType("change_password_temp_0");
    } else if(document.querySelector('#change_password').checked && document.querySelector('#new_password').checked){
      //both option 1 and option 2 checked
      setPasswordChangeType("change_password_temp_1");
    } else if(!document.querySelector('#change_password').checked && !document.querySelector('#new_password').checked){
      //none
      setPasswordChangeType(null);
    }

    if(name === 'password'){
      setNewPassword(value);
    }
  }
  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();
    console.log(newPassword);
    console.log(passwordChangeType);
    if(passwordChangeType === "expire_password"){
      handleExpirePwd();
    } else if (passwordChangeType === "change_password_temp_0"){
      handleChangePwd(0);
    } else if (passwordChangeType === "change_password_temp_1"){
      handleChangePwd(1);
    }
  }
  const handleChangePwd = (tempVal) => {
    console.log({token: token, email_id: currentUserEmail, password: newPassword, temporary: tempVal});
    axios.post(serverConfig.api_base_url + "user_password_change ",{token: token, email_id: currentUserEmail, password: newPassword, temporary: tempVal})
    .then(response=>{
      console.log('user_password_change  response', response);
      if(response.data.status === 200){
        console.log('success');
        setChPWMsg("Successful");
        setTimeout(() => {
          closeChPWModal();
        }, 1000);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleActive = (emailid, changeActive) => {
    console.log(emailid);
    console.log(changeActive);

    axios.post(serverConfig.api_base_url + "user_active",{token: token, email_id: emailid, active: changeActive})
    .then(response=>{
      console.log('user_active response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user active is set 0/1
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLocked = (emailid, changeLock) => {
    console.log(emailid);
    console.log(changeLock);

    axios.post(serverConfig.api_base_url + "user_locked",{token: token, email_id: emailid, locked: changeLock})
    .then(response=>{
      console.log('user_locked response', response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is locked / unlocked
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [inputs, setInputs] = useState({});
  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const handleCloseCreateUserModal = () => {
    setInputs({})
    setCreateUserModalShow(false);
  }
  const handleShowCreateUserModal = () => setCreateUserModalShow(true);

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

    if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			}
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    console.log(inputs);
    var formdata = {
      token:token,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email_id: inputs.email_id,
      password: inputs.password,
    }
    console.log(formdata);

    axios.post(serverConfig.api_base_url + "user_create",formdata)
    .then(response=>{
      console.log("User create response:", response);
      if(response.data.status === 200){
        console.log('success');
        setActions(!actions);// to refresh user list after user is created
        handleCloseCreateUserModal();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} />
        <main>
          <Container fluid>
            <section className='d-flex align-items-center justify-content-between mb-5'>
              <h1>Users</h1>
              {/* Create User */}
              <button type="button" className='btn-transparent' onClick={handleShowCreateUserModal}>Create</button>
              <Modal show={createUserModalShow} onHide={handleCloseCreateUserModal} backdrop="static"keyboard={false} centered className='modal-medium'>
                <Form onSubmit={handleCreateUser}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" className='rounded-0' placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" className='rounded-0' placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control type="email" className='rounded-0' placeholder="Email (Username)" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                    </Form.Group>
                    <Row className='align-items-end'>
                      <Col>
                        <div className='position-relative'>
                          <Form.Control type="password" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required className='rounded-0 pe-4' />
                          <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                        </div>
                      </Col>
                      <Col><button type="button" id="createUserPwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2'>Generate Password</button></Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="button" className="btn-transparent" onClick={handleCloseCreateUserModal}>Cancel</button>
                    <button type="submit" className='btn-yellow ms-3'><i className="fas fa-user-plus me-2"></i> Create</button>
                  </Modal.Footer>
                </Form>
              </Modal>
            </section>

            {/* User list table */}
            <section>
              <div className='datatable datatable-users list-table'>
                <MDBDataTable striped small borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here" />
              </div>
            </section>

            {/* Edit user role */}
            <Modal show={rolesModalShow} onHide={handleCloseRolesModal} backdrop="static"keyboard={false} centered className='modal-small'>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Edit User Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Edit roles for {currentUser}</p>
                  <Row className='justify-content-center'>
                    {rolesList.map((item, index) => (
                      <Col key={index} xs="auto"><Form.Check type="checkbox" id={item} value={item} label={item} defaultChecked={currentUserRole && currentUserRole.includes(item)} onChange={handleCheckboxChange} /></Col>
                    ))}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn-transparent" onClick={handleCloseRolesModal}>Cancel</button>
                  <button type="button" onClick={handleApplyRole} className='btn-yellow ms-3'>Apply</button>
                </Modal.Footer>
              </Form>
            </Modal>

            {/* Change Password */}
            <Modal show={showChPWModal} onHide={closeChPWModal} backdrop="static"keyboard={false} centered>
              <Form onSubmit={handlePasswordChangeSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Password change for {currentUser}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <p>Change password for {currentUser}</p>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="New password" name="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                  </Form.Group>
                  <p className="txt-green">{chPWMsg}</p> */}
                  <Form.Group className='mb-3'>
                    <Form.Check name="changePassword" type="checkbox" id="change_password" label="User must change password on next login" value="User must change password on next login" className='mb-3' onChange={handlePasswordChange} />
                    <Form.Check name="changePassword" type="checkbox" id="new_password" label="Set a new password for the user" value="Set a new password for the user" className='mb-3' onChange={handlePasswordChange} />
                    <Row className='d-none align-items-end'>
                      <Col>
                        <div className='position-relative'>
                          <Form.Control type="password" id="password" name="password" placeholder="New password" disabled onChange={handlePasswordChange} value={newPassword} className='rounded-0 pe-4' />
                          <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                        </div>
                      </Col>
                      <Col xs="auto"><button type="button" id="changePwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2'>Generate Password</button></Col>
                    </Row>
                  </Form.Group>
                  <p className="txt-green">{chPWMsg}</p>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn-transparent" onClick={closeChPWModal}>Cancel</button>
                  <button type="submit" className='btn-yellow ms-3' disabled={passwordSubmitDisabled}>Submit</button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Container>
        </main>
      </div>
    </>
  );
}

export default Users;
