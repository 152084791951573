import React, {useEffect, useState} from 'react';
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/LoanUnderwrite/Header';
import Steps from '../components/LoanUnderwrite/Steps';
import PortfolioRecommendations from './PortfolioRecommendations.jsx';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const UnderwriteLoan = ({applicationInputs}) => {

  const[revealDetails, setRevealDetails] = useState(false);

  const [token, setToken] = useState(null);
  const [company, setCompany] = useState("");

  const [currentStep, setCurrentStep] = useState("approval-status");

  const [loanUnderwrite, setLoanUnderwrite] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }

    console.log(currentStep);
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "loan_underwrite", {token:token})
			.then(response=>{
				console.log('loan_underwrite response', response.data);
				if(response.data.status === 200){
					if(response.data.items !== null) {
						setLoanUnderwrite(response.data.items);
					} else {
						setLoanUnderwrite({});
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleSubmit = () => {
    axios.post(serverConfig.api_base_url + "loan_underwrite_set",{token:token, entity:"approval_status", items:{
      sanctionedAmount: applicationInputs.loanAmount,
      approvalStatus: document.getElementById("approvalStatus").innerText,
      irr: document.getElementById("irr").innerText,
      occupationStability: document.getElementById("occupationStability").innerText,
      residentsVerification:loanUnderwrite.cpv,
      propertyAppraisal: loanUnderwrite.cpv,
      priorDelinquency: document.getElementById("priorDelinquency").innerText,
      fraudCheck: document.getElementById("fraudCheck").innerText,
      foir: document.getElementById("foir").innerText,
      creditBureau: document.getElementById("creditBureau").innerText,
      ltv: document.getElementById("ltv").innerText
    }})
    .then(response=>{
      console.log("loan_underwrite_set response:", response);
      if (response.data.status === 200) {
        console.log('success');
        setCurrentStep("portfolio-impact")
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (<>
    {currentStep === "approval-status" && <div className='loan-underwrite'>
      <Header token={token} setCompany={setCompany} />
      {loanUnderwrite && <Container fluid className='container-wrapper'>
        {showAlert && <div className='alert-msg'><Container fluid>{alertMsg}</Container></div>}
        <Steps currentStep="Status" />

        <Row className='txt-yellow underwrite-loan'>
          <Col sm={4} className='text-center'>
            <p><small>Sanctioned Amount</small></p>
            <div className='icon mb-2'><img src={require('./../assets/images/cash-icon.png')} alt="Sanctioned Amount" /></div>
            <p>{applicationInputs.loanAmount}</p>
          </Col>
          <Col sm={4} className='text-center'>
            <p><small>Approval Status</small></p>
            <div className='icon mb-2'>
              {loanUnderwrite.profisor_isapproved === 1 ? ((loanUnderwrite.cpv === 'Positive' && loanUnderwrite.fcu_status === 'Positive' && loanUnderwrite.are_repayments_of_all_the_loans__regular === 0) ? <img src={require('./../assets/images/approved-icon.png')} alt="Approval Status" /> : <img src={require('./../assets/images/referred.png')} alt="Approval Status" />) :
              (loanUnderwrite.profisor_isapproved === 0 && <img src={require('./../assets/images/declined.png')} alt="Approval Status" />)}
            </div>
            <p id="approvalStatus">
              {loanUnderwrite.profisor_isapproved === 1 ? ((loanUnderwrite.cpv === 'Positive' && loanUnderwrite.fcu_status === 'Positive' && loanUnderwrite.are_repayments_of_all_the_loans__regular === 0) ? 'Approved' : 'Under Review') :
              (loanUnderwrite.profisor_isapproved === 0 && 'Declined')}
            </p>
          </Col>
          <Col sm={4} className='text-center'>
            <p><small>IRR</small></p>
            <div className='icon mb-2'><img src={require('./../assets/images/meter-icon.png')} alt="IRR" /></div>
            <p id="irr">{loanUnderwrite.recommendation_irr && parseFloat((loanUnderwrite.recommendation_irr).match(/[\d\.]+/))}%</p>
          </Col>
        </Row>
        <div className='mt-4 mb-5 text-center'><Button variant='plain' onClick={() => setRevealDetails(!revealDetails)}>{revealDetails ? 'Hide Details' : 'Explain Decision'}</Button></div>

        {revealDetails && <Table>
          <thead>
            <tr>
              <th>Occupation Stability</th>
              <th>Residents Verification</th>
              <th>Property Appraisal</th>
              <th>Prior Delinquency</th>
              <th>Fraud Check</th>
              <th>FOIR</th>
              <th>Credit Bureau</th>
              <th>LTV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="occupationStability">
                {Number(applicationInputs.occupationTenure) < 1 ? 'Low' : undefined}
                {(Number(applicationInputs.occupationTenure) >= 1 && Number(applicationInputs.occupationTenure) <= 3) && 'Medium'}
                {(Number(applicationInputs.occupationTenure) > 3) && 'High'}
              </td>
              <td>{loanUnderwrite.cpv}</td>
              <td>{loanUnderwrite.cpv}</td>
              <td id="priorDelinquency">{loanUnderwrite.are_repayments_of_all_the_loans__regular === 1 ? 'No' : 'Yes'}</td>
              <td id="fraudCheck">
                {loanUnderwrite.fcu_status === "Positive" && "Negative"}
                {loanUnderwrite.fcu_status === "Negative" && "Positive"}
                {(loanUnderwrite.fcu_status === null || loanUnderwrite.fcu_status === "Refer") && "Under Review"}
              </td>
              <td id="foir">{(((Number(applicationInputs.loanAmount)*0.01)/Number(applicationInputs.monthlyIncome))*100).toFixed(2)}%</td>
              <td id="creditBureau">
                {loanUnderwrite.cibil === 'Negative' && 'Low'}
                {loanUnderwrite.cibil === 'Refer' && 'Medium'}
                {loanUnderwrite.cibil === 'Positive' && 'High'}
              </td>
              <td id="ltv">{((Number(applicationInputs.selfContribution))/Number(applicationInputs.propertyValue))*100}%</td>
            </tr>
          </tbody>
        </Table>}

        <div className='text-center mt-5 mb-5'>
          {/* <Button variant="primary" onClick={() => navigate('/PortfolioRecommendations',{state:{applicationInputs:applicationInputs, loanUnderwrite:loanUnderwrite}})}>Portfolio Recommendations</Button> */}
          <Button variant="primary" onClick={handleSubmit}>Portfolio Recommendations</Button>
        </div>
      </Container>}
    </div>}
    {currentStep === "portfolio-impact" && <PortfolioRecommendations applicationInputs={applicationInputs} loanUnderwrite={loanUnderwrite} />}
  </>);
}

export default UnderwriteLoan;