import React, {useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const SupportHealth = () => {

  const [token, setToken] = useState(null);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

	return (
    <>
			<LeftNav />
			<div className="main-wrapper">
        <Header token={token} />
        <main>
          <Container fluid>
            <div style={{height: "60vh", display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center"}}>
              <h1 className='text-center mb-5'>Service issues, requests, open tickets, resolution, and support FAQs will be displayed here.</h1>
              <h2 className='txt-yellow'>Under-development.</h2>
            </div>
          </Container>
        </main>
      </div>
    </>
	);
}
 
export default SupportHealth;