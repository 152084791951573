import React, {useState} from 'react';
import { Button, Row, Col } from 'react-bootstrap';
//import { MobileView } from 'react-device-detect';

const Steps = ({currentStep}) => {
  const[stepsCollapse, setStepsCollapse] = useState(false);

  var step1class, step2class, step3class;
  if(currentStep==="Details"){
    step1class = 'active';
  } else if(currentStep==="Status"){
    step1class = 'completed';
    step2class = 'active';
  } else if(currentStep==="PortfolioImpact"){
    step1class = 'completed';
    step2class = 'completed';
    step3class = 'active completed';
  }

  return (
    <>
      <div className={stepsCollapse ? 'steps collapsed' : 'steps'}>
        <ul>
          <li className={step1class}>
            <img src={require('./../../assets/images/details-icon.png')} alt="Loan Application" width="70" />
            <span><span className='step-num'>STEP 1</span>Loan Application</span>
          </li>
          <li className={step2class}>
            <img src={require('./../../assets/images/approve-icon.png')} alt="Approval Status" width="70" />
            <span><span className='step-num'>STEP 2</span>Approval Status</span>
          </li>
          <li className={step3class}>
            <img src={require('./../../assets/images/portfolio-icon.png')} alt="Portfolio Impact" width="70" />
            <span><span className='step-num'>STEP 3</span>Portfolio Impact</span>
          </li>
        </ul>
        <div className='d-none'>
          <Button variant="plain" onClick={() => setStepsCollapse(!stepsCollapse)}><img src={require('./../../assets/images/angle-icon.png')} width="15" /></Button>
        </div>
      </div>
    </>
  );
}

export default Steps;