import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Table, Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const ManageUsers = () => {

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [userSaasModule, setUserSaasModule] = useState([]);
  const [biModuleList, setBIModuleList] = useState({});
  const [inputs, setInputs] = useState({});
  const [userInputs, setUserInputs] = useState({});
  const [actions, setActions] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [userList, setUserList] = useState([]);
  const [userFunctionList, setUserFunctionList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      /* profile_list_get */
      profileListGet();

      /* user_role_list_get */
      axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
      .then(response=>{
        console.log('user_role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "modules_list_get", {token:token})
      .then(response=>{
        console.log('modules_list_get response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            console.log('modules_list_get response', response.data.items);
            setUserSaasModule(response.data.items);
          } else {
            setUserSaasModule([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "modules"})
			.then(response=>{
				console.log("modules entity_details_get response:", response);
				if (response.data.status === 200) {
					console.log('success');
					if(response.data.items !== null){
						setBIModuleList(response.data.items.biModules);
					} else {
            setBIModuleList({});
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
    if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "user_function_list_get",{token:token})
      .then(response=>{
        console.log("user_function_list_get response:", response);
        if (response.data.status === 200) {
          console.log('success');
          if(response.data.items !== null){
            setUserList(response.data.items);
            setUserFunctionList(response.data.items);
          } else {
            setUserList([]);
            setUserFunctionList([]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, actions]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

		if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

		if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			}
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}

    console.log(inputs);
  }

  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            password:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  const handleCreateUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(inputs);
    var formdata = {
      token:token,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email_id: inputs.email_id,
      password: inputs.password,
    }
    console.log(formdata);

    /* user_exists_check - start */
    axios.post(serverConfig.api_base_url + "user_exists_check",{token:token, email_id:inputs.email_id})
    .then(response=>{
      console.log("user_exists_check response:", response);
      if (response.data.status === 200) {
        if(response.data.user_exists === 0){
          axios.post(serverConfig.api_base_url + "user_create",formdata)
          .then(response=>{
            console.log("User create response:", response);
            if(response.data.status === 200){
              /* user_grant_role - start */
              axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: inputs.email_id, roles: [inputs.role]})
              .then(response=>{
                console.log('user_grant_role response', response);
                if(response.data.status === 200){//on success set athena modules subscribed
                  console.log('success');
                  /* modules_subscribed_set - start */
                  inputs.saas_module.forEach(function(el){
                    console.log(el);
                    axios.post(serverConfig.api_base_url + "modules_subscribed_set",{token:token, email_id:inputs.email_id, module:el, profile:inputs.profile, function: inputs.function})
                    .then(response=>{
                      console.log("modules_subscribed_set response:", response);
                      if (response.data.status === 200) {
                        console.log('success');
                        setInputs({});
                        document.getElementById("formCreateUser").reset();
                        setActions(!actions);// to refresh user list after user is created
                        setIsLoading(false);
                      } else if(response.data.status === 301){
                        handleLogout();
                      } else {
                        alert(response.data.message);
                      }
                    })
                    .catch(err=>console.log("error is",err));
                  });
                  /* modules_subscribed_set - end */
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                }
              })
              .catch(err=>console.log("error is",err));
              /* user_grant_role - end */
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        } else if(response.data.user_exists === 1){
          setMessageText('Ignored as '+ inputs.email_id + ' already exists.');
          //handleShowMessage();
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessageText("");
          }, 3000);
          setIsLoading(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    /* user_exists_check - end */
  }

  const [editUserModalShow, setEditUserModalShow] = useState(false);
  const handleCloseEditUserModal = () => {
    setInputs({})
    setEditUserModalShow(false);
  }
  const handleShowEditUserModal = (userDetails) => {
    console.log(userDetails);
    setUserInputs(userDetails);
    setEditUserModalShow(true);
  }
  const handleEditUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(inputs);
    let module = [];
    if(inputs.module instanceof Array){
      module = inputs.module;
    } else {
      module = [inputs.module];
    }
    
    /* user_grant_role - start */
    axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: inputs.email_id, roles: [inputs.role]})
    .then(response=>{
      console.log('user_grant_role response', response);
      if(response.data.status === 200){//on success set athena modules subscribed
        console.log('success');
        /* modules_subscribed_set - start */
        module.forEach(function(el){
          console.log(el);
          axios.post(serverConfig.api_base_url + "modules_subscribed_set",{token:token, email_id:inputs.email_id, module:el, profile:inputs.profile, function: inputs.functions})
          .then(response=>{
            console.log("modules_subscribed_set response:", response);
            if (response.data.status === 200) {
              console.log('success');
              setActions(!actions);// to refresh user list after user is updated
              handleCloseEditUserModal();
              setIsLoading(false);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        });
        /* modules_subscribed_set - end */
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    /* user_grant_role - end */
  }

  const handleSetActive = (email, isActive) => {
    axios.post(serverConfig.api_base_url + "modules_subscribed_set_active",{token:token, email_id: email, is_active: isActive})
    .then(response=>{
      console.log("modules_subscribed_set_active", response);
      if (response.data.status === 200) {
        let function_name = userList[0].functions;
        let module_name = userList[0].module;
        //setUserList(userFunctionList.filter((item) => (item.functions === function_name && item.module === module_name)));
        setUserList(userFunctionList);
        setActions(!actions);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const handleDeactivateUser = (email) => {
    axios.post(serverConfig.api_base_url + "user_deactivate",{token:token, email_id: email})
    .then(response=>{
      console.log("user_deactivate", response);
      if (response.data.status === 200) {
        let function_name = userList[0].functions;
        let module_name = userList[0].module;
        //setUserList(userFunctionList.filter((item) => (item.functions === function_name && item.module === module_name)));
        setUserList(userFunctionList);
        setActions(!actions);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  /* const [showMessage, setShowMessage] = useState(false);
  const handleCloseMessage = () => {
    setMessageText("");
    setShowMessage(false);
  }
  const handleShowMessage = () => setShowMessage(true); */

	return (
    <>
			<LeftNav />
			<div className="main-wrapper">
        <Header token={token} setCompany={setCompany} setIndustry={setIndustry} />
        <main>
          {isLoading && <div id="loading"><img src={require('./../assets/images/icons/spinner.gif')} alt="Loader" width="70" /></div>}
          {showAlert && <div className='alert-msg'><Container fluid>{messageText}</Container></div>}
          <Container fluid>
            <section className="form full-ht">
            <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Manage Users</li>
                </ul>
              </div>
              <h1 className="mb-4">Create or Edit User | {industry}</h1>
              <Row>
                <Col>
                  <Form id="formCreateUser" onSubmit={handleCreateUser}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" className='rounded-0' placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" className='rounded-0' placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control type="email" className='rounded-0' placeholder="Email (Username)" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <div className='profile-wrap full-width'>
                            <Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={inputs.profile || ""} onChange={handleChange} required />
                            <datalist id="profile">
                              {profileList.length > 0 &&
                                profileList.map((item) => <option value={item}>{item}</option>)
                              }
                            </datalist>
                            <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={company} readOnly />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" style={{paddingLeft: '0.75rem'}}>
                          <Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
                          <div>
                            {userSaasModule.length > 0 && userSaasModule.map((item, index) => {
                              return(
                                <Form.Check key={index} inline type="checkbox" name="saas_module" id={"saas-module-"+item} label={item} value={item} onChange={handleChange} required defaultChecked={inputs.saas_module ? inputs.saas_module.includes(item) : false} />
                              )
                            })}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Select aria-label="Function" className="rounded-0" name="function" value={inputs.function || ""} onChange={handleChange} /* required */>
                            <option value="">Function Name</option>
                            {biModuleList && biModuleList.functions && biModuleList.functions.length > 0 &&
                              biModuleList.functions.map((item, index) => <option value={item} key={index}>{item}</option>)
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Select aria-label="User Roles" className="rounded-0" name="role" value={inputs.role || ""} onChange={handleChange} required>
                            <option value="">User Role</option>
                            {rolesList.length > 0 &&
                              rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='align-items-end'>
                      <Col>
                        <div className='position-relative'>
                          <Form.Control type="password" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required className='rounded-0 pe-4' />
                          <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                        </div>
                      </Col>
                      <Col><button type="button" id="createUserPwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2'>Generate Password</button></Col>
                    </Row>
                    <div className='mt-5'>
                      <button type="button" className="btn-transparent" onClick={() => navigate("/")}>Cancel</button>
                      <button type="submit" className='btn-yellow ms-3'><i className="fas fa-user-plus me-2"></i> Create</button>
                    </div>
                  </Form>
                </Col>
                <Col>
                  <Table borderless style={{height:150, overflowY:"auto"}}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      {/* <th>Analytics Client</th> */}
                      <th>Functions</th>
                      <th>Module</th>
                      <th>User Role</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.length > 0 && userList.map((usr,usridx) => <>
                      <tr className={usr.user_is_active === 0 ? 'disabled' : undefined}>
                        <td>{usr.name}</td>
                        {/* <td>{company}</td> */}
                        <td>{usr.functions}</td>
                        <td>{usr.module}</td>
                        <td>{usr.role}</td>
                        <td>{usr.email_id}</td>
                        <td className='text-end'>
                          <label className="switch">
                            <input type="checkbox" defaultChecked={usr.is_active === 1} onChange={() => handleSetActive(usr.email_id, usr.is_active === 1 ? 0 : 1)} />
                            <span className="slider"></span>
                          </label>
                          <button title='Edit' onClick={() => handleShowEditUserModal(usr)}><img src={require('./../assets/images/icons/edit-icon.png')} alt="Edit" /></button>
                          <button title='Delete' className='ms-2' onClick={() => handleDeactivateUser(usr.email_id)}><img src={require('./../assets/images/icons/delete-icon.png')} alt="Delete" height="20" /></button>
                        </td>
                      </tr>
                    </>)}
                  </tbody>
                </Table>
                </Col>
              </Row>
            </section>

            <Modal show={editUserModalShow} onHide={handleCloseEditUserModal} backdrop="static"keyboard={false} centered className='modal-medium'>
              <Form onSubmit={handleEditUser}>
                <Modal.Header closeButton>
                  <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" className='rounded-0' placeholder="Name" name="name" value={userInputs.name || ""} readOnly />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="email" className='rounded-0' placeholder="Email (Username)" name="email_id" value={userInputs.email_id || ""} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <div className='profile-wrap full-width'>
                          <Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={userInputs.profile || ""} onChange={handleChange} />
                          <datalist id="profile">
                            {profileList.length > 0 &&
                              profileList.map((item) => <option value={item}>{item}</option>)
                            }
                          </datalist>
                          <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={company} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" style={{paddingLeft: '0.75rem'}}>
                        <Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
                        <div>
                          {userSaasModule.length > 0 && userSaasModule.map((item, index) => {
                            return(
                              <Form.Check key={index} inline type="checkbox" name="module" id={"module-"+item} label={item} value={item} onChange={handleChange} defaultChecked={userInputs.module && userInputs.module.includes(item)} />
                            )
                          })}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Select aria-label="Function" className="rounded-0" name="functions" value={userInputs.functions || ""} onChange={handleChange} /* required */>
                          <option value="">Function Name</option>
                          {biModuleList && biModuleList.functions && biModuleList.functions.length > 0 &&
                            biModuleList.functions.map((item, index) => <option value={item} key={index}>{item}</option>)
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Select aria-label="User Roles" className="rounded-0" name="role" value={userInputs.role || ""} onChange={handleChange} required>
                          <option value="">User Role</option>
                          {rolesList.length > 0 &&
                            rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn-transparent" onClick={handleCloseEditUserModal}>Cancel</button>
                  <button type="submit" className='btn-yellow ms-3'>Save</button>
                </Modal.Footer>
              </Form>
            </Modal>

            {/* <Modal show={showMessage} onHide={handleCloseMessage} backdrop="static"keyboard={false} centered className='modal-medium'>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className='text-center'>
                  <p>{messageText}</p>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal> */}
          </Container>
        </main>
      </div>
    </>
	);
}
 
export default ManageUsers;