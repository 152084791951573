import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const CreateUser = () => {
	const navigate = useNavigate();

  const [token, setToken] = useState(null);
	const [userSaasModule, setUserSaasModule] = useState([]);
	const [functionList, setFunctionList] = useState([]);
	const [inputs, setInputs] = useState({});

	const [industry, setIndustry] = useState("");
	const [company, setCompany] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

	const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
		if(token !== null && token !== undefined){
			/* user_role_list_get */
			axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
			.then(response=>{
				console.log('user_role_list_get response', response.data.items);
				if(response.data.status === 200){
					setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

			/* profile_list_get */
			profileListGet();

			axios.post(serverConfig.api_base_url + "modules_list_get", {token:token})
			.then(response=>{
				console.log('modules_list_get response', response);
				if(response.data.status === 200){
					if(response.data.items !== null){
						console.log('modules_list_get response', response.data.items);
						setUserSaasModule(response.data.items);
					} else {
						setUserSaasModule([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

			axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "modules"})
			.then(response=>{
				console.log("modules entity_details_get response:", response);
				if (response.data.status === 200) {
					console.log('success');
					if(response.data.items !== null){
						setFunctionList(response.data.items.biModules.functions);
					} else {
						setFunctionList([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	},[token]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

		if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

		if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			}
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}

    console.log(inputs);
  }

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(inputs);
	}

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} setCompany={setCompany} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Create New User</li>
                </ul>
              </div>
              <h1 className="mb-4">Create User | {industry}</h1>
              <Form id="formCreateManageUser" onSubmit={handleSubmit}>
								<Row>
									<Col>
										<Row className="mt-4">
											<Col lg={5}>
												<Form.Group>
													<Form.Control type="text" placeholder='First Name' className="rounded-0 border-top-0 border-right-0 border-left-0" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
												</Form.Group>
											</Col>
											<Col lg={{ offset: 1, span: 5 }}>
												<Form.Group>
													<Form.Control type="text" placeholder='Last Name' className="rounded-0 border-top-0 border-right-0 border-left-0" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={5}>
												<Form.Group className="mt-4">
													<Form.Control type="email" placeholder='Email (Username)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
												</Form.Group>
											</Col>
											<Col lg={{ offset: 1, span: 5 }}>
												<Form.Group className="mt-4">
													<div className='profile-wrap full-width'>
														<Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={inputs.profile || ""} onChange={handleChange} required />
														<datalist id="profile">
															{profileList.length > 0 &&
																profileList.map((item) => <option value={item}>{item}</option>)
															}
														</datalist>
														<button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={5}>
												<Form.Group className="mt-4">
													<Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={company} readOnly />
												</Form.Group>
											</Col>
											<Col lg={{ offset: 1, span: 5 }}>
												<Form.Group className="mt-4" style={{paddingLeft: '0.75rem'}}>
													<Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
													<div>
														{userSaasModule.length > 0 && userSaasModule.map((item, index) => {
															return(
																<Form.Check key={index} inline type="checkbox" name="saas_module" id={"saas-module-"+item} label={item} value={item} onChange={handleChange} required defaultChecked={inputs.saas_module ? inputs.saas_module.includes(item) : false} />
															)
														})}
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={5}>
												<Form.Group className="mt-4">
													<Form.Select aria-label="Function" className="rounded-0" name="function" value={inputs.function || ""} onChange={handleChange} /* required */>
														<option value="">Function Name</option>
														{functionList.length > 0 &&
															functionList.map((item, index) => <option value={item} key={index}>{item}</option>)
														}
													</Form.Select>
												</Form.Group>
											</Col>
											<Col lg={{ offset: 1, span: 5 }}>
												<Form.Group className="mt-4">
													<Form.Select aria-label="User Role" className="rounded-0" name="role" value={inputs.role || ""} onChange={handleChange} required>
														<option value="">User Roles</option>
														{rolesList.length > 0 &&
															rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
														}
													</Form.Select>
												</Form.Group>
											</Col>
										</Row>
										<Row className="mt-5">
											<Col>
												<button type="button" className="btn-transparent" onClick={() => navigate('/')}>Cancel</button>
												<button type="submit" className="btn-yellow ms-4">Add User</button>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default CreateUser;