import React, {useState} from 'react';
import { NavLink, Link } from 'react-router-dom';
 
const LeftNav = () => {

	const [showMore, setShowMore] = useState(false);

	return (
		<div className="leftnav">
			<div className="leftnav-logo"><Link to="/"><img src={require('./../assets/images/icons/athena-logo.png')} alt="Athena" /></Link></div>
			<ul>
				<li><NavLink to="/dashboard"><img src={require('./../assets/images/icons/icon-saas-demo.png')} alt="Analytics Demo" width="30" /> <span>Analytics Demo</span></NavLink></li>
				<li><NavLink to="/subscription"><img src={require('./../assets/images/icons/icon-enterprises.png')} alt="Subscription" width="30" /> <span>Subscription</span></NavLink></li>
				<li><NavLink to="/products"><img src={require('./../assets/images/icons/icon-products.png')} alt="Products" width="44" /> <span>Products</span></NavLink></li>
				<li><NavLink to="/usage-and-billing"><img src={require('./../assets/images/icons/icon-usage-billing.png')} alt="Usage &amp; Billing" width="30" /> <span>Usage &amp; Billing</span></NavLink></li>
				<li><NavLink to="/service-health"><img src={require('./../assets/images/icons/icon-service-health.png')} alt="Service Health" width="30" /> <span>Service Health</span></NavLink></li>
				<li><NavLink to="/support-health"><img src={require('./../assets/images/icons/icon-support-health.png')} alt="Support Health" width="30" /> <span>Support Health</span></NavLink></li>
				{showMore && <>
					<li><NavLink to="/metaverse"><img src={require('./../assets/images/icons/icon-metaverse.png')} alt="Metaverse" width="30" /> <span>Metaverse</span></NavLink></li>
					<li><NavLink to="/nexverse"><img src={require('./../assets/images/icons/icon-nexverse.png')} alt="Nexverse" width="30" /> <span>Nexverse</span></NavLink></li>
					<li><NavLink to="/intelverse"><img src={require('./../assets/images/icons/icon-intelverse.png')} alt="Intelverse" width="30" /> <span>Intelverse</span></NavLink></li>
					<li><NavLink to="/securverse"><img src={require('./../assets/images/icons/icon-securverse.png')} alt="Securverse" width="30" /> <span>Securverse</span></NavLink></li>
				</>}
			</ul>
			<button title="Show More" className={showMore ? 'show-more show' : 'show-more'} onClick={() => setShowMore(!showMore)}><img src={require('./../assets/images/icons/chevron-right.png')} alt="Show More" width="30" /></button>
		</div>
	);
}
 
export default LeftNav;