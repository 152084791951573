import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';

const SaaSUsersList = ({saasUsers, pendingWorkBasketLookup, handleShowIntegrateSystems}) => {
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Product Type',
        field: 'module',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Access',
        field: 'access',
        sort: 'disabled',
        width: 500
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      }
    ],
    rows: saasUsers.map((item) => {
      return (
        {
          name: item.name,
          status:  item.status === 0 ? 'Inactive' : 'Active',
          module: item.module && item.module.map((itm,idx) => itm !== "" && <>{itm}{idx < item.module.length -1 && ', '}</>),
          access: item.access === 0 ? 'Unlocked' : 'Locked',
          actions: <>{pendingWorkBasketLookup && <Link to={pendingWorkBasketLookup[0].attribute1 ? pendingWorkBasketLookup[0].attribute1 : '#'} onClick={pendingWorkBasketLookup[0].attribute1 ? (e) => e.stopPropagation() : handleShowIntegrateSystems} title={pendingWorkBasketLookup[0].value}>{pendingWorkBasketLookup[0].value}</Link>}</>
        }
      )
    })
    /* [
      {
        name: 'User 1',
        status: 'Active',
        module: 'EDM, BI, DE',
        access: 'Active',
        actions: 'Provision Up Sell Engine',
      },
      {
        name: 'User 2',
        status: 'Inactive',
        module: 'EDM, BI',
        access: 'Active',
        actions: 'None',
      },
      {
        name: 'User 3',
        status: 'In Progress',
        module: 'EDM, BI',
        access: 'Active',
        actions: 'None',
      },
      {
        name: 'User 4',
        status: 'In Progress',
        module: 'EDM, BI',
        access: 'Active',
        actions: 'None',
      },
      {
        name: 'User 5',
        status: 'In Progress',
        module: 'EDM, BI',
        access: 'Active',
        actions: 'None',
      }
    ] */
  };

  return (
    <MDBDataTable striped small borderless data={data} entries={5} entriesOptions={[ 5, 10, 15 ]} searchLabel="Type here" />
  );
}

export default SaaSUsersList;