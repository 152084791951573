import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Table} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const ChooseDataEntities = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [finalInputs, setFinalInputs] = useState({});
  const [dataSource, setDataSource] = useState([]);

  const [industry, setIndustry] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    if (location.state !== null) {
      console.log("location.state", location.state);
      setFinalInputs(location.state);
      
      if(location.state.dataEntities){
        setInputs(location.state.dataEntities);
      }
    }
    console.log(finalInputs);

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null && token !== undefined){
      if (location.state !== null) {
        var arr = [];
        location.state.dataSource.forEach(function (el) {
          let purpose=el.split(' ')[(el.split(' ')).length - 1];
          axios.post(serverConfig.api_base_url + "data_entity_get",{token:token, purpose:purpose})
          .then(response=>{
            console.log("data_entity_get response:", response);
            if (response.data.status === 200) {
              arr.push({
                datasource:el,
                dataentity: response.data.items
              });
              console.log(arr);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
          setTimeout(() => {
            setDataSource(arr);
          }, 1000);
          
        });
      }
    }
  }, [token]);

  const handleMutliSelect = (event) => {
    //console.log(event.currentTarget, event.target.parentNode);
    //console.log($(this));
    var thisEl = event.target;
    if (thisEl.nextSibling.style.display === "none") {
      thisEl.nextSibling.style.display = "block";
    } else {
      thisEl.nextSibling.style.display = "none";
    }
    //$(event.target).next('ul').toggle();
  }

  let arr = [];
  const handleShowDropdown = (event) => {
    /* document.querySelector(this).next('ul').toggle();
    e.stopPropagation();
    e.preventDefault(); */
    console.log(event.target.value);
    if(event.target.value === "Select all"){
      let el = event.target.closest('.dropdown-list').querySelectorAll(' .dropdown-list > li > div > .form-check-input');
      if(event.target.checked){
        event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "";
        for(let i=0; i < el.length; i++){
          console.log(i, el.length);
          el[i].checked = true;
          if(el[i].value !== "Select all"){
            if(i === el.length -1){
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value);
            } else {
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value+', ');
            }
          }
        }
      } else {
        event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "Select from below";
        for(let i=0; i < el.length; i++){
          console.log(el[i].nodeName);
          el[i].checked = false;
        }
      }
    } else {
      if(event.target.checked){
        //event.target.closest('.dropdown-list').previousElementSibling.innerHTML += event.target.value;
        arr.push(event.target.value);
      } else {
        arr = arr.filter(e => e !== event.target.value);
      }
      console.log(arr);
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(name, value);

    if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      
      if(event.target.value === ""){
        let el = event.target.closest('ul').querySelectorAll('.form-check-input');
        if(event.target.checked){
          event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "";
          for(let i=0; i < el.length; i++){
            console.log(el[i].value);
            el[i].checked = true;
            if(el[i].value !== ""){
              if(i === el.length -1){
                event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value);
              } else {
                event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value+', ');
              }
            }
          }
        } else {
          event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "Select from below";
          for(let i=0; i < el.length; i++){
            console.log(el[i].value);
            el[i].checked = false;
          }
        }
      } else {
        event.target.closest('ul').querySelector('.form-check-input').checked = false;
        event.target.closest('.dropdown-list').previousElementSibling.innerHTML = "";
        let el = event.target.closest('ul').querySelectorAll('.form-check-input');
        for(let i=0; i < el.length; i++){
          if(el[i].checked){
            if(i === el.length -1){
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value);
            } else {
              event.target.closest('.dropdown-list').previousElementSibling.innerHTML += (el[i].value+', ');
            }
          }
        }
      }

      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        console.log(checkboxes[i].value, checkboxes[i].checked);
        if (checkboxes[i].checked && checkboxes[i].value !== "") {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
      setInputs(values => ({...values, [name]: checkboxesChecked}));
		}

    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    navigate('/data-sources-entities',{state:{...finalInputs, dataEntities:inputs}});
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Setup New Client</li>
                  <li>|</li>
                  <li>Analytics Admin Screen</li>
                </ul>
              </div>
              <h1 className="mb-4">Data Sources &amp; Data Entities | {industry}</h1>
              <Form onSubmit={handleSubmit} className='mt-5'>
                <h6 className='mb-4'>Choose the desired Data Entities against the Data sources chosen from below:</h6>
                <Table bordered>
                  <thead>
                    <tr>
                      <th width="200">Data Sources</th>
                      <th>Data Entities</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataSource.length > 0 &&
                      dataSource.map((item, index) => <tr key={index}>
                        <td>{item.datasource}</td>
                        <td className='position-relative'>
                          <button className="btn btn-transparent-gray dropdown" type="button" onClick={handleMutliSelect}>
                            {finalInputs.dataEntities && finalInputs.dataEntities[item.datasource] ? finalInputs.dataEntities[item.datasource].map((el, i) => {

                              return(<>{el}{i < finalInputs.dataEntities[item.datasource].length -1 && <>, </>}</>)
                            }) : <>Select from below</>}
                          </button>
                          <ul className="dropdown-list" style={{display: 'none'}}>
                            <li><Form.Check name={item.datasource} value="" type="checkbox" id={"select-all-"+item.datasource} label="Select all" onChange={handleChange} /></li>
                            {item.dataentity && item.dataentity.length > 0 && item.dataentity.map((el) => <li><Form.Check name={item.datasource} value={el} type="checkbox" id={item.datasource+"-"+el} label={el} onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item.datasource] && finalInputs.dataEntities[item.datasource].includes(el)} /></li>)}
                          </ul>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {/* <tbody>
                    {finalInputs.dataSource && finalInputs.dataSource.length > 0 &&
                      finalInputs.dataSource.map((item, index) => <tr key={index}>
                        <td>{item}</td>
                        <td className='position-relative'>
                          <button className="btn btn-transparent-gray dropdown" type="button" onClick={handleMutliSelect}>
                            {finalInputs.dataEntities && finalInputs.dataEntities[item] ? finalInputs.dataEntities[item].map((el, i) => {

                              return(<>{el}{i < finalInputs.dataEntities[item].length -1 && <>, </>}</>)
                            }) : <>Select from below</>}
                          </button>
                          {item === "Salesforce (CRM)" ? <ul className="dropdown-list" style={{display: 'none'}}>
                            <li><Form.Check name={item} value="" type="checkbox" id={"select-all-"+item} label="Select all" onChange={handleChange} /></li>
                            <li><Form.Check name={item} value="Account" type="checkbox" id="Account" label="Account" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Account")} /></li>
                            <li><Form.Check name={item} value="Lead" type="checkbox" id="Lead" label="Lead" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Lead")} /></li>
                            <li><Form.Check name={item} value="Opportunity" type="checkbox" id="Opportunity" label="Opportunity" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Opportunity")} /></li>
                            <li><Form.Check name={item} value="Contact" type="checkbox" id="Contact" label="Contact" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Contact")} /></li>
                            <li><Form.Check name={item} value="Region" type="checkbox" id="Region" label="Region" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Region")} /></li>
                          </ul> : <ul className="dropdown-list" style={{display: 'none'}}>
                            <li><Form.Check name={item} value="" type="checkbox" id={"select-all-"+item} label="Select all" onChange={handleChange} /></li>
                            <li><Form.Check name={item} value="Option 1" type="checkbox" id={item+"-Option 1"} label="Option 1" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Option 1")} /></li>
                            <li><Form.Check name={item} value="Option 2" type="checkbox" id={item+"-Option 2"} label="Option 2" onChange={handleChange} checked={finalInputs.dataEntities && finalInputs.dataEntities[item] && finalInputs.dataEntities[item].includes("Option 2")} /></li>
                          </ul>}
                        </td>
                      </tr>
                    )}
                  </tbody> */}
                </Table>
                <Row className="mt-5">
                    <Col>
                        <button type="button" className="btn-transparent" onClick={() => navigate('/add-data-sources',{state:finalInputs})}>Previous</button>
                        <button type="submit" className="btn-yellow ms-4">Next</button>
                    </Col>
                </Row>
              </Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default ChooseDataEntities;