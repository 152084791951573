import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';

const SystemsList = ({dataSources, pendingWorkBasketLookup, handleShowIntegrateSystems}) => {

  var dataSourceArr = [];
  if(dataSources){
    for (const key in dataSources) {
      if(!`${key}`.includes("email") && !`${key}`.includes("name") && !`${key}`.includes("role") && !`${key}`.includes("profile") && !`${key}`.includes("password")){
        console.log(`${key}: ${dataSources[key]}`);
        //dataSourceArr.push({[key]: `${dataSources[key]}`});
        dataSourceArr.push({
          systems: `${key}`,
          dataEntities:[`${dataSources[key]}`],
          modules: "DE",
          status: "Active",
          actions: <>{pendingWorkBasketLookup && <Link to={pendingWorkBasketLookup[0].attribute1 ? pendingWorkBasketLookup[0].attribute1 : '#'} onClick={pendingWorkBasketLookup[0].attribute1 ? (e) => e.stopPropagation() : handleShowIntegrateSystems} title={pendingWorkBasketLookup[0].value}>{pendingWorkBasketLookup[0].value}</Link>}</>
        });
      }
    }
  }
  console.log(dataSourceArr);

  const data = {
    columns: [
      {
        label: 'Systems',
        field: 'systems',
        sort: 'disabled',
        width: 150
      },
      {
        label: 'Data Entities',
        field: 'dataEntities',
        sort: 'disabled',
        width: 270
      },
      /* {
        label: 'Modules',
        field: 'modules',
        sort: 'disabled',
        width: 200
      }, */
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 150
      }
    ],
    rows: dataSourceArr
    /* rows: [
      {
        systems: 'Salesforce',
        dataEntities: 'XX',
        modules: 'EDM, BI, DE',
        status: 'Active',
        actions: 'Provision Up Sell Engine',
      },
      {
        systems: 'Finserve',
        dataEntities: 'XX',
        modules: 'EDM, BI',
        status: 'Active',
        actions: 'None',
      },
      {
        systems: 'Oracle',
        dataEntities: '',
        modules: '',
        status: '',
        actions: '',
      },
      {
        systems: 'Peoplesoft',
        dataEntities: '',
        modules: '',
        status: '',
        actions: '',
      },
      {
        systems: 'MS Dynamics',
        dataEntities: '',
        modules: '',
        status: '',
        actions: '',
      }
    ] */
  };

  return (
    <MDBDataTable striped small borderless data={data} entries={5} entriesOptions={[ 5, 10, 15 ]} searchLabel="Type here" />
  );
}

export default SystemsList;