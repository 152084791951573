import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/Header';
import LeftNav from '../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const DataSourcesEntities = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [inputs, setInputs] = useState({});
  const [finalInputs, setFinalInputs] = useState({});

  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);

  const [industry, setIndustry] = useState("");

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    if (location.state !== null) {
      console.log("location.state", location.state);
      setFinalInputs(location.state);
      if(location.state.dataEntities){
        setInputs(location.state.dataEntities);
      }
    }
    console.log(finalInputs);

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
		if(token !== null && token !== undefined){
      profileListGet();

      /* user_role_list_get */
      axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
      .then(response=>{
        console.log('user_role_list_get response', response.data.items);
        if(response.data.status === 200){
          setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
		}
	},[token]);

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handlePasswordGenerate = (functionName, event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            [functionName+"_password"]:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

    setInputs(values => ({...values, [name]: value}));

    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);

    var arr = [];
    let ds = location.state.dataSource;
    ds.forEach(function (el) {
      console.log(el);
      //console.log(inputs[`${el+'-name'}`]);
      //console.log(inputs['Salesforce (CRM)-name']);
      arr.push({
        first_name:inputs[`${el+'_first_name'}`],
        last_name:inputs[`${el+'_last_name'}`],
        email_id:inputs[`${el+'_email_id'}`],
        password:inputs[`${el+'_password'}`],
        profile:inputs[`${el+'_profile'}`],
        role:[inputs[`${el+'_role'}`]],
        //module:"BI"
      });
    });
    
    console.log(arr);
    var uniqueArr = arr.reduce((acc, obj)=>{
      var exist = acc.find(({email_id, module}) => obj.email_id === email_id && obj.module === module);
      if(!exist){
        acc.push(obj);
      }
      return acc;
    },[]);
    console.log(uniqueArr);
    const modulesSubscribed = uniqueArr;

    axios.post(serverConfig.api_base_url + "data_sources_add",{token:token, items:inputs})
    .then(response=>{
      console.log("data_sources_add response:", response);
      if (response.data.status === 200) {
        console.log('success');
        modulesSubscribed.forEach(function(el){
          console.log(el);
          /* user_exists_check - start */
          if(el.email_id){
            axios.post(serverConfig.api_base_url + "user_exists_check",{token:token, email_id:el.email_id})
            .then(response=>{
              console.log("user_exists_check response:", response);
              if (response.data.status === 200) {
                if(response.data.user_exists === 0){
                  /* user_create - start */
                  axios.post(serverConfig.api_base_url + "user_create",{token:token, first_name:el.first_name, last_name:el.last_name, email_id:el.email_id, password:el.password})
                  .then(response=>{
                    console.log("User create response:", response);
                    if(response.data.status === 200){//on success then grant role
                      console.log('success');
                      /* user_grant_role - start */
                      axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: el.email_id, roles: el.role})
                      .then(response=>{
                        console.log('user_grant_role response', response);
                        if(response.data.status === 200){//on success set athena modules subscribed
                          console.log('success');
                          /* modules_subscribed_set - start */
                          axios.post(serverConfig.api_base_url + "modules_subscribed_set",{token:token, email_id:el.email_id, module:el.module, profile:el.profile, function: null})
                          .then(response=>{
                            console.log("modules_subscribed_set response:", response);
                            if (response.data.status === 200) {
                              console.log('success');
                              navigate('/');
                            } else if(response.data.status === 301){
                              handleLogout();
                            } else {
                              alert(response.data.message);
                            }
                          })
                          .catch(err=>console.log("error is",err));
                          /* modules_subscribed_set - end */
                        } else if(response.data.status === 301){
                          handleLogout();
                        } else {
                          alert(response.data.message);
                        }
                      })
                      .catch(err=>console.log("error is",err));
                      /* user_grant_role - end */
                    } else if(response.data.status === 301){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                    }
                  })
                  .catch(err=>console.log("error is",err));
                  /* user_create - end */
                } else if(response.data.user_exists === 1){
                  console.log('Ignored as '+ el.email_id + ' already exists.')
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          } else {
            navigate('/');
          }
          /* user_exists_check - end */
        });
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li><Link to="/create-client">Setup New Client</Link></li>
                  <li>|</li>
                  <li>Analytics Admin Screen</li>
                </ul>
              </div>
              <h1 className="mb-4">Data Sources &amp; Data Entities | {industry}</h1>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    {finalInputs.dataEntities &&
                      Object.keys(finalInputs.dataEntities).map((item, index) => <fieldset key={index} className="mt-3">
                      <Row>
                        <Col lg={3} className="fieldset-heading">
                          <h5>{item}</h5>
                        </Col>
                        <Col>
                          <h6 className="txt-white">Data Entity Names:</h6>
                          <Form.Group>
                          {finalInputs.dataEntities[item].length > 0 &&
                            finalInputs.dataEntities[item].map((subitem) => <Form.Check type="checkbox" id={subitem} label={subitem} checked readOnly />)
                          }
                          </Form.Group>
                        </Col>
                        <Col className='function-head'>
                          <h6 className="txt-white">System Admin:</h6>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>First Name: </Form.Label>
                                <Form.Control type="text" name={item+"_first_name"} defaultValue={inputs[`${item+"_first_name"}`] || ""} onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Last Name: </Form.Label>
                                <Form.Control type="text" name={item+"_last_name"} defaultValue={inputs[`${item+"_last_name"}`] || ""} onChange={handleChange} />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mt-1">
                                <Form.Label>Email: </Form.Label>
                                <Form.Control type="email" name={item+"_email_id"} defaultValue={inputs[`${item+"_email_id"}`] || ""} onChange={handleChange} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Profile: </Form.Label>
                                <div className='profile-wrap'>
                                  <Form.Control type="text" list={item+"_profile"} name={item+"_profile"} defaultValue={inputs[`${item+"_profile"}`] || ""} className='profile' onChange={handleChange} />
                                  <datalist id={item+"_profile"}>
                                    {profileList.length > 0 &&
                                      profileList.map((itm, idx) => <option key={itm+"-"+idx} value={itm}>{itm}</option>)
                                    }
                                  </datalist>
                                  <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mt-1">
                                <Form.Label>Role: </Form.Label>
                                <div className='roles-wrap'>
                                  <Form.Select name={item+"_role"} defaultValue={inputs[`${item+"_role"}`] || ""} onChange={handleChange}>
                                    <option value="">Select Role</option>
                                    {rolesList.length > 0 &&
                                      rolesList.map((itm, idx) => <option key={itm+"-"+idx} value={itm}>{itm}</option>)
                                    }
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col>
                              <div className='position-relative mt-1'>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control type="password" name={item+"_password"} className='pe-4' defaultValue={finalInputs.deModules && finalInputs.deModules[`${item+"_password"}`] || inputs[`${item+"_password"}`]} onChange={handleChange} />
                                <span className='toggle-password me-2' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                              </div>
                              <button type="button" id="createUserPwdGenerate" onClick={(e) => handlePasswordGenerate(item, e)} className='btn-transparent p-2 mt-1 float-end'>Generate Password</button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </fieldset>)}

                    <Row className="mt-5">
                      <Col>
                        <button type="button" className="btn-transparent" onClick={() => navigate('/choose-data-entities',{state:finalInputs})}>Previous</button>
                        <button type="submit" className="btn-yellow ms-4">Submit</button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default DataSourcesEntities;