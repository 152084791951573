import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Modal} from 'react-bootstrap';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const ModulesPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [industry, setIndustry] = useState("");
  const [finalInputs, setFinalInputs] = useState({});
  const [productsSubscribed, setProductsSubscribed] = useState([]);
  const [modulesSubscribed, setModulesSubscribed] = useState([]);
  const [functionsLookup, setFunctionsLookup] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    if (location.state !== null) {
      console.log("location.state", location.state);
      setFinalInputs(location.state);
      console.log(finalInputs);

      var arr = [];
      var productsArray = [];
      //EDM
      arr.push({
        first_name:location.state.edmModules.first_name,
        last_name:location.state.edmModules.last_name,
        email_id:location.state.edmModules.email_id,
        password:location.state.edmModules.password,
        profile:location.state.edmModules.profile,
        role:[location.state.edmModules.roles],
        module:"EDM"
      });
      location.state.edmModules.edm_modules.forEach((item) => productsArray.push(item));
      //BI
      let bi = location.state.biModules;
      bi.functions.forEach(function (el) {
        //console.log(el);
        arr.push({
          first_name:bi[`${el+'_first_name'}`],
          last_name:bi[`${el+'_last_name'}`],
          email_id:bi[`${el+'_email_id'}`],
          password:bi[`${el+'_password'}`],
          profile:bi[`${el+'_profile'}`],
          role:[bi[`${el+'_role'}`]],
          module:"BI"
        });
        bi[`${el+'-modules'}`].forEach((item) => productsArray.push(item));
      });
      //DE
      let de = location.state.deModules;
      de.functions.forEach(function (el) {
        //console.log(el);
        arr.push({
          first_name:de[`${el+'_first_name'}`],
          last_name:de[`${el+'_last_name'}`],
          email_id:de[`${el+'_email_id'}`],
          password:de[`${el+'_password'}`],
          profile:de[`${el+'_profile'}`],
          role:[de[`${el+'_role'}`]],
          module:"AI"
        });
        de[`${el+'-modules'}`].forEach((item) => productsArray.push(item));
      });

      console.log(arr);
      console.log(productsArray);
      setProductsSubscribed(productsArray);
      var uniqueArr = arr.reduce((acc, obj)=>{
        var exist = acc.find(({email_id, module}) => obj.email_id === email_id && obj.module === module);
        if(!exist){
          acc.push(obj);
        }
        return acc;
      },[]);
      console.log(uniqueArr);
      setModulesSubscribed(uniqueArr);
    }

    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
		if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"select_functions"})
      .then(response=>{
        console.log("select_functions lookup:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("select_functions lookup data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            setFunctionsLookup(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
	},[token]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(finalInputs);
    console.log(productsSubscribed);
    console.log(modulesSubscribed);
    axios.post(serverConfig.api_base_url + "modules_add",{token:token, items:finalInputs})
    .then(response=>{
      console.log("modules_add response:", response);
      if (response.data.status === 200) {
        console.log('success');
        //productsSubscribed
        productsSubscribed.forEach(function(el){
          axios.post(serverConfig.api_base_url + "product_subscribed_set",{token:token, productName:el})
          .then(response=>{
            console.log("product_subscribed_set response:", response);
            if (response.data.status === 200) {
              console.log('success');
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        });
        //modulesSubscribed
        modulesSubscribed.forEach(function(el,index){
          console.log(el, modulesSubscribed.length, index);
          /* user_exists_check - start */
          if(el.email_id){
            axios.post(serverConfig.api_base_url + "user_exists_check",{token:token, email_id:el.email_id})
            .then(response=>{
              console.log("user_exists_check response:", response);
              if (response.data.status === 200) {
                if(response.data.user_exists === 0){
                  /* user_create - start */
                  axios.post(serverConfig.api_base_url + "user_create",{token:token, first_name:el.first_name, last_name:el.last_name, email_id:el.email_id, password:el.password})
                  .then(response=>{
                    console.log("User create response:", response);
                    if(response.data.status === 200){//on success then grant role
                      console.log('success');
                      /* user_grant_role - start */
                      axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: el.email_id, roles: el.role})
                      .then(response=>{
                        console.log('user_grant_role response', response);
                        if(response.data.status === 200){//on success set athena modules subscribed
                          console.log('success');
                          /* modules_subscribed_set - start */
                          location.state.biModules.functions.forEach(function(func){
                            console.log(func);
                            axios.post(serverConfig.api_base_url + "modules_subscribed_set",{token:token, email_id:el.email_id, module:el.module, profile:el.profile, function: func})
                            .then(response=>{
                              console.log("modules_subscribed_set response:", response);
                              if (response.data.status === 200) {
                                console.log('success');
                                navigate('/');
                              } else if(response.data.status === 301){
                                handleLogout();
                              } else {
                                alert(response.data.message);
                                setIsLoading(false);
                              }
                            })
                            .catch(err=>console.log("error is",err));
                          });
                          /* modules_subscribed_set - end */
                        } else if(response.data.status === 301){
                          handleLogout();
                        } else {
                          alert(response.data.message);
                          setIsLoading(false);
                        }
                      })
                      .catch(err=>console.log("error is",err));
                      /* user_grant_role - end */
                    } else if(response.data.status === 301){
                      handleLogout();
                    } else {
                      alert(response.data.message);
                      setIsLoading(false);
                    }
                  })
                  .catch(err=>console.log("error is",err));
                  /* user_create - end */
                } else if(response.data.user_exists === 1){
                  setMessageText('Ignored as '+ el.email_id + ' already exists.');
                  //handleShowMessage();
                  setShowAlert(true);
                  setTimeout(() => {
                    setShowAlert(false);
                    setMessageText("");
                  }, 3000);
                  setIsLoading(false);
                  if(index === modulesSubscribed.length-1){
                    navigate('/');
                  }
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
                setIsLoading(false);
              }
            })
            .catch(err=>console.log("error is",err));
          } else {
            navigate('/');
          }
          /* user_exists_check - end */
        });
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  /* const [showMessage, setShowMessage] = useState(false);
  const handleCloseMessage = () => {
    setMessageText("");
    setShowMessage(false);
  }
  const handleShowMessage = () => setShowMessage(true); */

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          {isLoading && <div id="loading"><img src={require('./../assets/images/icons/spinner.gif')} alt="Loader" width="70" /></div>}
          {showAlert && <div className='alert-msg'><Container fluid>{messageText}</Container></div>}
          <Container fluid>
            <section className="form no-bg-img full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li>Add Products</li>
                </ul>
              </div>
              <h1 className="mb-4">Modules Preview | {industry}</h1>
              <Row>
                <Col>
                  <Form className="modules-preview">
                    <div>
                      <h2 className="mb-3">EDM Modules | {industry}</h2>
                      {finalInputs.edmModules && <Row>
                        <Col lg={4} className='border-end border-dgray'>
                          <ul className="tick">
                            {finalInputs.edmModules.edm_modules.map((item, index) => <li key={index}><div className='tick-icon'>{item}</div></li>)}
                          </ul>
                        </Col>
                        <Col lg={4}>
                          <h6 className="txt-white">Function Head:</h6>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>First Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.edmModules.first_name} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Last Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.edmModules.last_name} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" readOnly value={finalInputs.edmModules.email_id} />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Profile:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.edmModules.profile} />
                              </Form.Group>
                              <Form.Group className="mt-1 d-flex">
                                <Form.Label>Role:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.edmModules.roles} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>}
                    </div>
                    <div className="mt-3 pt-3 border-top border-dgray">
                      <h2 className="mb-3">BI Modules | {industry}</h2>
                      {finalInputs.biModules && <ul className="tick row">
                        {functionsLookup.length > 0 &&
                          functionsLookup.map((item, index) => finalInputs.biModules.functions.includes(item.value) && <li key={index} className="col-lg-2 border-end border-dgray mb-4">
                            <div className='tick-icon'>
                              <span>{item.value}</span>
                              <Form.Check type="checkbox" label="Pe" className='chkbox-round ms-4' readOnly checked={finalInputs.biModules && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`] && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`].includes("Pe")} />
                              <Form.Check type="checkbox" label="Pr" className='chkbox-round chkbox-orange ms-2 me-2' readOnly checked={finalInputs.biModules && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`] && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`].includes("Pr")} />
                              <Form.Check type="checkbox" label="GI" className='chkbox-round chkbox-dorange' readOnly checked={finalInputs.biModules && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`] && finalInputs.biModules[`${item.value+"-pe-pr-gi"}`].includes("GI")} />
                            </div>
                            <ul>
                              {finalInputs.biModules[`${item.value+"-modules"}`].length > 0 &&
                                finalInputs.biModules[`${item.value+"-modules"}`].map((subitem, subindex) => 
                                  <li key={subindex} className='d-flex justify-content-between'>
                                    <div>{subitem}</div>
                                    <div>{finalInputs.biModules[`${item.value+"_"+subitem+"_billing_cycle"}`]}</div>
                                  </li>
                                )
                              }
                            </ul>
                            <h6 className="txt-gray mt-3">Function Head:</h6>
                            <div className="txt-gray">
                              <Form.Group>
                                <Form.Label>First Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.biModules[`${item.value+"_first_name"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Last Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.biModules[`${item.value+"_last_name"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" readOnly value={finalInputs.biModules[`${item.value+"_email_id"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Profile:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.biModules[`${item.value+"_profile"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1 d-flex">
                                <Form.Label>Role:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.biModules[`${item.value+"_role"}`]} />
                              </Form.Group>
                            </div>
                          </li>)
                        }
                      </ul>}
                    </div>
                    <div className="mt-3 pt-3 border-top border-dgray">
                      <h2 className="mb-3">Decision Engines | {industry}</h2>
                      {finalInputs.deModules && <ul className="tick row">
                        {functionsLookup.length > 0 &&
                          functionsLookup.map((item, index) => finalInputs.deModules.functions.includes(item.value) && <li key={index} className="col-lg-2 border-end border-dgray mb-4">
                            <div className='tick-icon'>
                              <span>{item.value}</span>
                            </div>
                            <ul>
                              {finalInputs.deModules[`${item.value+"-modules"}`].length > 0 &&
                                finalInputs.deModules[`${item.value+"-modules"}`].map((subitem, subindex) => 
                                <li key={subindex} className='d-flex justify-content-between'>
                                  <div>{subitem}</div>
                                  <div>{finalInputs.deModules[`${item.value+"_"+subitem+"_billing_cycle"}`]}</div>
                                </li>
                                )
                              }
                            </ul>
                            <h6 className="txt-gray mt-3">Function Head:</h6>
                            <div className="txt-gray">
                              <Form.Group>
                                <Form.Label>First Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.deModules[`${item.value+"_first_name"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Last Name:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.deModules[`${item.value+"_last_name"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control type="email" readOnly value={finalInputs.deModules[`${item.value+"_email_id"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1">
                                <Form.Label>Profile:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.deModules[`${item.value+"_profile"}`]} />
                              </Form.Group>
                              <Form.Group className="mt-1 d-flex">
                                <Form.Label>Role:</Form.Label>
                                <Form.Control type="text" readOnly value={finalInputs.deModules[`${item.value+"_role"}`]} />
                              </Form.Group>
                            </div>
                          </li>)
                        }
                      </ul>}
                    </div>
                    
                    <Row className="mt-5">
                      <Col>
                        <button type="button" className="btn-transparent" onClick={() => navigate('/add-data',{state:finalInputs})}>Previous</button>
                        <button type="submit" className="btn-yellow ms-4" onClick={handleSubmit}>Submit</button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </section>

            {/* <Modal show={showMessage} onHide={handleCloseMessage} backdrop="static"keyboard={false} centered className='modal-medium'>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className='text-center'>
                  <p>{messageText}</p>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal> */}
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default ModulesPreview;