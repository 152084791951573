import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Modal, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import BIList from './../components/BIList';
import DEList from './../components/DEList';
import SaaSUsersList from './../components/SaaSUsersList';
import SaaSInfraList from './../components/SaaSInfraList';
import SystemsList from './../components/SystemsList';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import UserService from '../services/UserService';
import IntroAnimation from '../components/IntroAnimation';
import logo from "./../assets/images/icons/logo-large.png";
import splashlogo from "./../assets/images/icons/splashlogo.png";

const serverConfig = require("../config/server.js");
 
const SaaSCI = () => {

	const navigate = useNavigate();

	const [token, setToken] = useState(null);

	const [viewReportsPrivileged, setViewReportsPrivileged] = useState(null);

	const [clientCompletedActions, setClientCompletedActions] = useState([]);
	const [pendingWorkBasketLookup, setPendingWorkBasketLookup] = useState([]);

	const [selectFunctions, setSelectFunctions] = useState([]);
	const [clientFunction, setClientFunction] = useState([]);
	const [functions, setFunctions] = useState([]);
	const [saasInfrastructure, setSaaSInfrastructure] = useState({});
	const [biDetails, setBIDetails] = useState([]);
	const [deDetails, setDEDetails] = useState([]);
	const [saasUsers, setSaaSUsers] = useState([]);
	const [dataSources, setDataSources] = useState({});
	const [systemInputs, setSystemInputs] = useState({});
	const [refreshNotifications, setRefreshNotifications] = useState(false);

	const [showCreateList, setShowCreateList] = useState(false);
  const handleToggleCreateList = () => { setShowCreateList(!showCreateList); };

	const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	const workbasketCompletedActionsGet = () => {
		axios.post(serverConfig.api_base_url + "workbasket_completed_actions_get",{token:token})
		.then(response=>{
			console.log("workbasket_completed_actions_get response:", response);
			if (response.data.status === 200) {
				if(response.data.items !== null){
					console.log("workbasket_actions data:", response.data.items);
					setClientCompletedActions(response.data.items);
				}
			} else if(response.data.status === 301){
				handleLogout();
			} else {
				alert(response.data.message);
			}
		})
		.catch(err=>console.log("error is",err));
	}

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

	useEffect(() => {
		if(token !== null && token !== undefined){
			axios.post(serverConfig.api_base_url + "user_check_privilege", {token:token, privilege:"view_reports"})
			.then(response=>{
				console.log('user_check_privilege response', response.data);
				if(response.data.status === 200){
					if(response.data.is_privileged === 1) {
						setViewReportsPrivileged(true);
						window.location.href = "/dashboard";
					} else if(response.data.is_privileged === 0) {
						setViewReportsPrivileged(false);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	},[token]);

	useEffect(() => {
		if(token !== null && token !== undefined){
			if(!viewReportsPrivileged){
				workbasketCompletedActionsGet();

				axios.post(serverConfig.api_base_url + "lookup_list", {name: "select_functions", token:token})
				.then(response=>{
					console.log('lookup_list_response response', response.data.items);
					if(response.data.status === 200){
						if(response.data.items !== null) {
							response.data.items.sort(function(a,b){
								return a.seq_no - b.seq_no;
							});
							setSelectFunctions(response.data.items);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "client_function_get", {token:token})
				.then(response=>{
					console.log('client_function_get response', response.data.items);
					if(response.data.status === 200){
						if(response.data.items !== null) {
							setClientFunction(response.data.items);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "modules"})
				.then(response=>{
					console.log("modules entity_details_get response:", response);
					if (response.data.status === 200) {
						console.log('success');
						if(response.data.items !== null){
							setFunctions(response.data.items.biModules.functions);
						} else {
							setFunctions([]);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "deploy_bi"})
				.then(response=>{
					console.log("deploy_bi entity_details_get response:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("deploy_bi entity_details_get data:", response.data.items);
							setBIDetails(response.data.items);
						} else {
							setBIDetails([]);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "deploy_de"})
				.then(response=>{
					console.log("deploy_de entity_details_get response:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("deploy_de entity_details_get data:", response.data.items);
							setDEDetails(response.data.items);
						} else {
							setDEDetails([]);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "client_details_get",{token:token})
				.then(response=>{
					console.log("client_details_get response:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("client_details_get data:", response.data.items);
							setSaaSInfrastructure(JSON.parse(response.data.items.deploy_enterprise_saas));
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "user_module_list_get",{token:token})
				.then(response=>{
					console.log("user_module_list_get response:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("user_module_list_get data:", response.data.items);
							setSaaSUsers(response.data.items);
						} else {
							setSaaSUsers([]);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));

				axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "data_sources"})
				.then(response=>{
					console.log("data_sources entity_details_get response:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("data_sources entity_details_get data:", response.data.items);
							setDataSources(response.data.items);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));
			}
		}
	},[viewReportsPrivileged]);

	useEffect(() => {
		if(token !== null && token !== undefined){
			if(!viewReportsPrivileged){
				axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"workbasket_actions"})
				.then(response=>{
					console.log("workbasket_actions lookup:", response);
					if (response.data.status === 200) {
						if(response.data.items !== null){
							console.log("workbasket_actions data:", response.data.items);
							response.data.items.sort(function(a,b){
								return a.seq_no - b.seq_no;
							});

							let pendingWorkBasketActions = response.data.items.filter(object => {
								return !clientCompletedActions.includes(object.value);
							});
							console.log(pendingWorkBasketActions);
							setPendingWorkBasketLookup(pendingWorkBasketActions);
						}
					} else if(response.data.status === 301){
						handleLogout();
					} else {
						alert(response.data.message);
					}
				})
				.catch(err=>console.log("error is",err));
			}
		}
	},[clientCompletedActions, viewReportsPrivileged]);
	
	const [showIntegrateSystems, setShowIntegrateSystems] = useState(false);
  const handleCloseIntegrateSystems = () => {
		setSystemInputs({});
		setShowIntegrateSystems(false);
	};
  const handleShowIntegrateSystems = () => setShowIntegrateSystems(true);

	const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
		setSystemInputs(values => ({...values, [name]: value}));
		console.log(systemInputs);
	}

	const handleSubmit = (event) => {
    event.preventDefault();
    console.log(systemInputs);
    axios.post(serverConfig.api_base_url + "integrate_systems",{token:token, items:systemInputs})
    .then(response=>{
      console.log("integrate_systems response:", response);
      if (response.data.status === 200) {
        console.log('success');
				workbasketCompletedActionsGet();
				setRefreshNotifications(!refreshNotifications);
        handleCloseIntegrateSystems();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

	return (
		<>
			<IntroAnimation splashlogo={splashlogo} imgsrc={logo}/>
			<LeftNav />
			<div className="main-wrapper">
				<Header token={token} refreshNotifications={refreshNotifications} />
				<main>
					<Container fluid>
						<section className="home-saas">
							<Row xs={1} md={3}>
								<Col>
									<div className="inner">
										<h4>Data Quality</h4>
										<span className="image"><img src={require('./../assets/images/saas-performance.jpg')} alt="Data Quality" /></span>
									</div>
								</Col>
								<Col>
									<div className="inner">
										<h4>Business Growth</h4>
										<span className="image"><img src={require('./../assets/images/saas-subscriptions.jpg')} alt="Business Growth" /></span>
									</div>
								</Col>
								<Col>
									<div className="inner">
										<h4>Operations Performance</h4>
										<span className="image"><img src={require('./../assets/images/saas-billing.jpg')} alt="Operations Performance" /></span>
									</div>
								</Col>
							</Row>
						</section>
						<section className="workbasket">
							<h2>My workbasket ({pendingWorkBasketLookup.length})</h2>
							{pendingWorkBasketLookup.length > 0 ?
							<ul>
								{pendingWorkBasketLookup.map((item, index) => {
										return(
											<li key={index}><Link to={item.attribute1 ? item.attribute1 : '#'} onClick={item.attribute1 ? (e) => e.stopPropagation() : handleShowIntegrateSystems} className={index !== 0 ? 'disabled-link' : undefined}>{item.value}</Link></li>
										)
									})
								}
							</ul> : <ul><li>&nbsp;</li></ul>}
							{/* <ul>
								<li><Link to="/add-modules">Add Module</Link></li>
								<li><Link to="/data-sources-entities">Add Data</Link></li>
								<li><Link to="/add-data-sources">Add Data Sources</Link></li>
								<li><Link to="#" onClick={handleShowIntegrateSystems}>Integrate Systems</Link></li>
								<li><Link to="/data-source-metaverse-mapping">Map Meta Data</Link></li>
								<li><Link to="/data-ingestion-schedule">Setup Data Ingestion Schedule</Link></li>
								<li><Link to="/deploy-bi">Deploy BI</Link></li>
							</ul> */}
							<Modal show={showIntegrateSystems} onHide={handleCloseIntegrateSystems} backdrop="static" keyboard={false} centered>
								<Form onSubmit={handleSubmit}>
									<Modal.Header closeButton>
										<Modal.Title>Integrate Systems</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form.Group className="mb-3 mt-4 formitem">
                      <Form.Control type="text" placeholder='IP Address *' name="ipAddress" required className="rounded-0 border-top-0 border-right-0 border-left-0" onChange={handleChange} />
                    </Form.Group>
										<Form.Group className="mb-3 mt-4 formitem">
                      <Form.Control type="text" placeholder='Port *' name="port" required className="rounded-0 border-top-0 border-right-0 border-left-0" onChange={handleChange} />
                    </Form.Group>
										<Form.Group className="mb-3 mt-4 formitem">
                      <Form.Control type="text" placeholder='User Name *' name="username" required className="rounded-0 border-top-0 border-right-0 border-left-0" onChange={handleChange} />
                    </Form.Group>
										<Form.Group className="mb-3 mt-4 formitem">
                      <Form.Control type="password" placeholder='Password *' name="password" required className="rounded-0 border-top-0 border-right-0 border-left-0" onChange={handleChange} />
                    </Form.Group>
									</Modal.Body>
									<Modal.Footer>
                    <Button variant="primary" className="rounded-0" type='cancel' onClick={handleCloseIntegrateSystems}>Cancel</Button>
										<Button variant="primary" className="rounded-0 border-0" type='submit'>Submit</Button>									  
                  </Modal.Footer>
								</Form>
							</Modal>
						</section>
						<section className="saas-manage">
							<Row>
								<Col>
									<Link to="/subscription">
										<span className="image"><img src={require('./../assets/images/icons/icon-2.png')} alt="Manage Products" width="50" /></span>
										<span className="text">Manage Products</span>
									</Link>
								</Col>
								<Col>
									<Link to="/manage-users">
										<span className="image"><img src={require('./../assets/images/icons/icon-2.png')} alt="Manage Users" width="50" /></span>
										<span className="text">Manage Users</span>
									</Link>
								</Col>
								<Col>
									<Link to="/add-data-sources">
										<span className="image"><img src={require('./../assets/images/icons/icon-2.png')} alt="Manage Data" width="50" /></span>
										<span className="text">Manage Data</span>
									</Link>
								</Col>
								<Col>
									<button>
										<span className="image"><img src={require('./../assets/images/icons/icon-2.png')} alt="Monetize Data" width="50" /></span>
										<span className="text">Monetize Data</span>
									</button>
								</Col>
								<Col>
									<button>
										<span className="image"><img src={require('./../assets/images/icons/icon-2.png')} alt="Manage Billing" width="50" /></span>
										<span className="text">Manage Billing</span>
									</button>
								</Col>
								<Col>
								<div className="create">
										<button onClick={handleToggleCreateList}><img src={require('./../assets/images/icons/plus-icon.png')} alt="Create" /></button>
										<div className={"popup-list " + (showCreateList ? "show" : "")}>
											<ul>
												<li><Link to="/add-products">Add Products</Link></li>
												<li><Link to="/manage-users">Add User</Link></li>
												<li><Link to="/add-data-sources">Add Data</Link></li>
												<li><Link to="#">Sell Data</Link></li>
												<li><Link to="#">Review Bills</Link></li>
											</ul>
										</div>
									</div>
								</Col>
								<Col>
								</Col>
							</Row>
						</section>

						{functions.length > 0 && <section className="client-subscription saas-subscription">
              <h2>Analytics Subscription</h2>
							<ul className="subscription">
								{selectFunctions.map((item, index) => (clientFunction.includes(item.value) &&
									<li key={index} className={(functions.includes(item.value) || item.value==='IT') ? "subscribed" : undefined}>
										<span className="image"><img src={item.attribute1 && item.attribute1} alt={item.value} width="38" /></span>
										<span className="text">{item.value}</span>
									</li>
								))}
							</ul>
            </section>}

						{biDetails.length > 0 &&
							<section className="list-table">
								<h4>BI</h4>
								<BIList biDetails={biDetails} pendingWorkBasketLookup={pendingWorkBasketLookup.length > 0 && pendingWorkBasketLookup} handleShowIntegrateSystems={handleShowIntegrateSystems} />
							</section>
						}
						{deDetails.length > 0 &&
							<section className="list-table">
								<h4>Decision Engine</h4>
								<DEList deDetails={deDetails} pendingWorkBasketLookup={pendingWorkBasketLookup.length > 0 && pendingWorkBasketLookup} handleShowIntegrateSystems={handleShowIntegrateSystems} />
							</section>
						}
						{saasUsers &&
							<section className="list-table">
								<h4>Analytics Users</h4>
								<SaaSUsersList saasUsers={saasUsers} pendingWorkBasketLookup={pendingWorkBasketLookup.length > 0 && pendingWorkBasketLookup} handleShowIntegrateSystems={handleShowIntegrateSystems} />
							</section>
						}
						{saasInfrastructure &&
							<section className="list-table">
								<h4>Analytics Infrastructure</h4>
								<SaaSInfraList saasInfrastructure={saasInfrastructure} pendingWorkBasketLookup={pendingWorkBasketLookup.length > 0 && pendingWorkBasketLookup} handleShowIntegrateSystems={handleShowIntegrateSystems} />
							</section>
						}
						{dataSources &&
							<section className="list-table">
								<h4>Data Sources</h4>
								<SystemsList dataSources={dataSources} pendingWorkBasketLookup={pendingWorkBasketLookup.length > 0 && pendingWorkBasketLookup} handleShowIntegrateSystems={handleShowIntegrateSystems} />
							</section>
						}
					</Container>
				</main>
			</div>
		</>
	);
}
 
export default SaaSCI;