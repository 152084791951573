import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { MDBDataTable } from "mdbreact";
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import axios from 'axios';
import UserService from '../services/UserService';
 
const serverConfig = require("../config/server.js");

const Notifications = () => {

  const navigate = useNavigate();
  
  const [token, setToken] = useState(null);

  const [notificationsRender, setNotificationsRender] = useState([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState('');

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      console.log('setting token');
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    if(token !== null) {
      axios.post(serverConfig.api_base_url + "notification_get_list", {token:token, mark_read: 1})
      .then(response=>{
        console.log('notification_get_list response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
            let items = response.data.items;
            setNewNotificationsCount(items.filter(item => item.read === 'N').length);
            console.log('notifications', items);
            let appData = [];
            items.reverse().map((item, index) => {
              if(item.read === 'N'){
                item.notification_message = (<span className='txt-yellow'>{item.notification_message}</span>)
              } else if(item.read === 'Y'){
                item.notification_message = (<span>{item.notification_message}</span>)
              }
              appData.push({"notifications": item.notification_message, "stamp":item.stamp});
            });
            console.log(appData);
            setNotificationsRender(appData);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));


    }
  }, [token]);

  const data = {
    columns: [
      {
        label: 'Notifications',
        field: 'notifications',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Date & Time stamp',
        field: 'stamp',
        sort: 'disabled',
        width: 270
      }
    ],

    rows: notificationsRender,
  };

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} notificationsCount={newNotificationsCount} />
        <main>
          <Container fluid>
            <p className="txt-yellow"><button className='back' onClick={() => navigate(-1)}><span className='txt-gray me-2'>&#8249;</span> Back</button> <span className='txt-gray ms-2 me-2'>|</span> New Notifications ({newNotificationsCount})</p>

            <section className="list-table notifications-table">
              <MDBDataTable striped small borderless data={data} entries={20} />
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}

export default Notifications;