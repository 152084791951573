import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import UserService from '../../services/UserService';
import { Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';

const serverConfig = require("./../../config/server.js");

const Header = ({token, isMarketplacePage, notificationsCount, refreshNotifications, setIndustry, setCompany}) => {

	const [notificationStatus, setNotificationStatus] = useState(true);

	const notificationIconRef = useRef();

	const handleClickOutside = e => {
		if (!notificationIconRef.current.contains(e.target)) {
				setShowNotification(false);
		}
	};

	useEffect(() => {
			document.addEventListener('mousedown', handleClickOutside);
			return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const handleTogglePage = (event) => {
		if (event.target.checked) {
			window.location.href='/marketplace';
    } else {
			window.location.href='/';
    }
	}

	const [showNotification, setShowNotification] = useState(false);
  const handleToggleNotification = () => { setShowNotification(!showNotification); };

	const [newNotificationsCount, setNewNotificationsCount] = useState('');
	const [notifications, setNotifications] = useState([]);

	const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");

	useEffect(() => {
		if(token !== null && token !== undefined) {
			axios.post(serverConfig.api_base_url + "company_details_get",{token:token})
			.then(response=>{
				console.log("company_details_get response:", response);
				if (response.data.status === 200) {
					if(response.data.companyName) {
						setCompanyName(response.data.companyName);
						if(setCompany){
							setCompany(response.data.companyName);
						}
					}
					if(response.data.companyLogo) {
						setCompanyLogo(response.data.companyLogo);
					}
					if(setIndustry){
						if(response.data.companyIndustry) {
							setIndustry(response.data.companyIndustry);
						}
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
		}
	}, [token]);
	
	useEffect(() => {
		console.log(token);
		if(token !== null && token !== undefined) {
			axios.post(serverConfig.api_base_url + "notification_get_list", {token:token, mark_read: 0})
      .then(response=>{
        console.log('notification_get_list response', response);
        if(response.data.status === 200){
          if(response.data.items !== null){
						let items = response.data.items;
						setNewNotificationsCount(items.filter(item => item.read === 'N').length);
            console.log('notifications', items);
						setNotifications(items.filter(item => item.read === 'N').sort((a, b) => b.stamp.localeCompare(a.stamp)).slice(0, 5));
					}
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
		}
	}, [token, refreshNotifications]);

	return (
		<header>
			<Container fluid>
				<Row className="align-items-center">
					<Col xs="auto" className="header-left">
						<h1>
              <img src={require('./../../assets/images/underwriting-logo.png')} alt="Underwriting Engine" />
						</h1>
					</Col>
					<Col xs="auto" className="header-right">
						<div className="notification" ref={notificationIconRef}>
							<button className={notificationStatus ? 'notification-true' : null} onClick={handleToggleNotification}d>
								<img src={require('./../../assets/images/icons/notification-icon.png')} alt="Notification"/>
								{newNotificationsCount !== '' && <span className="count">{notificationsCount ? notificationsCount :newNotificationsCount}</span>}
							</button>
							<ul className={"notification-list " + (showNotification ? "show" : "")}>
								{notifications.length > 0 && notifications.map((item, index) => (
                  <li key={index}><span>{item.notification_message}</span></li>
                ))}
								<li><Link to="/notifications">See all</Link></li>
							</ul>
						</div>
						<div className="user">
							<span className="text">Hello {UserService.getWelcomeName()}!</span>
							<button onClick={handleLogout}>
								<img src={require('./../../assets/images/icons/user-icon.png')} alt="User" />
							</button>
						</div>
					</Col>
				</Row>
			</Container>
		</header>
	);
}

export default Header;