import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form} from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/Header';
import LeftNav from '../components/LeftNav';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const DataSourceMetaverseMapping = () => {

  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  const [inputs, setInputs] = useState({});
  const [industry, setIndustry] = useState("");
  const [purpose, setPurpose] = useState([]);
  const [metaverseEntitiesLookup, setMetaverseEntitiesLookup] = useState([]);
  const [dataSources, setDataSources] = useState({});

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
		if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "data_sources"})
			.then(response=>{
				console.log("data_sources entity_details_get response:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("data_sources entity_details_get data:", response.data.items);
            var items = response.data.items;
            for (const key in items) {
              if(`${key}`.includes("email") || `${key}`.includes("name") || `${key}`.includes("role") || `${key}`.includes("profile") || `${key}`.includes("password")){
                console.log(`${key}: ${items[key]}`);
                delete items[`${key}`];
              }
            }
            console.log(items);
            setDataSources(items);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      /* axios.post(serverConfig.api_base_url + "lookup_list",{token:token, name:"metaverse_entities"})
      .then(response=>{
        console.log("metaverse_entities lookup:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("metaverse_entities lookup data:", response.data.items);
            response.data.items.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            setMetaverseEntitiesLookup(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err)); */

      axios.post(serverConfig.api_base_url + "purpose_get",{token:token})
      .then(response=>{
        console.log("purpose_get:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("purpose_get data:", response.data.items);
            setPurpose(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "metaverse_entity_get",{token:token})
      .then(response=>{
        console.log("metaverse_entity_get:", response);
        if (response.data.status === 200) {
          if(response.data.items !== null){
            console.log("metaverse_entity_get data:", response.data.items);
            setMetaverseEntitiesLookup(response.data.items);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}
    console.log(inputs);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputs);
    axios.post(serverConfig.api_base_url + "metadata_map",{token:token, items:inputs})
    .then(response=>{
      console.log("metadata_map response:", response);
      if (response.data.status === 200) {
        console.log('success');
        navigate('/data-attributes-mapping');
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <LeftNav />
      <div className="main-wrapper">
        <Header token={token} setIndustry={setIndustry} />
        <main>
          <Container fluid>
            <section className="form full-ht">
              <div className="breadcrumbs">
                <ul>
                  <li>Enterprise Analytics-as-a-Service</li>
                  <li>|</li>
                  <li><Link to="/create-client">Setup New Client</Link></li>
                  <li>|</li>
                  <li>Analytics Admin Screen</li>
                </ul>
              </div>
              <h1 className="mb-4">Data Source &amp; Metaverse Mapping | {industry}</h1>
              <p className='txt-gray'>Click to choose the entity you want to map to with the listed System names</p>
              <Form onSubmit={handleSubmit}>
                {dataSources &&
                  Object.keys(dataSources).map((item, index) => <Row key={index} className='mt-3'>
                    <Col>
                      <fieldset className='full-ht'>
                        <Row>
                          <Col className="fieldset-heading">
                            {index === 0 && <h6 className="txt-white mb-0">Source Systems</h6>}
                            <h5>{item}</h5>
                          </Col>
                          <Col>
                            {index === 0 && <h6 className="txt-white">Data Entity Names:</h6>}
                            <Form.Group>
                              {dataSources[item].length > 0 &&
                                dataSources[item].map((subitem) => <Form.Check name={item} type="checkbox" id={item+"-"+subitem} value={subitem} label={subitem} onChange={handleChange} />)
                              }
                            </Form.Group>
                          </Col>
                        </Row>
                      </fieldset>
                    </Col>
                    <Col>
                      <fieldset className='full-ht starred'>
                        <h6 className='mb-3'>Metaverse | {industry}</h6>
                        <Row>
                          <Col>
                            <Form.Select name={'system-'+item} defaultValue={item} onChange={handleChange}>
                              <option value="">Select</option>
                              {/* {Object.keys(dataSources).map((dsitem) => <option value={dsitem}>{dsitem}</option>)} */}
                              {purpose.length > 0 && purpose.map((pitem) => <option value={pitem}>{pitem}</option>)}
                            </Form.Select>
                          </Col>
                          <Col>
                            <Form.Select name={'entity-'+item} onChange={handleChange}>
                              <option value="">Select</option>
                              {/* {metaverseEntitiesLookup.length > 0 && metaverseEntitiesLookup.map((el) => <option value={el.value}>{el.value}</option>)} */}
                              {metaverseEntitiesLookup.length > 0 && metaverseEntitiesLookup.map((el) => <option value={el}>{el}</option>)}
                            </Form.Select>
                          </Col>
                        </Row>
                        {Object.keys(inputs).length > 0 && <>{inputs[item] && inputs[item].map((itm) => <Row>
                          <Col>
                            <Form.Select name={'system-'+item+'-'+itm} defaultValue={item} onChange={handleChange}>
                              <option value="">Select</option>
                              {/* {Object.keys(dataSources).map((dsitem) => <option value={dsitem}>{dsitem}</option>)} */}
                              {purpose.length > 0 && purpose.map((pitem) => <option value={pitem}>{pitem}</option>)}
                            </Form.Select>
                          </Col>
                          <Col>
                            <Form.Select name={'entity-'+item+'-'+itm} defaultValue={itm} onChange={handleChange}>
                              <option value="">Select</option>
                              {/* {metaverseEntitiesLookup.length > 0 && metaverseEntitiesLookup.map((el) => <option value={el.value}>{el.value}</option>)} */}
                              {metaverseEntitiesLookup.length > 0 && metaverseEntitiesLookup.map((el) => <option value={el}>{el}</option>)}
                            </Form.Select>
                          </Col>
                        </Row>)}</>}
                      </fieldset>
                    </Col>
                    <Col></Col>
                  </Row>
                  )
                }
                {/* <Row>
                  <Col>
                    <fieldset className='full-ht'>
                      <Row>
                        <Col className="fieldset-heading">
                          <h6 className="txt-white mb-0">Source Systems</h6>
                          <h5>Salesforce (CRM)</h5>
                        </Col>
                        <Col>
                          <h6 className="txt-white">Data Entity Names:</h6>
                          <Form.Group>
                            <Form.Check type="checkbox" id="" label="Account" />
                            <Form.Check type="checkbox" id="" label="Opportunity" />
                            <Form.Check type="checkbox" id="" label="Lead" />
                            <Form.Check type="checkbox" id="" label="Product" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset className='full-ht starred'>
                      <h6 className='mb-3'>Metaverse | {industry}</h6>
                      <Row>
                        <Col>
                          <Form.Select>
                            <option value="CRM">CRM</option>
                            <option value="LOS">LOS</option>
                            <option value="LMS">LMS</option>
                            <option value="FRIS">FRIS</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Account">Account</option>
                            <option value="Opportunity">Opportunity</option>
                            <option value="Lead">Lead</option>
                            <option value="Contact">Contact</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <fieldset className='full-ht'>
                    <Row>
                        <Col className="fieldset-heading">
                          <h5>FinServe (LOS)</h5>
                        </Col>
                        <Col>
                          <h6 className="txt-white">Data Entity Names:</h6>
                          <Form.Group>
                            <Form.Check type="checkbox" id="" label="Account" />
                            <Form.Check type="checkbox" id="" label="Opportunity" />
                            <Form.Check type="checkbox" id="" label="Lead" />
                            <Form.Check type="checkbox" id="" label="Product" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset className='full-ht starred'>
                      <h6 className='mb-3'>Metaverse | {industry}</h6>
                      <Row>
                        <Col>
                          <Form.Select>
                            <option value="CRM">CRM</option>
                            <option value="LOS">LOS</option>
                            <option value="LMS">LMS</option>
                            <option value="FRIS">FRIS</option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select>
                            <option value="Account">Account</option>
                            <option value="Opportunity">Opportunity</option>
                            <option value="Lead">Lead</option>
                            <option value="Contact">Contact</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col></Col>
                </Row> */}
                <Row className="mt-5">
                  <Col>
                    <button type="button" className="btn-transparent" onClick={() => navigate('/')}>Cancel</button>
                    <button type="submit" className="btn-yellow ms-4">Next</button>
                  </Col>
                </Row>
              </Form>
            </section>
          </Container>
        </main>
      </div>
    </>
  );
}
 
export default DataSourceMetaverseMapping;