import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import axios from 'axios';
import AthenaSaaSTermsOfUse from './AthenaSaaSTermsOfUse';
import UserService from '../services/UserService.js';

const serverConfig = require("../config/server.js");
 
const DeployBI = ({token, handleLogout, companyName, callRefreshNotification, refreshNotifications}) => {

  const [deployStep, setDeployStep] = useState('Overview');

  const [showAlert, setShowAlert] = useState(false);
  const [messageText, setMessageText] = useState("");

  const [deployProductId, setDeployProductId] = useState('');
  const [deployProductName, setDeployProductName] = useState('');
  const [subscribeProductName, setSubscribeProductName] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [longDesc, setLongDesc] = useState('');
  const [subscribePopupBase64, setSubscribePopupBase64] = useState('');
  const [productStatus, setProductStatus] = useState('');
  const [deployFuncName, setDeployFuncName] = useState('');
  const [deployIcon, setDeployIcon] = useState('');
  const [deployDesc, setDeployDesc] = useState('');
  const [deployShortDesc, setDeployShortDesc] = useState('');
  const [subscription, setSubscription] = useState('');

  const [userSaasModule, setUserSaasModule] = useState([]);
	const [biProducts, setBIProducts] = useState([]);
	const [biModuleList, setBIModuleList] = useState({});
	const [deployBIDetails, setDeployBIDetails] = useState([]);
	const [userFunctionList, setUserFunctionList] = useState([]);
	const [clientUserList, setClientUserList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [inputs, setInputs] = useState({});
  const [actions, setActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewReportsPrivileged, setViewReportsPrivileged] = useState(true);

  const [rolesList, setRolesList] = useState([]);
  const [profileList, setProfileList] = useState([]);

  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const handleCloseTermsOfUse = () => setShowTermsOfUse(false);
  const handleShowTermsOfUse = () => setShowTermsOfUse(true);

  const profileListGet = () => {
    axios.post(serverConfig.api_base_url + "profile_list_get",{token: token})
    .then(response=>{
      console.log('profile_list_get response', response.data.items);
      if(response.data.status === 200){
        if(response.data.items !== null){
          setProfileList(response.data.items);
        } else {
          setProfileList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleAddProfile = (event) => {
    let profile_name = event.target.closest('.profile-wrap').querySelector('input').value;
    console.log(profile_name);
    axios.post(serverConfig.api_base_url + "profile_create",{token:token, profileName: profile_name})
    .then(response=>{
      console.log("profile_create", response);
      if (response.data.status === 200) {
        profileListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getBIProducts = () => {
    axios.post(serverConfig.api_base_url + "analytics_products_details_get",{token:token, moduleName:"BI"})
    .then(response=>{
      console.log("analytics_products_details_get:", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log("analytics_products_details_get data:", response.data.items);
          setBIProducts(response.data.items);
          setIsLoading(false);
        } else {
          setBIProducts([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const userProductListGet = () => {
    axios.post(serverConfig.api_base_url + "user_product_list_get",{token:token})
    .then(response=>{
      console.log("user_product_list_get response:", response);
      if (response.data.status === 200) {
        console.log('success');
        if(response.data.items !== null){
          setUserFunctionList(response.data.items);
          if(deployProductName !== ''){
            setUserList(response.data.items.filter((item) => (item.product_name === deployProductName)));
          }
        } else {
          setUserFunctionList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const callOnDeployPopup = () => {
    /* profile_list_get */
    profileListGet();

    userProductListGet();

    /* user_role_list_get */
    axios.post(serverConfig.api_base_url + "user_role_list_get",{token: token})
    .then(response=>{
      console.log('user_role_list_get response', response.data.items);
      if(response.data.status === 200){
        setRolesList(response.data.items.filter((item) => !item.includes('Athena Admin') && !item.includes('Athena Product Manager')));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
		if(token !== null && token !== undefined){
      axios.post(serverConfig.api_base_url + "user_check_privilege", {token:token, privilege:"view_reports"})
			.then(response=>{
				console.log('user_check_privilege response', response.data);
				if(response.data.status === 200){
					if(response.data.is_privileged === 1) {
						setViewReportsPrivileged(true);
					} else if(response.data.is_privileged === 0) {
						setViewReportsPrivileged(false);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

			axios.post(serverConfig.api_base_url + "modules_list_get", {token:token})
			.then(response=>{
				console.log('modules_list_get response', response);
				if(response.data.status === 200){
					if(response.data.items !== null){
						console.log('modules_list_get response', response.data.items);
						setUserSaasModule(response.data.items);
					} else {
						setUserSaasModule([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      getBIProducts();

      axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "deploy_bi"})
			.then(response=>{
				console.log("deploy_bi entity_details_get response:", response);
				if (response.data.status === 200) {
					if(response.data.items !== null){
						console.log("deploy_bi entity_details_get data:", response.data.items);
						setDeployBIDetails(response.data.items);
					} else {
						setDeployBIDetails([]);
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "entity_details_get",{token:token, entity_name: "modules"})
			.then(response=>{
				console.log("modules entity_details_get response:", response);
				if (response.data.status === 200) {
					console.log('success');
					if(response.data.items !== null){
						setBIModuleList(response.data.items.biModules);
					} else {
            setBIModuleList({});
					}
				} else if(response.data.status === 301){
					handleLogout();
				} else {
					alert(response.data.message);
				}
			})
			.catch(err=>console.log("error is",err));
    }
  }, [token]);

  useEffect(() => {
		if(token !== null && token !== undefined){
      if(!viewReportsPrivileged){
        callOnDeployPopup();
      }
    }
  }, [token,viewReportsPrivileged, actions, clientUserList]);

  const [showDeployModal, setShowDeployModal] = useState(false);
  const handleCloseDeployModal = () => {
    setDeployStep('Overview');

    setDeployProductId('');
    setDeployProductName('');
    setDeployFuncName('');
    setDeployIcon('');
    setDeployDesc('');
    setDeployShortDesc('');
    setSubscription('');

    setShowDeployModal(false);
  }
  const handleShowDeployModal = (module_name, product_id, product_name, function_name, desc, shortdesc, subscription, icon, imageBase64, event) => {
    axios.post(serverConfig.api_base_url + "client_user_list_get",{token:token})
    .then(response=>{
      console.log("client_user_list_get response:", response);
      if (response.data.status === 200) {
        console.log('success');
        if(response.data.items !== null){
          setClientUserList(response.data.items);
        } else {
          setClientUserList([]);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));

    callOnDeployPopup();
    
    console.log(event.target.innerText);
    if(event.target.innerText === "Trial"){
      //event.target.innerText = 'Deploy';
      axios.post(serverConfig.api_base_url + "product_status_update",{token:token, productName: product_name, isTrial:1})
      .then(response=>{
        setIsLoading(true);
        console.log("product_status_update response:", response);
        if (response.data.status === 200) {
          console.log('success');
          getBIProducts();
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setIsLoading(false);
        }
      })
      .catch(err=>console.log("error is",err));
    } else /* if(event.target.innerText === "Deploy") */{
      console.log(module_name, product_name, function_name, desc, shortdesc, icon);
      setDeployProductId(product_id);
      setDeployProductName(product_name);
      setDeployFuncName(function_name);
      if(imageBase64 !== null){
        setDeployIcon(imageBase64);
      } else {
        setDeployIcon(icon);
      }
      setDeployDesc(desc);
      setDeployShortDesc(shortdesc);
      setSubscription(subscription);
      setShowDeployModal(true);
      console.log(userFunctionList);
      /* console.log(userFunctionList.filter((item) => (item.functions === function_name && item.module === module_name)));
      setUserList(userFunctionList.filter((item) => (item.functions === function_name && item.module === module_name))); */
      console.log(userFunctionList.filter((item) => (item.product_name === product_name)));
      setUserList(userFunctionList.filter((item) => (item.product_name === product_name)));
    }
  }

  const handlePrevDeployModal = () => {
    if(deployStep === 'Access'){
      setDeployStep('Overview');
    } else if(deployStep === 'Review'){
      setDeployStep('Access');
    } else if(deployStep === 'Deploy'){
      setDeployStep('Review');
    }
  }

  const handleNextDeployModal = () => {
    if(deployStep === 'Overview'){
      setDeployStep('Access');
    } else if(deployStep === 'Access'){
      setDeployStep('Review');
    } else if(deployStep === 'Review'){
      setDeployStep('Deploy');
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

		if(event.target.classList.contains("profile")){
      const list = event.target.nextSibling.querySelectorAll("option");
      if(value.length > 3){
        const found = [...list].find(opt => opt.value===value);
        if (!found) {
          console.log("Please choose an existing value");
          event.target.closest('div').querySelector('button').classList.add('d-block');
          event.target.closest('div').querySelector('button').classList.remove('d-none');
        } else {
          console.log("found");
          event.target.closest('div').querySelector('button').classList.add('d-none');
          event.target.closest('div').querySelector('button').classList.remove('d-block');
        }
      } else {
        event.target.closest('div').querySelector('button').classList.add('d-none');
        event.target.closest('div').querySelector('button').classList.remove('d-block');
      }
    }

		if(event.target.type === "checkbox"){
      var checkboxes = document.getElementsByName(name);
      var checkboxesChecked = [];
      for (var i=0; i<checkboxes.length; i++) {
        // Push the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      console.log(checkboxesChecked);
			setInputs(values => ({...values, [name]: checkboxesChecked}));
      console.log(checkboxesChecked.length);
			if(checkboxesChecked.length !== 0){
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = false;
				}
			} else {
				for(let i=0; i < checkboxes.length; i++){
					checkboxes[i].required = true;
				}
			}
		} else {
    	setInputs(values => ({...values, [name]: value}));
		}

    console.log(inputs);
  }

  const handleSelectUser = (event) => {
    event.preventDefault();
    console.log(event);
    const emailid = event.target.querySelector('input[type="text"]').value;

    const list = event.target.querySelectorAll("option");
    const found = [...list].find(opt => opt.value===emailid);
    if (!found) {
      console.log(emailid);
      setMessageText("User with email "+emailid+" does not exists");
      //handleShowMessage();
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessageText("");
      }, 3000);
    } else {
      axios.post(serverConfig.api_base_url + "user_product_active",{token:token, email_id:emailid, product_id: deployProductId, is_active:1})
      .then(response=>{
        console.log("user_product_active response:", response);
        if (response.data.status === 200) {
          axios.post(serverConfig.api_base_url + "provide_product_access",{token:token, email_id:emailid, product_id: deployProductId})
          .then(response=>{
            setIsLoading(true);
            console.log("provide_product_access response:", response);
            if (response.data.status === 200) {
              console.log('success');
              setActions(!actions);// to refresh user list after user is created
              setMessageText("User with email "+emailid+" added");
              //handleShowMessage();
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setMessageText("");
              }, 3000);
              event.target.reset();
              setIsLoading(false);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handlePasswordGenerate = (event) => {
    //user_password_generate
    console.log(event.target.id);
    axios.post(serverConfig.api_base_url + "user_password_generate",{})
    .then(response=>{
      console.log('user_password_generate response', response);
      if(response.data.status === 200){
        if(event.target.id === 'createUserPwdGenerate'){
          setInputs({
            ...inputs,
            password:response.data.password
          });
        }
        event.target.closest('.row').querySelector('input[type="password"]').type = "text";
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  const togglePasswordVisibility = (event) => {
    //var x = document.getElementById("password");
    var x = event.target.closest('.position-relative').querySelector('input');
    var y = event.target;
    if (x.type === "password") {
      x.type = "text";
      y.classList.remove('fa-eye-slash');
      y.classList.add('fa-eye');
    } else {
      x.type = "password";
      y.classList.add('fa-eye-slash');
      y.classList.remove('fa-eye');
    }
  }

  const [createUserModalShow, setCreateUserModalShow] = useState(false);
  const handleCloseCreateUserModal = () => {
    setInputs({})
    setCreateUserModalShow(false);
  }
  const handleShowCreateUserModal = () => setCreateUserModalShow(true);
  const handleCreateUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(inputs);
    var formdata = {
      token:token,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email_id: inputs.email_id,
      password: inputs.password,
    }
    console.log(formdata);

    /* user_exists_check - start */
    axios.post(serverConfig.api_base_url + "user_exists_check",{token:token, email_id:inputs.email_id})
    .then(response=>{
      console.log("user_exists_check response:", response);
      if (response.data.status === 200) {
        if(response.data.user_exists === 0){
          axios.post(serverConfig.api_base_url + "user_create",formdata)
          .then(response=>{
            console.log("User create response:", response);
            if(response.data.status === 200){
              /* user_grant_role - start */
              axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: inputs.email_id, roles: [inputs.role]})
              .then(response=>{
                console.log('user_grant_role response', response);
                if(response.data.status === 200){//on success set athena modules subscribed
                  console.log('success');
                  /* user_product_active - start */
                  inputs.saas_module.forEach(function(el){
                    console.log(el);
                    axios.post(serverConfig.api_base_url + "user_product_active",{token:token, email_id:inputs.email_id, product_id: deployProductId, is_active:1})
                    .then(response=>{
                      console.log("user_product_active response:", response);
                      if (response.data.status === 200) {
                        axios.post(serverConfig.api_base_url + "provide_product_access",{token:token, email_id:inputs.email_id, product_id: deployProductId})
                        .then(response=>{
                          setIsLoading(true);
                          console.log("provide_product_access response:", response);
                          if (response.data.status === 200) {
                            console.log('success');
                            setActions(!actions);// to refresh user list after user is created
                            setMessageText("User with email "+inputs.email_id+" added");
                            //handleShowMessage();
                            setShowAlert(true);
                            setTimeout(() => {
                              setShowAlert(false);
                              setMessageText("");
                            }, 3000);
                            handleCloseCreateUserModal();
                            setIsLoading(false);
                          } else if(response.data.status === 301){
                            handleLogout();
                          } else {
                            alert(response.data.message);
                            setIsLoading(false);
                          }
                        })
                        .catch(err=>console.log("error is",err));
                      } else if(response.data.status === 301){
                        handleLogout();
                      } else {
                        alert(response.data.message);
                      }
                    })
                    .catch(err=>console.log("error is",err));
                  });
                  /* user_product_active - end */
                } else if(response.data.status === 301){
                  handleLogout();
                } else {
                  alert(response.data.message);
                }
              })
              .catch(err=>console.log("error is",err));
              /* user_grant_role - end */
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        } else if(response.data.user_exists === 1){
          setMessageText('Ignored as '+ inputs.email_id + ' already exists.');
          //handleShowMessage();
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessageText("");
          }, 3000);
          setIsLoading(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    /* user_exists_check - end */
  }

  const [editUserModalShow, setEditUserModalShow] = useState(false);
  const handleCloseEditUserModal = () => {
    setInputs({})
    setEditUserModalShow(false);
  }
  const handleShowEditUserModal = (userDetails) => {
    console.log(userDetails);
    setInputs(userDetails);
    setEditUserModalShow(true);
  }
  const handleEditUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(inputs);
    let module = [];
    if(inputs.module instanceof Array){
      module = inputs.module;
    } else {
      module = [inputs.module];
    }
    
    /* user_grant_role - start */
    axios.post(serverConfig.api_base_url + "user_grant_role",{token: token, email_id: inputs.email_id, roles: [inputs.role]})
    .then(response=>{
      console.log('user_grant_role response', response);
      if(response.data.status === 200){//on success set athena modules subscribed
        console.log('success');
        /* modules_subscribed_set - start */
        module.forEach(function(el){
          console.log(el);
          axios.post(serverConfig.api_base_url + "modules_subscribed_set",{token:token, email_id:inputs.email_id, module:el, profile:inputs.profile, function: inputs.functions})
          .then(response=>{
            console.log("modules_subscribed_set response:", response);
            if (response.data.status === 200) {
              console.log('success');
              setActions(!actions);// to refresh user list after user is updated
              setMessageText("User with email "+inputs.email_id+" updated");
              //handleShowMessage();
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setMessageText("");
              }, 3000);
              handleCloseEditUserModal();
              setIsLoading(false);
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              alert(response.data.message);
            }
          })
          .catch(err=>console.log("error is",err));
        });
        /* modules_subscribed_set - end */
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
    /* user_grant_role - end */
  }

  const handleSetActive = (email, isActive) => {
    axios.post(serverConfig.api_base_url + "user_product_active",{token:token, email_id: email, product_id:deployProductId, is_active: isActive})
    .then(response=>{
      console.log("user_product_active", response);
      let message;
      if(isActive === 0){
        message = `User ${email} access to product ${deployProductName} is now inactive`;
      } else if(isActive === 1){
        message = `User ${email} access to product ${deployProductName} is now active`;
      }
      if (response.data.status === 200) {
        console.log('success');
        setMessageText(message);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessageText("");
        }, 5000);
        userProductListGet();
        /* let function_name = userList[0].functions;
        let module_name = userList[0].module;
        console.log(userFunctionList, userList, function_name, module_name);
        console.log(userFunctionList.filter((item) => (item.functions === function_name && item.module.includes(module_name))));
        setUserList(userFunctionList.filter((item) => (item.functions === function_name && item.module.includes(module_name)))); */
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleSetSubscribe = (email, isSubscribed) => {
    axios.post(serverConfig.api_base_url + "user_product_subscribe",{token:token, email_id: email, product_id:deployProductId, is_subscribed: isSubscribed})
    .then(response=>{
      console.log("user_product_subscribe", response);
      let message;
      if(isSubscribed === 0){
        message = `User ${email} subscription to product ${deployProductName} is now inactive`;
      } else if(isSubscribed === 1){
        message = `User ${email} subscription to product ${deployProductName} is now active`;
      }
      if (response.data.status === 200) {
        console.log('success');
        setMessageText(message);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessageText("");
        }, 5000);
        userProductListGet();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleDeactivateUser = (email) => {
    axios.post(serverConfig.api_base_url + "user_deactivate",{token:token, email_id: email})
    .then(response=>{
      console.log("user_deactivate", response);
      if (response.data.status === 200) {
        let function_name = userList[0].functions;
        let module_name = userList[0].module;
        console.log(userFunctionList, userList, deployProductName);
        console.log(userFunctionList.filter((item) => (item.product_name === deployProductName)));
        setUserList(userFunctionList.filter((item) => (item.product_name === deployProductName)));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleDeploy = () => {
    setIsLoading(true);
    console.log(biModuleList);
    /* console.log(deployProductName, deployFuncName);
    console.log(userList);
    console.log(Object.keys(biModuleList).filter(key => String(key).startsWith(deployFuncName)).reduce((obj, key) => {
      obj[key] = biModuleList[key];
      return obj;
    }, {})); */
    
    var newObj = {
      function: deployFuncName,
      module: deployProductName,
      billing_cycle: biModuleList[`${deployFuncName+'_'+deployProductName+'_billing_cycle'}`],
      function_head: biModuleList[`${deployFuncName+'_first_name'}`] +' '+ biModuleList[`${deployFuncName+'_last_name'}`],
      email_id: biModuleList[`${deployFuncName+'_email_id'}`],
      subscription: subscription,
      status: 'Active'
    };
    console.log(newObj);
    console.log([{...newObj}, ...deployBIDetails]);
    setDeployBIDetails([{...newObj}, ...deployBIDetails]);

    axios.post(serverConfig.api_base_url + "modules_deploy",{token:token, module: "BI", items:[{...newObj}, ...deployBIDetails]})
    .then(response=>{
      console.log("modules_deploy response:", response);
      if (response.data.status === 200) {
        console.log('success');
        var formdata;
        if(subscription === 'Subscribed'){
          formdata = {token:token, productName: deployProductName};
        } else if(subscription === 'Unsubscribed'){
          formdata = {token:token, productName: deployProductName, isTrial:0};
        }
        axios.post(serverConfig.api_base_url + "product_status_update",formdata)
        .then(response=>{
          console.log("product_status_update response:", response);
          if (response.data.status === 200) {
            console.log('success');

            //console.log(userFunctionList);
            console.log(userList, userList.length);
            if(userList.length > 0){
              userList.forEach(function(el){
                console.log(el);
                axios.post(serverConfig.api_base_url + "user_product_deploy", {token:token, email_id:el.email_id, product_id: deployProductId, is_active:el.is_active})
                .then(response=>{
                  console.log("user_product_deploy response:", response);
                  if (response.data.status === 200) {
                    setMessageText(`Product ${deployProductName} deployed`);
                    setShowAlert(true);
                    setTimeout(() => {
                      setShowAlert(false);
                      setMessageText("");
                    }, 3000);
                    getBIProducts();
                    handleCloseDeployModal();
                  } else if(response.data.status === 301){
                    handleLogout();
                  } else {
                    alert(response.data.message);
                    setIsLoading(false);
                  }
                })
                .catch(err=>console.log("error is",err));
              });
            } else {
              getBIProducts();
              handleCloseDeployModal();
            }
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
            setIsLoading(false);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [showSubscribe, setShowSubscribe] = useState(false);
  const handleCloseSubscribe = () => {
    setDeployProductId("");
    setSubscribeProductName("");
    setShortDesc("");
    setLongDesc("");
    setSubscribePopupBase64("");
    setProductStatus("");
    setShowSubscribe(false);
  }
  const handleShowSubscribe = (productId, productName, shortDesc, longDesc, popupBase64, productStatus) => {
    console.log(productName);
    setDeployProductId(productId);
    setSubscribeProductName(productName);
    setShortDesc(shortDesc);
    setLongDesc(longDesc);
    setSubscribePopupBase64(popupBase64);
    setProductStatus(productStatus);
    setShowSubscribe(true);
  }

  const handleSubscribe = (productName) => {
    axios.post(serverConfig.api_base_url + "product_subscribed_set",{token:token, productName:productName})
    .then(response=>{
      setIsLoading(true);
      console.log("product_subscribed_set response:", response);
      if (response.data.status === 200) {
        axios.post(serverConfig.api_base_url + "notification_set",{token:token, notificationText: `Subscription for ${productName} completed`})
        .then(response=>{
          console.log("notification_set response:", response);
          if (response.data.status === 200) {
            setMessageText(`Subscription for ${productName} completed`);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setMessageText("");
            }, 3000);
            callRefreshNotification(!refreshNotifications);
            getBIProducts();
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleRequest = (e) => {
    e.preventDefault();
		console.log(subscribeProductName);
    axios.post(serverConfig.api_base_url + "product_subscribed_set",{token:token, productName:subscribeProductName})
    .then(response=>{
      setIsLoading(true);
      console.log("product_subscribed_set response:", response);
      if (response.data.status === 200) {
        var message = `Subscription requested for ${subscribeProductName}`;
        axios.post(serverConfig.api_base_url + "notification_set",{token:token, notificationText: message})
        .then(response=>{
          console.log("notification_set response:", response);
          if (response.data.status === 200) {
            setMessageText(message);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setMessageText("");
            }, 3000);
            axios.post(serverConfig.api_base_url + "provide_product_access",{token:token, email_id:UserService.getUsername(), product_id: deployProductId})
            .then(response=>{
              console.log("provide_product_access response:", response);
              if (response.data.status === 200) {
                callRefreshNotification(!refreshNotifications);
                getBIProducts();
                handleCloseSubscribe();
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
                setIsLoading(false);
              }
            })
            .catch(err=>console.log("error is",err));
          } else if(response.data.status === 301){
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  
  /* const [showMessage, setShowMessage] = useState(false);
  const handleCloseMessage = () => {
    setMessageText("");
    setShowMessage(false);
  }
  const handleShowMessage = () => setShowMessage(true); */

  return (
    <>
      {isLoading && <div id="loading"><img src={require('./../assets/images/icons/spinner.gif')} alt="Loader" width="70" /></div>}
      {showAlert && <div className='alert-msg'><Container fluid>{messageText}</Container></div>}
      <Container fluid>
        <section className="bg mb-5" style={{ backgroundImage: `url(${require("./../assets/images/bi-banner.jpg")})` }}>
          <div className="section-title">
            <h2>BUSINESS INTELLIGENCE</h2>
            <h3>Intelligence-as-a-Service | Pay Per Module</h3>
            <p>Plug n Play modules for decision makers that bring you unbiased intelligence on business performance to inform your business strategy</p>
          </div>
          <div className="bottom-text">
            <p>300%+ Increase In Integrated Data Delivery</p>
            <p>30%+ Increase In Business Productivity</p>
            <p>50%+ Operations Efficiency, Driving Up Performance</p>
          </div>
        </section>
        {biProducts.length > 0 &&
          biProducts.map((item, index) => {
            let productList;
            if(viewReportsPrivileged){
              //productList = item.product_details.filter(el =>  (el.email_id === UserService.getUsername() || el.email_id === null));
              productList = [...new Map(item.product_details.map(item => [item.product_id, item])).values()];
            } else {
              productList = item.product_details;
            }
            return(<>{/* biModuleList.functions && biModuleList.functions.length > 0 && biModuleList.functions.includes(item.function) && */
              <section key={index} className='deploy-bi mt-2'>
                <h5 className='txt-yellow'>{item.function}</h5>
                <Row>
                  {productList.length > 0 &&
                    productList.map((product, productIndex) => {
                      function handleFunc(e){
                        if(product.status === 'Active'){
                          handleShowDeployModal("BI", product.product_id, product.product_name, item.function, product.product_long_desc, product.product_short_desc, product.subscription, product.product_icon, product.image_base64, e);
                        } else if(product.status === 'Subscribe'){
                          handleSubscribe(product.product_name);
                        } else if(product.status === 'Unavailable' || product.status === 'Inactive'){
                          e.stopPropagation();
                        }
                      };
                      return(<Col index={productIndex} lg={4} className={product.status+" mb-4"}>
                        <div className={viewReportsPrivileged ? 'col-wrap cursor-pointer' : (product.status === 'Subscribe' || product.status === 'Active' ? 'col-wrap cursor-pointer' : 'col-wrap')} onClick={viewReportsPrivileged ? () => handleShowSubscribe(product.product_id, product.product_name, product.product_short_desc, product.product_long_desc, product.image_base64, product.status) : (e) => handleFunc(e)} title={(viewReportsPrivileged && product.status === 'Subscribe') ? 'Subscribe' : ''}>
                          <div className='icon'><img src={product.product_icon && product.product_icon} alt={item.value} /></div>
                          <div className='content-wrap'>
                            <h6>{product.product_name}</h6>
                            <div className='status'>
                              <span>{product.subscription}</span>
                              {product.status_info && <OverlayTrigger placement="right" overlay={<Tooltip>{product.status_info}</Tooltip>}>
                                <button type="button" className='info'>i</button>
                              </OverlayTrigger>}
                            </div>
                          </div>
                          <div className='button-wrap'>
                            <div className='ribbon'></div>
                            <div>
                              {(product.status === 'Active' || product.status === 'Unavailable' || product.status === 'Subscribe') && <>
                                {product.status === 'Active' && <div className='txt-yellow mt-4'>{product.status}</div>}
                                {product.status === 'Unavailable' && <div className='txt-gray mt-4'>{product.status}</div>}
                                {product.status === 'Subscribe' && <div className='mt-4'>{product.status}</div>}
                              </>}
                              {product.status === 'Inactive' && <>
                                {viewReportsPrivileged ? <div className='mt-4'>{product.status}</div> : <Button variant='yellow' onClick={(e) => handleShowDeployModal("BI", product.product_id, product.product_name, item.function, product.product_long_desc, product.product_short_desc, product.subscription, product.product_icon, product.image_base64, e)}>
                                  {product.status}
                                </Button>}
                              </>}
                            </div>
                          </div>
                        </div>
                        {/* <div className={viewReportsPrivileged ? (product.subscription === 'Unsubscribed' ? 'col-wrap cursor-pointer' : 'col-wrap') : ('col-wrap cursor-pointer')} onClick={viewReportsPrivileged ? (product.subscription === 'Unsubscribed' ? () => handleShowSubscribe(product.product_name, product.product_short_desc, product.product_long_desc, product.image_base64) : (e) => e.stopPropagation()) : ((e) => handleShowDeployModal("BI", product.product_name, item.function, product.product_long_desc, product.product_short_desc, product.subscription, product.product_icon, e))} title={(viewReportsPrivileged && product.subscription === 'Unsubscribed') ? 'Subscribe' : ''}>
                          <div className='icon'><img src={product.product_icon && product.product_icon} alt={item.value} /></div>
                          <div className='content-wrap'>
                            <h6>{product.product_name}</h6>
                            <div className='status'>
                              <span>{product.subscription}</span>
                              {product.status_info && <OverlayTrigger placement="right" overlay={<Tooltip>{product.status_info}</Tooltip>}>
                                <button type="button" className='info'>i</button>
                              </OverlayTrigger>}
                            </div>
                          </div>
                          <div className='button-wrap'>
                            <div className='ribbon'></div>
                            {product.data_source === 'Available' ? <div>
                              {product.status === 'Active' ? <div className={product.subscription === 'Subscribed' ? 'txt-yellow mt-4' : 'txt-gray mt-4'}>ACTIVE</div> : !viewReportsPrivileged && <Button variant='yellow' onClick={(e) => handleShowDeployModal("BI", product.product_name, item.function, product.product_long_desc, product.product_short_desc, product.subscription, product.product_icon, e)}>
                                {product.status}
                              </Button>}
                            </div> : <div className='txt-gray mt-4'>
                                {product.status}
                            </div>}
                          </div>
                        </div> */}
                      </Col>)
                    })
                  }
                </Row>
              </section>}
            </>)
          })
        }

        <Modal show={showDeployModal} onHide={handleCloseDeployModal} centered animation={false} className='modal-deploy-bi deploy-bi'>
          <Modal.Header closeButton>
            <div>
              <div className='subscribed'>
                <div className='col-wrap'>
                  <div className='icon'><img src={deployIcon} alt={deployFuncName} /></div>
                  <div className='content-wrap'>
                    <h6>{deployProductName}</h6>
                    <div className='status'>
                      <span>Subscribed</span>
                    </div>
                  </div>
                  <div className='button-wrap'>
                    <div className='ribbon'></div>
                  </div>
                </div>
              </div>
              <ul className='steps mt-4'>
                <li className={(deployStep === 'Overview' || deployStep === 'Access' || deployStep === 'Review' || deployStep === 'Deploy') ? 'completed' : undefined}><span></span><div>Overview</div></li>
                <li className={(deployStep === 'Access' || deployStep === 'Review' || deployStep === 'Deploy') ? 'completed' : undefined}><span></span><div>Access</div></li>
                <li className={(deployStep === 'Review' || deployStep === 'Deploy') ? 'completed' : undefined}><span></span><div>Review</div></li>
                <li className={(deployStep === 'Deploy') ? 'completed' : undefined}><span></span><div>Deploy</div></li>
              </ul>
            </div>
          </Modal.Header>
          <Modal.Body>
            {deployStep === 'Overview' && <>
              <Row>
                <Col xs="auto">
                  <div style={{width: 150, height: 150, border: '1px solid #212121', textAlign: 'center', lineHeight:'150px'}}><img src={deployIcon} alt={deployFuncName} /></div>
                </Col>
                <Col>
                  <h6 className='txt-yellow'>{deployShortDesc}</h6>
                  <p>{deployDesc}</p>
                </Col>
              </Row>
            </>}

            {deployStep === 'Access' && <>
              <h6 className='txt-yellow mb-4'>Access</h6>
              <h6 className='txt-yellow'>Create User</h6>
              <Form onSubmit={handleCreateUser}>
                <Row>
                  <Col>
                    <Row className="mt-4">
                      <Col lg={5}>
                        <Form.Group>
                          <Form.Control type="text" placeholder='First Name' className="rounded-0 border-top-0 border-right-0 border-left-0" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col lg={{ offset: 1, span: 5 }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder='Last Name' className="rounded-0 border-top-0 border-right-0 border-left-0" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Form.Group className="mt-4">
                          <Form.Control type="email" placeholder='Email (Username)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col lg={{ offset: 1, span: 5 }}>
                        <Form.Group className="mt-4">
                          <div className='profile-wrap full-width'>
                            <Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={inputs.profile || ""} onChange={handleChange} required />
                            <datalist id="profile">
                              {profileList.length > 0 &&
                                profileList.map((item) => <option value={item}>{item}</option>)
                              }
                            </datalist>
                            <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Form.Group className="mt-4">
                          <Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={companyName} readOnly />
                        </Form.Group>
                      </Col>
                      <Col lg={{ offset: 1, span: 5 }}>
                        <Form.Group className="mt-4" style={{paddingLeft: '0.75rem'}}>
                          <Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
                          <div>
                            {userSaasModule.length > 0 && userSaasModule.map((item, index) => {
                              return(
                                <Form.Check key={index} inline type="checkbox" name="saas_module" id={"saas-module-"+item} label={item} value={item} onChange={handleChange} required defaultChecked={inputs.saas_module ? inputs.saas_module.includes(item) : false} />
                              )
                            })}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5}>
                        <Form.Group className="mt-4">
                          <Form.Select aria-label="Function" className="rounded-0" name="function" value={inputs.function || ""} onChange={handleChange} /* required */>
                            <option value="">Function Name</option>
                            {biModuleList.functions.length > 0 &&
                              biModuleList.functions.map((item, index) => <option value={item} key={index}>{item}</option>)
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={{ offset: 1, span: 5 }}>
                        <Form.Group className="mt-4">
                          <Form.Select aria-label="User Roles" className="rounded-0" name="role" value={inputs.role || ""} onChange={handleChange} required>
                            <option value="">User Role</option>
                            {rolesList.length > 0 &&
                              rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-4 align-items-end">
                      <Col lg={5}>
                        <div className='position-relative'>
                          <Form.Control type="password" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required className='rounded-0 pe-4' />
                          <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                        </div>
                      </Col>
                      <Col lg={{ offset: 1, span: 5 }}><button type="button" id="createUserPwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2'>Generate Password</button></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        {/* <button type="button" className="btn-transparent" onClick={() => navigate('/')}>Cancel</button> */}
                        <button type="submit" className="btn-yellow">Add User</button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <div className="mt-4 mb-3 divider">or</div>
              <h6 className='txt-yellow'>Select User</h6>
              <Form onSubmit={handleSelectUser}>
                <Row className='align-items-end'>
                  <Col lg={5}>
                    <Form.Group>
                      <div>
                        <Form.Control type="text" placeholder='Type user email' list="email" name="email" className='rounded-0 border-top-0 border-right-0 border-left-0' required />
                        <datalist id="email">
                          {clientUserList.length > 0 && clientUserList.filter(el => !userList.includes(el)).map((item) => <option value={item.email_id}>{item.email_id}</option>)}
                        </datalist>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={{ offset: 1, span: 5 }}><button type="submit" className="btn-yellow">Select User</button></Col>
                </Row>
              </Form>
            </>}

            {deployStep === 'Review' && <>
              <h6 className='txt-yellow mb-4'>Review</h6>
              <div className='d-flex justify-content-between border-bottom'>
                <h6 className='txt-yellow'>Manage user</h6>
                <h6 className='txt-yellow'><button className='txt-yellow' onClick={handleShowCreateUserModal}>Create user +</button></h6>
                <Modal show={createUserModalShow} onHide={handleCloseCreateUserModal} backdrop="static"keyboard={false} centered className='modal-medium'>
                  <Form onSubmit={handleCreateUser}>
                    <Modal.Header closeButton>
                      <Modal.Title>Create User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" className='rounded-0' placeholder="First Name" name="first_name" value={inputs.first_name || ""} onChange={handleChange} required />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" className='rounded-0' placeholder="Last Name" name="last_name" value={inputs.last_name || ""} onChange={handleChange} required />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control type="email" className='rounded-0' placeholder="Email (Username)" name="email_id" value={inputs.email_id || ""} onChange={handleChange} required />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <div className='profile-wrap full-width'>
                              <Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={inputs.profile || ""} onChange={handleChange} required />
                              <datalist id="profile">
                                {profileList.length > 0 &&
                                  profileList.map((item) => <option value={item}>{item}</option>)
                                }
                              </datalist>
                              <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={companyName} readOnly />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" style={{paddingLeft: '0.75rem'}}>
                            <Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
                            <div>
                              {userSaasModule.length > 0 && userSaasModule.map((item, index) => {
                                return(
                                  <Form.Check key={index} inline type="checkbox" name="saas_module" id={"saas-module-"+item} label={item} value={item} onChange={handleChange} required defaultChecked={inputs.saas_module ? inputs.saas_module.includes(item) : false} />
                                )
                              })}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Select aria-label="Function" className="rounded-0" name="function" value={inputs.function || ""} onChange={handleChange} /* required */>
                              <option value="">Function Name</option>
                              {biModuleList.functions.length > 0 &&
                                biModuleList.functions.map((item, index) => <option value={item} key={index}>{item}</option>)
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Select aria-label="User Roles" className="rounded-0" name="role" value={inputs.role || ""} onChange={handleChange} required>
                              <option value="">User Role</option>
                              {rolesList.length > 0 &&
                                rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='align-items-end'>
                        <Col>
                          <div className='position-relative'>
                            <Form.Control type="password" placeholder="Password" name="password" value={inputs.password || ""} onChange={handleChange} required className='rounded-0 pe-4' />
                            <span className='toggle-password' onClick={togglePasswordVisibility}><i className='fas fa-eye'></i></span>
                          </div>
                        </Col>
                        <Col><button type="button" id="createUserPwdGenerate" onClick={handlePasswordGenerate} className='btn-transparent p-2'>Generate Password</button></Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <button type="button" className="btn-transparent" onClick={handleCloseCreateUserModal}>Cancel</button>
                      <button type="submit" className='btn-yellow ms-3'><i className="fas fa-user-plus me-2"></i> Create</button>
                    </Modal.Footer>
                  </Form>
                </Modal>
              </div>
              <Table borderless style={{height:150, overflowY:"auto"}}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Analytics Client</th>
                    <th>Functions</th>
                    <th>User Role</th>
                    <th>Email</th>
                    <th>Active</th>
                    <th>Subscribed</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 && userList.map((usr,usridx) => <>
                    <tr className={usr.user_is_active === 0 ? 'disabled' : undefined}>
                      <td>{usr.name}</td>
                      <td>{companyName}</td>
                      <td>{usr.functions}</td>
                      <td>{usr.role}</td>
                      <td>{usr.email_id}</td>
                      <td>
                        <label className="switch ms-0" title={usr.is_active === 1 ? 'Inactive' : 'Active'}>
                          <input type="checkbox" checked={usr.is_active === 1} onChange={() => handleSetActive(usr.email_id, usr.is_active === 1 ? 0 : 1)} disabled={usr.is_subscribed === 0 && usr.is_active === 0} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <label className="switch ms-0" title={usr.is_subscribed === 1 ? 'Unsubscribe' : 'Subscribe'}>
                          <input type="checkbox" checked={usr.is_subscribed === 1} onChange={() => handleSetSubscribe(usr.email_id, usr.is_subscribed === 1 ? 0 : 1)} />
                          <span className="slider"></span>
                        </label>
                      </td>
                      <td>
                        <button title='Edit' onClick={() => handleShowEditUserModal(usr)}><img src={require('./../assets/images/icons/edit-icon.png')} alt="Edit" height="16" /></button>
                      </td>
                    </tr>
                  </>)}
                </tbody>
              </Table>
            </>}

            {deployStep === 'Deploy' && <>
              <h6 className='txt-yellow mb-4'>Deploy</h6>
              <Row>
                <Col xs="auto">
                  <h6 className='txt-yellow border-bottom pb-2'>BI Report</h6>
                  <div style={{width: 150, height: 150, border: '1px solid #212121', textAlign: 'center', lineHeight:'150px'}}><img src={deployIcon} alt={deployFuncName} /></div>
                </Col>
                <Col>
                  <h6 className='txt-yellow border-bottom pb-2'>Users</h6>
                  <Table borderless style={{height:150, overflowY:"auto"}}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Functions</th>
                        <th>User Role</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.length > 0 && userList.map((usr,usridx) =>
                        <tr>
                          <td>{usr.name}</td>
                          <td>{usr.functions}</td>
                          <td>{usr.role}</td>
                          <td>{usr.email_id}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>}
          </Modal.Body>
          <Modal.Footer>
            {deployStep !== 'Overview' && <Button variant="transparent" onClick={handlePrevDeployModal}>Previous</Button>}
            {deployStep === 'Deploy' ? <Button variant="yellow" onClick={handleDeploy}>Deploy</Button> : <Button variant="yellow" onClick={handleNextDeployModal}>Next</Button>}
          </Modal.Footer>
        </Modal>

        <Modal show={editUserModalShow} onHide={handleCloseEditUserModal} backdrop="static"keyboard={false} centered className='modal-medium'>
          <Form onSubmit={handleEditUser}>
            <Modal.Header closeButton>
              <Modal.Title>Update User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" className='rounded-0' placeholder="Name" name="name" value={inputs.name || ""} readOnly />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" className='rounded-0' placeholder="Email (Username)" name="email_id" value={inputs.email_id || ""} readOnly />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <div className='profile-wrap full-width'>
                      <Form.Control type="text" placeholder='User Profile' list="profile" name="profile" className='profile rounded-0 border-top-0 border-right-0 border-left-0' defaultValue={inputs.profile || ""} onChange={handleChange} />
                      <datalist id="profile">
                        {profileList.length > 0 &&
                          profileList.map((item) => <option value={item}>{item}</option>)
                        }
                      </datalist>
                      <button type="button" className="btn-transparent d-none" onClick={handleAddProfile}>+</button>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder='Analytics Client (Tenant)' className="rounded-0 border-top-0 border-right-0 border-left-0" name="saas_client" value={companyName} readOnly />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" style={{paddingLeft: '0.75rem'}}>
                    <Form.Label className='mt-2 txt-gray'>Analytics Module</Form.Label>
                    <div>
                      {userSaasModule.length > 0 && userSaasModule.map((item, index) => {
                        return(
                          <Form.Check key={index} inline type="checkbox" name="module" id={"module-"+item} label={item} value={item} onChange={handleChange} defaultChecked={inputs.module && inputs.module.includes(item)} />
                        )
                      })}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select aria-label="Function" className="rounded-0" name="functions" value={inputs.functions || ""} onChange={handleChange} /* required */>
                      <option value="">Function Name</option>
                      {biModuleList && biModuleList.functions && biModuleList.functions.length > 0 &&
                        biModuleList.functions.map((item, index) => <option value={item} key={index}>{item}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Select aria-label="User Roles" className="rounded-0" name="role" value={inputs.role || ""} onChange={handleChange} required>
                      <option value="">User Role</option>
                      {rolesList.length > 0 &&
                        rolesList.map((item, index) => <option value={item} key={index}>{item}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn-transparent" onClick={handleCloseEditUserModal}>Cancel</button>
              <button type="submit" className='btn-yellow ms-3'>Save</button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={showSubscribe} onHide={handleCloseSubscribe} keyboard={false} centered>
          <Modal.Header closeButton className='p-0'></Modal.Header>
          <Form onSubmit={handleRequest}>
            <Modal.Body>
              <div>
                <div className='mb-3'>
                  <img src={subscribePopupBase64} alt="Placeholder" className='img-fluid mb-2' width="400" />
                  <p className='txt-yellow mt-3'><b>{subscribeProductName}</b></p>
                </div>
                <div>
                  <p className='long-desc'>{longDesc}</p>
                  {productStatus === 'Subscribe' && <>
                    <h6 className='txt-yellow'>Confirm Subscription?</h6>
                    <Form.Check type="checkbox" id="subscrition-accept" label={<small>I accept and agree to the <u><a href="javascript:void(0);" onClick={handleShowTermsOfUse}>terms and conditions</a></u> of Athena Platform Service Agreement.</small>} required />
                  </>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='mx-auto'>
                {productStatus === 'Subscribe' ? <>
                <Button type="button" className="btn-transparent">Cancel</Button>
                <Button type="submit" className='btn-yellow ms-3'>Subscribe</Button>
                </> : <Button type="button" className='btn-yellow'>{productStatus}</Button>}
              </div>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal centered show={showTermsOfUse} onHide={handleCloseTermsOfUse} backdrop="static" keyboard={false} className="terms-policies">
          <Modal.Header closeButton>
              <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body><AthenaSaaSTermsOfUse /></Modal.Body>
          <Modal.Footer>
              <button onClick={handleCloseTermsOfUse}>Back</button>
          </Modal.Footer>
        </Modal>

        {/* <Modal show={showMessage} onHide={handleCloseMessage} backdrop="static"keyboard={false} centered className='modal-medium'>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='text-center'>
              <p>{messageText}</p>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal> */}
      </Container>
    </>
  );
}
 
export default DeployBI;