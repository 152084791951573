import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';

const SaaSInfraList = ({saasInfrastructure, pendingWorkBasketLookup, handleShowIntegrateSystems}) => {

  const deploySaaSArr = [];

  if(saasInfrastructure){
    if(saasInfrastructure.vpn_ip_address){
      deploySaaSArr.push({
        machines: 'VPN',
        ipAddress: saasInfrastructure.vpn_ip_address
      });
    }
    if(saasInfrastructure.rdp_ip_address){
      deploySaaSArr.push({
        machines: 'RDP',
        ipAddress: saasInfrastructure.rdp_ip_address
      });
    }
    if(saasInfrastructure.masternode_ip_address){
      deploySaaSArr.push({
        machines: 'Master Node',
        ipAddress: saasInfrastructure.masternode_ip_address
      });
    }
    if(saasInfrastructure.saas_ip_address){
      deploySaaSArr.push({
        machines: 'Analytics Application',
        ipAddress:  saasInfrastructure.saas_ip_address
      });
    }
    if(saasInfrastructure.compute_ip_address){
      deploySaaSArr.push({
        machines: 'Compute',
        ipAddress:  saasInfrastructure.compute_ip_address
      });
    }
    if(saasInfrastructure.generative_ai_ip_address){
      deploySaaSArr.push({
        machines: 'Generative AI',
        ipAddress:  saasInfrastructure.generative_ai_ip_address
      });
    }
    if(saasInfrastructure.ds_structured_host){
      deploySaaSArr.push({
        machines: 'Structured',
        ipAddress:  saasInfrastructure.ds_structured_host
      });
    }
    if(saasInfrastructure.ds_unstructured_host){
      deploySaaSArr.push({
        machines: 'Unstructured',
        ipAddress:  saasInfrastructure.ds_unstructured_host
      });
    }
    if(saasInfrastructure.ds_semistructured_host){
      deploySaaSArr.push({
        machines: 'Structured & Semi-Structured',
        ipAddress:  saasInfrastructure.ds_semistructured_host
      });
    }
  }
  console.log(deploySaaSArr);

  const data = {
    columns: [
      {
        label: 'Machines',
        field: 'machines',
        sort: 'disabled',
        width: 150
      },
      {
        label: 'IP Address',
        field: 'ipAddress',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Cost',
        field: 'cost',
        sort: 'disabled',
        width: 100
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 150
      }
    ],
    rows: deploySaaSArr.map((item) => {
      return (
        {
          machines: item.machines,
          ipAddress:  item.ipAddress,
          status: 'Active',
          cost: 'Active',
          /* actions: <Link to="#" onClick={(e) => e.stopPropagation()} title='Edit'><img height="15" src={require('./../assets/images/icons/edit-icon.png')} alt="Edit"/></Link>, */
          actions: <>{pendingWorkBasketLookup && <Link to={pendingWorkBasketLookup[0].attribute1 ? pendingWorkBasketLookup[0].attribute1 : '#'} onClick={pendingWorkBasketLookup[0].attribute1 ? (e) => e.stopPropagation() : handleShowIntegrateSystems} title={pendingWorkBasketLookup[0].value}>{pendingWorkBasketLookup[0].value}</Link>}</>,
        }
      )
    })
  };

  return (
    <MDBDataTable striped small borderless data={data} entries={5} entriesOptions={[ 5, 10, 15 ]} searchLabel="Type here" />
  );
}

export default SaaSInfraList;