import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';

const DEList = ({deDetails, pendingWorkBasketLookup, handleShowIntegrateSystems}) => {

  var deListArr = [];
  if(deDetails.length > 0){
    //console.log(deDetails);
    deDetails.forEach(function (el) {
      //console.log(el);
      deListArr.push({
        function: el.function,
        module: el.module,
        billing_cycle: el.billing_cycle,
        function_head: el.function_head,
        subscription: el.subscription,
        //status: <>{(el.deploy_type === 'Deploy' || el.deploy_type === 'Trial') ? 'Active' : 'Inactive'}</>,
        //status: <>{el.deploy_type === 'Deploy' && <>Active</>}{el.deploy_type === 'Trial' && <>Under Trial</>}</>,
        status: el.status,
        actions: <>{pendingWorkBasketLookup && <Link to={pendingWorkBasketLookup[0].attribute1 ? pendingWorkBasketLookup[0].attribute1 : '#'} onClick={pendingWorkBasketLookup[0].attribute1 ? (e) => e.stopPropagation() : handleShowIntegrateSystems} title={pendingWorkBasketLookup[0].value}>{pendingWorkBasketLookup[0].value}</Link>}</>
      });
    });
  }
  //console.log(deListArr);

  const data = {
    columns: [
      {
        label: 'Function',
        field: 'function',
        //sort: 'disabled',
        width: 250
      },
      {
        label: 'Product',
        field: 'module',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Billing Cycle',
        field: 'billing_cycle',
        //sort: 'disabled',
        width: 200
      },
      {
        label: 'Function Head',
        field: 'function_head',
        //sort: 'disabled',
        width: 200
      },
      {
        label: 'Subscription',
        field: 'subscription',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      }
    ],
    rows: deListArr
  };

  return (
    <MDBDataTable striped small borderless data={data} entries={5} entriesOptions={[ 5, 10, 15 ]} searchLabel="Type here" />
  );
}

export default DEList;