import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Header from './../components/Header';
import LeftNav from './../components/LeftNav';
import DeployBIComp from '../components/MarketplaceDeployBI.jsx';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");
 
const Products = () => {

	const [token, setToken] = useState(null);
  const [company, setCompany] = useState("");
  const [refreshNotifications, setRefreshNotifications] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

	const getToken = () => {
    console.log(sessionStorage.getItem('token'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

	useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

	return (
		<>
			<LeftNav />
			<div className="main-wrapper">
        <Header token={token} setCompany={setCompany} refreshNotifications={refreshNotifications} isMarketplacePage={true} />
				<main>
					<DeployBIComp token={token} handleLogout={handleLogout} companyName={company} callRefreshNotification={setRefreshNotifications} refreshNotifications={refreshNotifications} />
				</main>
			</div>
		</>
	);
}
 
export default Products;