import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import SaaSCI from '../pages/SaaS';
import AddModules from '../pages/AddModules';
import AddData from '../pages/AddData';
import FunctionModuleList from '../pages/FunctionModuleList';
import DataSourcesEntities from '../pages/DataSourcesEntities';
import ModulesPreview from '../pages/ModulesPreview';
import AddDataSources from '../pages/AddDataSources';
import ChooseDataEntities from '../pages/ChooseDataEntities';
import DataSourceMetaverseMapping from '../pages/DataSourceMetaverseMapping';
import DataAttributesMapping from '../pages/DataAttributesMapping';
import DataIngestionSchedule from '../pages/DataIngestionSchedule';
import DeployBI from '../pages/DeployBI';
import DeployAI from '../pages/DeployAI';
import DeployEDM from '../pages/DeployEDM';
import ScrollToTop from './../components/ScrollToTop';
import Notifications from '../pages/Notifications';
import Metaverse from '../pages/Metaverse';
import Nexverse from '../pages/Nexverse';
import Intelverse from '../pages/Intelverse';
import Securverse from '../pages/Securverse';
import Products from '../pages/Products';
import Users from '../pages/Users';
import CreateUser from '../pages/CreateUser';
import Dashboard from '../pages/Dashboard';
import Subscription from '../pages/Subscription';
import UsageAndBilling from '../pages/UsageAndBilling';
import ServiceHealth from '../pages/ServiceHealth';
import SupportHealth from '../pages/SupportHealth';
import ManageUsers from '../pages/ManageUsers';
import Marketplace from '../pages/Marketplace';

import LoanUnderwriteLanding from '../pages/LoanUnderwriteLanding';
import PortfolioRecommendations from '../pages/PortfolioRecommendations';
import UnderwriteLoan from '../pages/UnderwriteLoan';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../assets/css/styles.css';

function App() {
  return (
    <BrowserRouter>
      <RenderOnAnonymous />
      <RenderOnAuthenticated>
        <Routes>
          <Route index path="/" element={<SaaSCI/>} />
          <Route path="/add-products" element={<AddModules/>} />
          <Route path="/function-module-list" element={<FunctionModuleList/>} />
          <Route path="/add-data" element={<AddData/>} />
          <Route path="/modules-preview" element={<ModulesPreview/>} />
          <Route path="/add-data-sources" element={<AddDataSources/>} />
          <Route path="/choose-data-entities" element={<ChooseDataEntities/>} />
          <Route path="/data-sources-entities" element={<DataSourcesEntities/>} />
          <Route path="/data-source-metaverse-mapping" element={<DataSourceMetaverseMapping/>} />
          <Route path="/data-attributes-mapping" element={<DataAttributesMapping/>} />
          <Route path="/data-ingestion-schedule" element={<DataIngestionSchedule/>} />
          <Route path="/deploy-bi" element={<DeployBI/>} />
          <Route path="/deploy-de" element={<DeployAI/>} />
          <Route path="/deploy-edm" element={<DeployEDM/>} />
          <Route path="/notifications" element={<Notifications/>} />
          <Route path="/metaverse" element={<Metaverse/>} />
          <Route path="/nexverse" element={<Nexverse/>} />
          <Route path="/intelverse" element={<Intelverse/>} />
          <Route path="/securverse" element={<Securverse/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/users" element={<Users/>} />
          <Route path="/create-user" element={<CreateUser/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/subscription" element={<Subscription/>} />
          <Route path="/usage-and-billing" element={<UsageAndBilling/>} />
          <Route path="/service-health" element={<ServiceHealth/>} />
          <Route path="/support-health" element={<SupportHealth/>} />
          <Route path="/manage-users" element={<ManageUsers/>} />
          <Route path="/marketplace" element={<Marketplace/>} />

          <Route path="/loan-underwrite" element={<LoanUnderwriteLanding/>} />
          <Route path="/UnderwriteLoan" element={<UnderwriteLoan />} />
          <Route path="/PortfolioRecommendations" element={<PortfolioRecommendations />} />
        </Routes>
      <ScrollToTop />
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
